export enum LoginStatus {
	NotLogged,
	Loading,
	LoggedIn,
	Failed,
}

export interface LoginAccountInfo {
	account: string;
	password: string;
}

export interface OrgTree {
	resourceType: number;
	serviceType: number;
	subUnits: OrgTree[];
	sysId: number;
	unitCode: number;
	unitId: string;
	unitName: string;
	unitType: string;
	className?: string;
	key?: string;
}

export enum Role {
	SYSAdmin = 1,
	TXGAdmin = 2,
	TXGUser = 3,
	RESAdmin = 4,
	RESUser = 5,
	QSEAdmin = 6,
	QSEUser = 7,
}

export const RoleChineseVer = {
	1: '系統管理者',
	2: 'TXG管理者',
	3: 'TXG使用者',
	4: 'RES管理者',
	5: 'RES使用者',
	6: 'QSE管理者',
	7: 'QSE使用者',
	8: 'AFC管理者',
	9: 'AFC使用者',
	10: 'QSE&AFC管理者',
	11: 'QSE&AFC使用者',
};
