import React, { useEffect, useState } from 'react';
import DREGImplement from '@pages/PerformanceImplement/DREGImplement';
import SRImplement from '@pages/PerformanceImplement/SRImplement';
import { Button, Card, DatePicker, Modal, Select } from 'antd';
import { Icon } from '@models/icon';
import { OrgTree, Role } from '@models/user';
import { ServiceType } from '@models/common';
import { jsonToCSV } from 'react-papaparse';
import fileDownload from 'js-file-download';
import dayjs, { Dayjs } from 'dayjs';
import api from '@api/api';
import './PerformanceImplement.less';

export interface ImplementData {
	name: string;
	srName: string;
	fieldName: string;
	noticeTime: number;
	noticeType: string;
	clipMW: number;
	revenueFactor: number;
	spm: number;
	idx: number;
	servicePeriod: number;
	list?: ImplementData[];
}

const PerformanceImplement = () => {
	const [qseDropdownIsOpen, setQSEDropdownIsOpen] = useState(false);
	const [txgDropdownIsOpen, setTXGDropdownIsOpen] = useState(false);
	const [qseDropdownList, setQSEDropdownList] = useState([] as OrgTree[]);
	const [txgDropdownList, setTXGDropdownList] = useState([] as OrgTree[]);
	const [selectedQSE, setSelectedQSE] = useState('');
	const [selectedTXG, setSelectedTXG] = useState('');
	const [selectedTXGName, setSelectedTXGName] = useState<string>('');
	const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
	const [isLoading, setIsLoading] = useState(false);
	const [allData, setAllData] = useState([] as ImplementData[]);

	const allOrgTreeList: OrgTree[] = JSON.parse(
		localStorage.getItem('energy-dispatch-allOrgTree') as string
	);
	const userJson = JSON.parse(
		localStorage.getItem('energy-dispatch-user') as string
	);

	const getCSVDownloadData = () => {
		const type = txgDropdownList.filter((t) => t.unitId === selectedTXG)[0]
			.serviceType;
		const newArr: any = [];

		allData.forEach((a) => {
			newArr.push(a);
			newArr.push(...(a.list as ImplementData[]));
		});
		console.log('getCSVDownloadData newArr', newArr);

		let jsonData;
		switch (type) {
			case ServiceType.DREG:
			case ServiceType.SREG:
			case ServiceType.EDREG:
				jsonData = newArr.map((a: any) => ({
					'用戶/場域名稱': a.name,
					通知類別: Object.prototype.hasOwnProperty.call(a, 'noticeType')
						? a.noticeType
						: '',
					'執行類別(一小時)': Object.prototype.hasOwnProperty.call(
						a,
						'servicePeriod'
					)
						? a.servicePeriod
						: '',
					'執行率(%)': a.spm,
				}));
				break;
			case ServiceType.SR:
			case ServiceType.SUP:
			default:
				jsonData = newArr.map((a: any) => ({
					'用戶/場域名稱': a.name,
					通知類別: Object.prototype.hasOwnProperty.call(a, 'noticeType')
						? a.noticeType
						: '',
					調度通知時間: Object.prototype.hasOwnProperty.call(a, 'noticeTime')
						? dayjs(a.noticeTime).format('YYYY-MM-DD HH:mm:ss')
						: '',
					'通知降載量(MW)': a.clipMW,
					'執行率(%)': a.revenueFactor,
				}));
				break;
		}

		console.log('getCSVDownloadData jsonData', jsonData);
		const results = jsonToCSV(jsonData);
		fileDownload(
			results,
			`執行資訊_${selectedTXGName}_${dayjs(selectedDate).format(
				'YYYY-MM-DD'
			)}.csv`
		);
	};

	const serviceTypeCorrespond = (type: number) => {
		const sr = (
			<SRImplement
				allData={allData}
				isLoading={isLoading}
				selectedDate={selectedDate}
				selectedTXGName={selectedTXGName}
			/>
		);
		const dreg = (
			<DREGImplement
				allData={allData}
				isLoading={isLoading}
				selectedDate={selectedDate}
				selectedTXGName={selectedTXGName}
			/>
		);
		let result = { component: sr };

		switch (type) {
			case ServiceType.DREG:
			case ServiceType.SREG:
			case ServiceType.EDREG:
				result = { component: dreg };
				break;
			case ServiceType.SUP:
			case ServiceType.SR:
			default:
				result = { component: sr };
				break;
		}

		return { ...result };
	};

	const getImplementData = async () => {
		setIsLoading(true);

		const date = dayjs(
			dayjs(selectedDate).format('YYYY-MM-DD 00:00:00')
		).valueOf();

		const response: any = await api.get(
			`/webapi/clippedkw/sr/list?id=${selectedTXG}&date=${date}&statisticsT
ype=day`
		);

		if (response.data?.status !== 'ok') {
			return Modal.error({
				title: '找不到資料',
				content: '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		const result: ImplementData[] = response.data?.list.map(
			(ele: any, idx: number) => {
				return {
					...ele,
					idx: idx,
					name: ele.srName,
					noticeType:
						ele.noticeType === null
							? ''
							: ele.noticeType === 'UNLOAD'
							? '執行降載'
							: '準備量不足',
					list: ele.list?.map((child: any, childIdx: number) => {
						return {
							...child,
							idx: `${idx}-${childIdx}`,
							name: child.fieldName,
						};
					}),
				};
			}
		);
		console.log('getImplementData result:', result);

		setAllData(result);
		setIsLoading(false);
	};

	const getTxgName = () => {
		const result = txgDropdownList?.filter(
			(txg) => txg.unitId === selectedTXG
		)[0];
		setSelectedTXGName(result?.unitName);
	};

	useEffect(() => {
		if (allOrgTreeList.length > 0) {
			setQSEDropdownList(allOrgTreeList);
			setSelectedQSE(allOrgTreeList[0].unitId);
		}
	}, []);

	useEffect(() => {
		if (selectedQSE) {
			const newArr = allOrgTreeList.filter((a) => a.unitId === selectedQSE)[0]
				.subUnits;
			setTXGDropdownList(newArr);
			setSelectedTXG(newArr[0]?.unitId ?? '');
		}
	}, [selectedQSE]);

	useEffect(() => {
		getTxgName();
	}, [selectedTXG]);

	useEffect(() => {
		if (selectedQSE && selectedTXG && selectedDate) {
			getImplementData();
		}
	}, [selectedQSE, selectedTXG, selectedDate]);

	return (
		<Card title="執行資訊" bordered={false} className="performance-implement">
			<div className="tool-bar">
				<div className="left">
					<Select
						style={{ width: 180 }}
						onDropdownVisibleChange={() => {
							setQSEDropdownIsOpen((prevState: boolean) => !prevState);
						}}
						value={selectedQSE}
						onChange={(arg) => setSelectedQSE(arg)}
						suffixIcon={
							qseDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
						}
						options={qseDropdownList.map((qse: OrgTree) => ({
							value: qse.unitId,
							label: qse.unitName,
							disabled: qse.subUnits.length === 0,
						}))}
					/>
					<Select
						style={{ width: 180 }}
						onDropdownVisibleChange={() => {
							setTXGDropdownIsOpen((prevState: boolean) => !prevState);
						}}
						value={selectedTXG}
						onChange={(arg) => setSelectedTXG(arg)}
						suffixIcon={
							txgDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
						}
						options={txgDropdownList.map((txg: OrgTree) => ({
							value: txg.unitId,
							label: txg.unitName,
							disabled: [Role.SYSAdmin, Role.QSEAdmin, Role.QSEUser].includes(
								userJson.authority[0] ?? 1
							)
								? false
								: txg.unitId !== userJson.unitId,
						}))}
					/>
				</div>
				<div className="right">
					<DatePicker
						style={{ width: 145 }}
						onChange={(arg) => setSelectedDate(arg)}
						suffixIcon={<Icon.Day />}
						value={selectedDate}
						placeholder={'開始時間'}
						format={'YYYY-MM-DD'}
					/>

					<Button ghost type="primary" onClick={() => getCSVDownloadData()}>
						匯出CSV
					</Button>
				</div>
			</div>
			{txgDropdownList.length > 0 && selectedTXG
				? serviceTypeCorrespond(
						txgDropdownList.filter((t) => t.unitId === selectedTXG)[0]
							.serviceType
				  ).component
				: null}
		</Card>
	);
};

export default PerformanceImplement;
