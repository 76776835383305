import React from 'react';
import { Icon } from '@models/icon';
import { Role } from '@models/user';

export interface MenuInfo {
	label: string;
	key: string;
	icon?: JSX.Element;
	auth: Role[];
	children?: MenuInfo[];
}

export const PATHS = {
	MARKET: {
		COMPETITION: 'market/competition',
		ABANDON: 'market/abandon',
		FIXDATA: 'market/fixdata',
		PRICEINFO: 'market/priceInfo',
		ENERGYDOWNLOAD: 'market/energyDownload',
	},
	OPERATING: {
		OVERVIEW: 'operating/overview',
		SERVICE: 'operating/service',
		DEVICE: 'operating/device',
	},
	PERFORMANCE: {
		EXPENSE: 'performance/expense',
		AWARDED: 'performance/awarded',
		IMPLEMENT: 'performance/implement',
	},
	ORGANIZATION: {
		QSE: 'organization/qse',
		TXG: 'organization/txg',
		RES: 'organization/res',
		DEV: 'organization/dev',
		STRUCTURE: 'organization/structure',
	},
	SYSTEM: {
		USER: 'systemManage/userManage',
		COMPANY: 'systemManage/company',
		USEREDIT: 'system/userEdit',
	},
	MAINTENANCE: {
		RECORD: 'systemManage/userManage',
		DISPATCH: 'maintenance/dispatch',
		TRACK: '',
		CALENDAR: '',
		MAP: '',
	},
};

export const menuItems = [
	{
		label: '市場作業',
		key: 'menu-market',
		icon: <Icon.PageMarket />,
		auth: [
			Role.SYSAdmin,
			Role.QSEAdmin,
			Role.QSEUser,
			Role.TXGAdmin,
			Role.TXGUser,
		],
		children: [
			{
				label: '競價作業',
				key: PATHS.MARKET.COMPETITION,
				auth: [
					Role.SYSAdmin,
					Role.QSEAdmin,
					Role.QSEUser,
					Role.TXGAdmin,
					Role.TXGUser,
				],
			},
			{
				label: '中止待命',
				key: PATHS.MARKET.ABANDON,
				auth: [
					Role.SYSAdmin,
					Role.QSEAdmin,
					Role.QSEUser,
					Role.TXGAdmin,
					Role.TXGUser,
				],
			},
			{
				label: '補值作業',
				key: PATHS.MARKET.FIXDATA,
				auth: [
					Role.SYSAdmin,
					Role.QSEAdmin,
					Role.QSEUser,
					Role.TXGAdmin,
					Role.TXGUser,
				],
			},
			{
				label: '市場資訊',
				key: PATHS.MARKET.PRICEINFO,
				auth: [
					Role.SYSAdmin,
					Role.QSEAdmin,
					Role.QSEUser,
					Role.TXGAdmin,
					Role.TXGUser,
				],
			},
			{
				label: '電能下載',
				key: PATHS.MARKET.ENERGYDOWNLOAD,
				auth: [
					Role.SYSAdmin,
					Role.QSEAdmin,
					Role.QSEUser,
					Role.TXGAdmin,
					Role.TXGUser,
				],
			},
		],
	},
	{
		label: '運轉監控',
		key: 'menu-operate',
		icon: <Icon.PageOperate />,
		auth: [
			Role.SYSAdmin,
			Role.QSEAdmin,
			Role.QSEUser,
			Role.TXGAdmin,
			Role.TXGUser,
			Role.RESAdmin,
			Role.RESUser,
		],
		children: [
			{
				label: '服務內容總覽',
				key: PATHS.OPERATING.OVERVIEW,
				auth: [
					Role.SYSAdmin,
					Role.QSEAdmin,
					Role.QSEUser,
					Role.TXGAdmin,
					Role.TXGUser,
				],
			},
			{
				label: '輔助服務運轉監控',
				key: PATHS.OPERATING.SERVICE,
				auth: [
					Role.SYSAdmin,
					Role.QSEAdmin,
					Role.QSEUser,
					Role.TXGAdmin,
					Role.TXGUser,
					Role.RESAdmin,
					Role.RESUser,
				],
			},
			{
				label: '網路連線異常偵測',
				key: PATHS.OPERATING.DEVICE,
				auth: [Role.SYSAdmin],
			},
		],
	},
	{
		label: '服務績效',
		key: 'menu-perf',
		icon: <Icon.PagePerf />,
		auth: [
			Role.SYSAdmin,
			Role.QSEAdmin,
			Role.QSEUser,
			Role.TXGAdmin,
			Role.TXGUser,
		],
		children: [
			{
				label: '服務費用',
				key: PATHS.PERFORMANCE.EXPENSE,
				auth: [
					Role.SYSAdmin,
					Role.QSEAdmin,
					Role.QSEUser,
					Role.TXGAdmin,
					Role.TXGUser,
				],
			},
			{
				label: '得標資訊',
				key: PATHS.PERFORMANCE.AWARDED,
				auth: [
					Role.SYSAdmin,
					Role.QSEAdmin,
					Role.QSEUser,
					Role.TXGAdmin,
					Role.TXGUser,
				],
			},
			{
				label: '執行資訊',
				key: PATHS.PERFORMANCE.IMPLEMENT,
				auth: [
					Role.SYSAdmin,
					Role.QSEAdmin,
					Role.QSEUser,
					Role.TXGAdmin,
					Role.TXGUser,
				],
			},
		],
	},
	{
		label: '組織管理',
		key: 'menu-org',
		icon: <Icon.PageOrg />,
		auth: [
			Role.SYSAdmin,
			Role.QSEAdmin,
			Role.QSEUser,
			Role.TXGAdmin,
			Role.TXGUser,
		],
		children: [
			{
				label: '合格交易商 (QSE) 管理',
				key: PATHS.ORGANIZATION.QSE,
				auth: [Role.SYSAdmin],
			},
			{
				label: '交易群組 (TXG) 管理',
				key: PATHS.ORGANIZATION.TXG,
				auth: [Role.SYSAdmin],
			},
			{
				label: '場域 (RES) 管理',
				key: PATHS.ORGANIZATION.RES,
				auth: [Role.SYSAdmin],
			},
			{
				label: '設備 (DEV) 管理',
				key: PATHS.ORGANIZATION.DEV,
				auth: [Role.SYSAdmin],
			},
			{
				label: '組織架構',
				key: PATHS.ORGANIZATION.STRUCTURE,
				auth: [
					Role.SYSAdmin,
					Role.QSEAdmin,
					Role.QSEUser,
					Role.TXGAdmin,
					Role.TXGUser,
				],
			},
		],
	},
	{
		label: '系統管理',
		key: 'menu-sys',
		icon: <Icon.PageSys />,
		auth: [Role.SYSAdmin],
		children: [
			{ label: '人員管理', key: PATHS.SYSTEM.USER, auth: [Role.SYSAdmin] },
			{ label: '公司管理', key: PATHS.SYSTEM.COMPANY, auth: [Role.SYSAdmin] },
		],
	},
	{
		label: '維運系統',
		key: 'menu-market',
		icon: <Icon.PageOperate />,
		auth: [
			Role.SYSAdmin,
			Role.QSEAdmin,
			Role.QSEUser,
			Role.TXGAdmin,
			Role.TXGUser,
		],
		children: [
			{
				label: '維運紀錄',
				key: PATHS.MAINTENANCE.RECORD,
				auth: [
					Role.SYSAdmin,
					Role.QSEAdmin,
					Role.QSEUser,
					Role.TXGAdmin,
					Role.TXGUser,
				],
			},
			{
				label: '維運派工',
				key: PATHS.MAINTENANCE.DISPATCH,
				auth: [
					Role.SYSAdmin,
					Role.QSEAdmin,
					Role.QSEUser,
					Role.TXGAdmin,
					Role.TXGUser,
				],
			},
			{
				label: '事件追蹤',
				key: PATHS.MAINTENANCE.TRACK,
				auth: [
					Role.SYSAdmin,
					Role.QSEAdmin,
					Role.QSEUser,
					Role.TXGAdmin,
					Role.TXGUser,
				],
			},
			{
				label: '維運行事曆',
				key: PATHS.MAINTENANCE.CALENDAR,
				auth: [
					Role.SYSAdmin,
					Role.QSEAdmin,
					Role.QSEUser,
					Role.TXGAdmin,
					Role.TXGUser,
				],
			},
			{
				label: '維運地圖',
				key: PATHS.MAINTENANCE.MAP,
				auth: [
					Role.SYSAdmin,
					Role.QSEAdmin,
					Role.QSEUser,
					Role.TXGAdmin,
					Role.TXGUser,
				],
			},
		],
	},
];
