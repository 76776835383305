import React, { useEffect } from 'react';
import './NoAuth.less';

function NoAuth() {
	useEffect(() => {
		document.title = 'NoAuth - 智慧能源調度系統';
	}, []);

	return (
		<div className="no-auth">
			<div>403</div>
			<div>Sorry, you do not have access to this page.</div>
		</div>
	);
}
export default NoAuth;
