import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { Icon } from '@models/icon';

import './CardTableSwitch.less';

interface CardTableSwitchProps {
	displayTypeChange: (arg: string) => void;
}

function CardTableSwitch({ displayTypeChange }: CardTableSwitchProps) {
	const [type, setType] = useState('CARD');

	useEffect(() => {
		type && displayTypeChange(type);
	}, [type]);

	return (
		<div className="card-table-switch">
			<Button
				size="middle"
				className={type === 'CARD' ? 'selected' : ''}
				onClick={() => setType('CARD')}
			>
				<Icon.Card />
			</Button>
			<Button
				size="middle"
				className={type === 'TABLE' ? 'selected' : ''}
				onClick={() => setType('TABLE')}
			>
				<Icon.Table />
			</Button>
		</div>
	);
}
export default CardTableSwitch;
