import React, { useEffect, useRef, useState } from 'react';
import NoData from '@components/NoData/NoData';
import { useSelector } from 'react-redux';
import { useMeasure, useFirstMountState } from 'react-use';
import {
	getIsFullscreenState,
	getCustomThemeToken,
} from '@store/reducers/utilSlice';
import { Modal, Spin, Switch } from 'antd';
import { Icon } from '@models/icon';
import { shareChartOption, UnitType } from '@models/common';
import {
	SelectedService,
	TimeType,
} from '@pages/OperatingService/OperatingService';
import dayjs, { Dayjs } from 'dayjs';
import { flatten, isEmpty } from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import api from '@api/api';

highchartsMore(Highcharts);

interface SRProps {
	selectedTXG: string;
	timeType: number;
	serviceObj: SelectedService;
	selectedWeekday: number;
	dateRange: [Dayjs | null, Dayjs | null] | null;
	showDayDivider: boolean;
	handleUpdateTime: (arg: string) => void;
	handleApiIsLoading: (arg: boolean) => void;
}

const originalAndActualElectricityLabels = [
	{ labelName: '原始用電量', parameter: 'acPower', valueSuffix: ' kW' },
	{ labelName: '實際用電量', parameter: 'acPower', valueSuffix: ' kW' },
	{ labelName: '參考基準', parameter: 'base', valueSuffix: ' kW' },
	{ labelName: '目標降載', parameter: 'target', valueSuffix: ' kW' },
	{ labelName: '後續降載目標', parameter: 'nextTarget', valueSuffix: ' kW' },
];

const bidSchedulingAbortLabels = [
	{ labelName: '得標容量', parameter: 'awarded', valueSuffix: ' kW' },
	{ labelName: '調度降載量', parameter: 'clip', valueSuffix: ' kW' },
	{ labelName: '中止待命', parameter: 'abandon', valueSuffix: ' kW' },
];

function SRChart(props: SRProps) {
	const token = useSelector(getCustomThemeToken);
	const [refresher, setRefresher] = useState(0);
	const [expandField, setExpandField] = useState(false);
	const isFullscreen = useSelector(getIsFullscreenState);
	const isFirstMount = useFirstMountState();

	// oaae
	const [
		isOriginalAndActualElectricityNoData,
		setIsOriginalAndActualElectricityNoData,
	] = useState(false);
	const [
		expandOriginalAndActualElectricityChart,
		setExpandOriginalAndActualElectricityChart,
	] = useState(false);
	const [
		isOriginalAndActualElectricityLoading,
		setIsOriginalAndActualElectricityLoading,
	] = useState(true);
	const originalAndActualElectricityChartRef = useRef<null | HTMLDivElement>(
		null
	);
	const [
		originalAndActualElectricityChartOptions,
		setOriginalAndActualElectricityChartOptions,
	] = useState({
		...shareChartOption(token),
		chart: {
			height: 300,
			type: 'line',
			zoomType: 'xy',
			animation: false,
			backgroundColor: token.colorBgContainer,
		},
		colors: ['#4391FF', '#72D126', '#FF7A00', '#EE4D7E', '#9F32A7'],
		series: originalAndActualElectricityLabels.map((item) => ({
			name: item.labelName,
			parameter: item.parameter,
			data: [],
			marker: {
				symbol: 'circle',
				radius: 3.5,
			},
			dataLabels: {
				enabled: false,
			},
			tooltip: {
				valueSuffix: item.valueSuffix,
			},
		})),
		yAxis: {
			title: {
				text: 'kW',
				style: {
					color: token.colorChartNormal,
				},
			},
			labels: {
				style: {
					color: token.colorChartNormal,
				},
			},
			gridLineColor: token.colorChartNormal,
		},
	});
	const [
		originalAndActualElectricityPartRef,
		{
			x: oaaeX,
			y: oaaeY,
			width: oaaeWidth,
			height: oaaeHeight,
			top: oaaeTop,
			right: oaaeRight,
			bottom: oaaeBottom,
			left: oaaeLeft,
		},
	] = useMeasure<HTMLDivElement>();

	// bsa
	const [isBidSchedulingAbortNoData, setIsBidSchedulingAbortNoData] =
		useState(false);
	const [expandBidSchedulingAbortChart, setExpandBidSchedulingAbortChart] =
		useState(false);
	const [isBidSchedulingAbortLoading, setIsBidSchedulingAbortLoading] =
		useState(true);
	const bidSchedulingAbortChartRef = useRef<null | HTMLDivElement>(null);
	const [bidSchedulingAbortChartOptions, setBidSchedulingAbortChartOptions] =
		useState({
			...shareChartOption(token),
			chart: {
				height: 300,
				type: 'area',
				zoomType: 'xy',
				animation: false,
				backgroundColor: token.colorBgContainer,
			},
			colors: ['#4391FF', '#EE4D7E', '#667085'],
			series: bidSchedulingAbortLabels.map((item) => ({
				name: item.labelName,
				parameter: item.parameter,
				data: [],
				marker: {
					symbol: 'circle',
					radius: 3.5,
				},
				dataLabels: {
					enabled: false,
				},
				tooltip: {
					valueSuffix: item.valueSuffix,
				},
			})),
			yAxis: {
				title: {
					text: 'kW',
					style: {
						color: token.colorChartNormal,
					},
				},
				labels: {
					style: {
						color: token.colorChartNormal,
					},
				},
				gridLineColor: token.colorChartNormal,
			},
		});
	const [
		bidSchedulingAbortPartRef,
		{
			x: bsaX,
			y: bsaY,
			width: bsaWidth,
			height: bsaHeight,
			top: bsaTop,
			right: bsaRight,
			bottom: bsaBottom,
			left: bsaLeft,
		},
	] = useMeasure<HTMLDivElement>();

	//fe
	const [isFieldElectricityNoData, setIsFieldElectricityNoData] =
		useState(false);
	const [expandFieldElectricityChart, setExpandFieldElectricityChart] =
		useState(false);
	const [isFieldElectricityLoading, setIsFieldElectricityLoading] =
		useState(true);
	const fieldElectricityChartRef = useRef<null | HTMLDivElement>(null);
	const [fieldElectricityChartOptions, setFieldElectricityChartOptions] =
		useState({
			...shareChartOption(token),
			chart: {
				height: 300,
				type: 'line',
				zoomType: 'xy',
				animation: false,
				backgroundColor: token.colorBgContainer,
			},
			colors: ['#4391FF', '#EE4D7E'],
			series: [] as any[],
			yAxis: {
				title: {
					text: 'kW',
					style: {
						color: token.colorChartNormal,
					},
				},
				labels: {
					style: {
						color: token.colorChartNormal,
					},
				},
				gridLineColor: token.colorChartNormal,
			},
		});
	const [
		fieldElectricityPartRef,
		{
			x: feX,
			y: feY,
			width: feWidth,
			height: feHeight,
			top: feTop,
			right: feRight,
			bottom: feBottom,
			left: feLeft,
		},
	] = useMeasure<HTMLDivElement>();

	const getChartData = async (id: string, needLoading = false) => {
		console.log('serviceObj:', props.serviceObj);
		if (needLoading) {
			setIsOriginalAndActualElectricityLoading(true);
			setIsBidSchedulingAbortLoading(true);
			setIsFieldElectricityLoading(true);
			props.handleApiIsLoading(true);
		}

		const isNowUnitTypeTXG = props.serviceObj.unitType === UnitType.TXG;
		let date = '';
		let diff = 0;

		if (props.timeType === TimeType.WeekOrImmediate) {
			date = (dayjs() as any)
				.startOf('week')
				.add(props.selectedWeekday - 1, 'day')
				.format('YYYY-MM-DD');
			diff = 1;
		} else {
			if (!props.dateRange || !props.dateRange[0] || !props.dateRange[1]) {
				props.handleApiIsLoading(false);
				return;
			}

			const dateRangeStart = dayjs(props.dateRange[0]).format('YYYY-MM-DD');
			const dateRangeEnd = dayjs(props.dateRange[1]);
			date = dateRangeStart;
			diff = dateRangeEnd.diff(dateRangeStart, 'day') + 1;
		}
		console.log('date diff:', date, diff);

		const url = isNowUnitTypeTXG
			? [
					`/webapi/history/ed/sr/list/multi?id=${id}&date=${date}&count=${diff}`,
					`/webapi/history/ed/detail/list/multi?id=${id}&date=${date}&count=${diff}`,
			  ]
			: [
					`/webapi/history/ed/field/list/multi?id=${id}&date=${date}&count=${diff}`,
			  ];

		const allResp = await Promise.all(
			url.map(async (u) => {
				const res = await api.get(u);
				return res.data;
			})
		);
		console.log('getChartData resresresres', allResp);
		props.handleUpdateTime(dayjs().format('HH:mm:ss'));

		if (allResp.some((res) => res.status === 'fail')) {
			props.handleApiIsLoading(false);
			return Modal.error({
				title: '找不到資料',
				content: '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		const oneArr = flatten(allResp[0].list.map((r: any) => r.list)) as any[];
		console.log('oneArr', oneArr);

		setOriginalAndActualElectricityChartOptions((prevState) => ({
			...prevState,
			series: prevState.series.map((s) => ({
				...s,
				data: oneArr.map((r: any) => {
					// console.log('r[s.parameter]', s.parameter, r[s.parameter]);
					return [r.time, r[s.parameter] ?? null];
				}) as any,
			})),
		}));
		setBidSchedulingAbortChartOptions((prevState) => ({
			...prevState,
			series: prevState.series.map((s) => ({
				...s,
				data: oneArr.map((r: any) => [r.time, r[s.parameter] ?? null]) as any,
			})),
		}));

		if (isNowUnitTypeTXG) {
			const twoLablesArr = allResp[1].list[0].data.names;
			const twoArr = flatten(allResp[1].list.map((r: any) => r.data.values));
			console.log('twoLablesArr twoArr', twoLablesArr, twoArr);

			setFieldElectricityChartOptions((prevState) => ({
				...prevState,
				series: twoLablesArr.map((item: any, idx: number) => ({
					name: item,
					parameter: '',
					data: twoArr.map((r: any) => [r.time, r.activePower[idx] ?? null]),
					marker: {
						symbol: 'circle',
						radius: 3.5,
					},
					dataLabels: {
						enabled: false,
					},
					tooltip: {
						valueSuffix: ' kW',
					},
				})),
			}));

			setIsFieldElectricityNoData(twoArr.length === 0);
		}

		setIsOriginalAndActualElectricityNoData(
			oneArr.length === 0 ||
				(oneArr.length === 2 && oneArr.every((o) => !o?.acPower))
		);
		setIsBidSchedulingAbortNoData(
			oneArr.length === 0 ||
				oneArr.filter((item) => item.awarded || item.clip || item.abandon)
					.length === 0
		);
		console.log(
			'setIsBidSchedulingAbortNoData',
			oneArr.filter((item) => item.awarded || item.clip || item.abandon)
		);

		if (needLoading) {
			setTimeout(() => {
				setIsOriginalAndActualElectricityLoading(false);
				setIsBidSchedulingAbortLoading(false);
				setIsFieldElectricityLoading(false);
				props.handleApiIsLoading(false);
			}, 1000);
		}
	};

	const checkShowDayDivider = () => {
		console.log(
			'checkShowDayDivider:',
			props,
			isOriginalAndActualElectricityNoData,
			isBidSchedulingAbortNoData,
			isFieldElectricityNoData
		);

		if (
			props.showDayDivider &&
			props.timeType === TimeType.Specify &&
			props.dateRange &&
			props.dateRange[0] &&
			props.dateRange[1] &&
			!dayjs(props.dateRange[0]).isSame(props.dateRange[1], 'day')
		) {
			const start = dayjs(props.dateRange[0]);
			const end = dayjs(props.dateRange[1]);
			const diff = end.diff(start, 'day');
			const dayArr: number[] = [];

			for (let i = 0; i <= diff; i++) {
				const resp = dayjs(start.add(i, 'day')).startOf('day').valueOf();
				dayArr.push(resp);
			}
			console.log('dayArr:', dayArr);

			const lines = dayArr.map((d) => ({
				color: token.colorText,
				dashStyle: 'dash',
				value: d,
				width: 1,
				zIndex: 4,
				label: {
					text: dayjs(d).format('MM/DD'),
					rotation: 270,
					x: 14,
					y: 40,
					style: { color: token.colorText },
				},
			}));

			setOriginalAndActualElectricityChartOptions((prevState) => ({
				...prevState,
				xAxis: {
					...prevState.xAxis,
					plotLines: isOriginalAndActualElectricityNoData ? [] : lines,
				},
			}));
			setBidSchedulingAbortChartOptions((prevState) => ({
				...prevState,
				xAxis: {
					...prevState.xAxis,
					plotLines: isBidSchedulingAbortNoData ? [] : lines,
				},
			}));
			setFieldElectricityChartOptions((prevState) => ({
				...prevState,
				xAxis: {
					...prevState.xAxis,
					plotLines: isFieldElectricityNoData ? [] : lines,
				},
			}));
		} else {
			console.log('no showDayDivider');
			setOriginalAndActualElectricityChartOptions((prevState) => ({
				...prevState,
				xAxis: {
					...prevState.xAxis,
					plotLines: [],
				},
			}));
			setBidSchedulingAbortChartOptions((prevState) => ({
				...prevState,
				xAxis: {
					...prevState.xAxis,
					plotLines: [],
				},
			}));
			setFieldElectricityChartOptions((prevState) => ({
				...prevState,
				xAxis: {
					...prevState.xAxis,
					plotLines: [],
				},
			}));
		}
	};

	useEffect(() => {
		const cleanup = setInterval(() => setRefresher(Date.now()), 60 * 1000);
		return () => clearInterval(cleanup);
	}, []);

	useEffect(() => {
		setOriginalAndActualElectricityChartOptions((prevState) => ({
			...prevState,
			series: prevState.series.map((s) => ({
				...s,
				data: [],
			})),
		}));
		setBidSchedulingAbortChartOptions((prevState) => ({
			...prevState,
			series: prevState.series.map((s) => ({
				...s,
				data: [],
			})),
		}));
		setFieldElectricityChartOptions((prevState) => ({
			...prevState,
			series: prevState.series.map((s) => ({
				...s,
				data: [],
			})),
		}));
	}, [
		props.timeType,
		props.serviceObj,
		props.selectedWeekday,
		props.dateRange,
	]);

	useEffect(() => {
		setIsOriginalAndActualElectricityLoading(true);
		setIsBidSchedulingAbortLoading(true);
		setIsFieldElectricityLoading(true);

		setTimeout(() => {
			setIsOriginalAndActualElectricityLoading(false);
			setIsBidSchedulingAbortLoading(false);
			setIsFieldElectricityLoading(false);
		}, 1000);
	}, [isFullscreen]);

	useEffect(() => {
		console.log('props:', props);

		if (!isEmpty(props.serviceObj) && props.selectedTXG) {
			getChartData(props.selectedTXG, true);
		}
	}, [
		props.timeType,
		props.serviceObj,
		props.selectedWeekday,
		props.dateRange,
	]);

	useEffect(() => {
		if (isFirstMount) {
			return;
		}

		let isToday = false;
		if (props.timeType === TimeType.WeekOrImmediate) {
			const date = (dayjs() as any)
				.startOf('week')
				.add(props.selectedWeekday - 1, 'day')
				.format('YYYY-MM-DD');
			isToday = dayjs(date).isToday();
		} else {
			if (!props.dateRange || !props.dateRange[0] || !props.dateRange[1]) {
				return;
			}

			const dateRangeStart = dayjs(props.dateRange[0]).format('YYYY-MM-DD');
			const dateRangeEnd = dayjs(props.dateRange[1]);
			isToday = dayjs(dateRangeEnd).isToday();
		}
		console.log('isToday:', isToday);

		if (!isEmpty(props.serviceObj) && props.selectedTXG && isToday) {
			getChartData(props.selectedTXG);
		}
	}, [refresher]);

	useEffect(() => {
		checkShowDayDivider();
	}, [
		props.showDayDivider,
		props.timeType,
		props.dateRange,
		isOriginalAndActualElectricityNoData,
		isBidSchedulingAbortNoData,
		isFieldElectricityNoData,
	]);

	useEffect(() => {
		setOriginalAndActualElectricityChartOptions((prevState) => ({
			...prevState,
			chart: {
				...prevState.chart,
				height: expandOriginalAndActualElectricityChart ? 600 : 300,
			},
		}));

		console.log('oaaeHeight:', oaaeHeight);
		if (oaaeHeight > 0 && oaaeHeight < 600) {
			originalAndActualElectricityChartRef.current?.scrollIntoView({
				block: 'center',
				behavior: 'smooth',
			});
		}

		if (oaaeHeight !== 0) {
			setTimeout(() => {
				setIsOriginalAndActualElectricityLoading(false);
			}, 300);
		}
	}, [expandOriginalAndActualElectricityChart]);

	useEffect(() => {
		setBidSchedulingAbortChartOptions((prevState) => ({
			...prevState,
			chart: {
				...prevState.chart,
				height: expandBidSchedulingAbortChart ? 600 : 300,
			},
		}));

		console.log('bsaHeight', bsaHeight);
		if (bsaHeight > 0 && bsaHeight < 600) {
			bidSchedulingAbortChartRef.current?.scrollIntoView({
				block: 'center',
				behavior: 'smooth',
			});
		}

		if (bsaHeight !== 0) {
			setTimeout(() => {
				setIsBidSchedulingAbortLoading(false);
			}, 300);
		}
	}, [expandBidSchedulingAbortChart]);

	useEffect(() => {
		setFieldElectricityChartOptions((prevState) => ({
			...prevState,
			chart: {
				...prevState.chart,
				height: expandFieldElectricityChart ? 600 : 300,
			},
		}));

		console.log('feHeight', feHeight);
		if (feHeight > 0 && feHeight < 600) {
			fieldElectricityChartRef.current?.scrollIntoView({
				block: 'center',
				behavior: 'smooth',
			});
		}

		if (feHeight !== 0) {
			setTimeout(() => {
				setIsFieldElectricityLoading(false);
			}, 300);
		}
	}, [expandFieldElectricityChart]);

	return (
		<>
			{/* chart one */}
			<>
				<div
					className="original-and-actual-electricity"
					ref={originalAndActualElectricityPartRef}
				>
					<div className="chart-bar">
						<div className="title-part">原始用電量與實際用電量</div>
						<div className="switch-part">
							<div className="switch-label">
								<span className="switch-label-title">顯示標籤</span>
								{originalAndActualElectricityChartOptions.series.map(
									(item, currIdx) => {
										return (
											<div
												key={currIdx}
												className={item.dataLabels.enabled ? 'switch-on' : ''}
											>
												<Switch
													checked={item.dataLabels.enabled}
													onChange={(checked: boolean) => {
														setOriginalAndActualElectricityChartOptions(
															(prevState) => ({
																...prevState,
																series: prevState.series.map((s, prevIdx) => ({
																	...s,
																	dataLabels: {
																		enabled:
																			currIdx === prevIdx
																				? checked
																				: s.dataLabels.enabled,
																	},
																})),
															})
														);
													}}
												/>
												<span>{item.name}</span>
											</div>
										);
									}
								)}
							</div>
							<div
								className="switch-expand"
								onClick={() => {
									setIsOriginalAndActualElectricityLoading(true);
									setExpandOriginalAndActualElectricityChart(
										(prevState) => !prevState
									);
								}}
							>
								{expandOriginalAndActualElectricityChart ? (
									<>
										<Icon.CollapseArrow />
										收回圖表
									</>
								) : (
									<>
										<Icon.ExpandArrow />
										展開圖表
									</>
								)}
							</div>
						</div>
					</div>
					<div
						className={`chart ${
							expandOriginalAndActualElectricityChart ? 'expand-chart' : ''
						}`}
						ref={originalAndActualElectricityChartRef}
					>
						{isOriginalAndActualElectricityLoading ? (
							<Spin className="spin-center" />
						) : (
							<>
								<HighchartsReact
									highcharts={Highcharts}
									options={originalAndActualElectricityChartOptions}
								/>
								<div className="hint-text">滑鼠拖拉可以放大圖表</div>
								<div className="fixed-no-data">
									{isOriginalAndActualElectricityNoData ? <NoData /> : ''}
								</div>
							</>
						)}
					</div>
				</div>
			</>

			{/* chart two */}
			<>
				<div className="bid-scheduling-abort" ref={bidSchedulingAbortPartRef}>
					<div className="chart-bar">
						<div className="title-part">得標容量、調度降載量與中止待命</div>
						<div className="switch-part">
							<div className="switch-label">
								<span className="switch-label-title">顯示標籤</span>
								{bidSchedulingAbortChartOptions.series.map((item, currIdx) => {
									return (
										<div
											key={currIdx}
											className={item.dataLabels.enabled ? 'switch-on' : ''}
										>
											<Switch
												checked={item.dataLabels.enabled}
												onChange={(checked: boolean) => {
													setBidSchedulingAbortChartOptions((prevState) => ({
														...prevState,
														series: prevState.series.map((s, prevIdx) => ({
															...s,
															dataLabels: {
																enabled:
																	currIdx === prevIdx
																		? checked
																		: s.dataLabels.enabled,
															},
														})),
													}));
												}}
											/>
											<span>{item.name}</span>
										</div>
									);
								})}
							</div>
							<div
								className="switch-expand"
								onClick={() => {
									setIsBidSchedulingAbortLoading(true);
									setExpandBidSchedulingAbortChart((prevState) => !prevState);
								}}
							>
								{expandBidSchedulingAbortChart ? (
									<>
										<Icon.CollapseArrow />
										收回圖表
									</>
								) : (
									<>
										<Icon.ExpandArrow />
										展開圖表
									</>
								)}
							</div>
						</div>
					</div>
					<div
						className={`chart ${
							expandBidSchedulingAbortChart ? 'expand-chart' : ''
						}`}
						ref={bidSchedulingAbortChartRef}
					>
						{isBidSchedulingAbortLoading ? (
							<Spin className="spin-center" />
						) : (
							<>
								<HighchartsReact
									highcharts={Highcharts}
									options={bidSchedulingAbortChartOptions}
								/>
								<div className="hint-text">滑鼠拖拉可以放大圖表</div>
								<div className="fixed-no-data">
									{isBidSchedulingAbortNoData ? <NoData /> : ''}
								</div>
							</>
						)}
					</div>
				</div>
			</>

			{/* chart three */}
			<>
				{props.serviceObj.unitType === UnitType.TXG ? (
					<>
						{expandField ? (
							<div className="field-electricity" ref={fieldElectricityPartRef}>
								<div className="chart-bar">
									<div className="title-part">各場域實際用電量</div>
									<div className="switch-part">
										<div className="switch-label">
											<span className="switch-label-title">顯示標籤</span>
											{fieldElectricityChartOptions.series.map(
												(item, currIdx) => {
													return (
														<div
															key={currIdx}
															className={
																item.dataLabels.enabled ? 'switch-on' : ''
															}
														>
															<Switch
																checked={item.dataLabels.enabled}
																onChange={(checked: boolean) => {
																	setFieldElectricityChartOptions(
																		(prevState) => ({
																			...prevState,
																			series: prevState.series.map(
																				(s, prevIdx) => ({
																					...s,
																					dataLabels: {
																						enabled:
																							currIdx === prevIdx
																								? checked
																								: s.dataLabels.enabled,
																					},
																				})
																			),
																		})
																	);
																}}
															/>
															<span>{item.name}</span>
														</div>
													);
												}
											)}
										</div>
										<div
											className="switch-expand"
											onClick={() => {
												setIsFieldElectricityLoading(true);
												setExpandFieldElectricityChart(
													(prevState) => !prevState
												);
											}}
										>
											{expandFieldElectricityChart ? (
												<>
													<Icon.CollapseArrow />
													收回圖表
												</>
											) : (
												<>
													<Icon.ExpandArrow />
													展開圖表
												</>
											)}
										</div>
									</div>
								</div>
								<div
									className={`chart ${
										expandFieldElectricityChart ? 'expand-chart' : ''
									}`}
									ref={fieldElectricityChartRef}
								>
									{isFieldElectricityLoading ? (
										<Spin className="spin-center" />
									) : (
										<>
											<HighchartsReact
												highcharts={Highcharts}
												options={fieldElectricityChartOptions}
											/>
											<div className="hint-text">滑鼠拖拉可以放大圖表</div>
											<div className="fixed-no-data">
												{isFieldElectricityNoData ? <NoData /> : ''}
											</div>
										</>
									)}
								</div>
							</div>
						) : (
							''
						)}
						<div
							className="expand-field"
							onClick={() => setExpandField((prevState) => !prevState)}
						>
							{expandField ? (
								<>
									<Icon.Minus />
									收回所屬場域
								</>
							) : (
								<>
									<Icon.Plus />
									展開所屬場域
								</>
							)}
						</div>
					</>
				) : (
					''
				)}
			</>
		</>
	);
}
export default SRChart;
