import React, { useEffect, useState } from 'react';
import { DatePicker, Select, Button, Card, Space, Row, Col, Modal } from 'antd';
import { Icon } from '@models/icon';
import { OrgTree } from '@models/user';
import dayjs, { Dayjs } from 'dayjs';
import api from '@api/api';
import fileDownload from 'js-file-download';
import './MarketFixData.less';

const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

function MarketFixData() {
	const [isLoading, setIsLoading] = useState(false);
	const [qseDropdownIsOpen, setQSEDropdownIsOpen] = useState(false);
	const [txgDropdownIsOpen, setTXGDropdownIsOpen] = useState(false);
	const [resDropdownIsOpen, setRESDropdownIsOpen] = useState(false);
	const [qseDropdownList, setQSEDropdownList] = useState([] as OrgTree[]);
	const [txgDropdownList, setTXGDropdownList] = useState([] as OrgTree[]);
	const [resDropdownList, setRESDropdownList] = useState([] as OrgTree[]);
	const [selectedQSE, setSelectedQSE] = useState('');
	const [selectedTXG, setSelectedTXG] = useState('');
	const [selectedRES, setSelectedRES] = useState('');
	const [dateRange, setDateRange] = useState<RangeValue>([null, null]);

	const allOrgTreeList: OrgTree[] = JSON.parse(
		localStorage.getItem('energy-dispatch-allOrgTree') as string
	);

	const download = async () => {
		if (!dateRange) {
			return;
		}

		setIsLoading(true);
		const start = dayjs(dateRange[0]).format('YYYY-MM-DD HH:mm');
		const end = dayjs(dateRange[1]).format('YYYY-MM-DD HH:mm');

		const res = await api.get(
			`/webapi/report/download/${selectedQSE}/${selectedTXG}/${selectedRES}?begin=${start}&end=${end}&type=history`,
			{ responseType: 'blob' }
		);
		console.log('download resresresres', res);

		setIsLoading(false);
		if (res?.status !== 200) {
			return Modal.error({
				title: '檔案無法下載',
				content: '請確認網路連線狀態，並重新操作。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		fileDownload(res.data, `${start}_${selectedRES}.zip`);

		Modal.success({
			title: '檔案已經下載至您的電腦',
			content: '請點擊確定按鈕返回頁面',
			okText: '確定',
			centered: true,
			closable: true,
			closeIcon: <Icon.ModalClose />,
		});
	};

	useEffect(() => {
		if (allOrgTreeList.length > 0) {
			setQSEDropdownList(allOrgTreeList);
			setSelectedQSE('');
		}
	}, []);

	useEffect(() => {
		if (selectedQSE) {
			const newArr = allOrgTreeList.filter((a) => a.unitId === selectedQSE)[0]
				.subUnits;
			setTXGDropdownList(newArr);
			setRESDropdownList([]);
			setSelectedTXG('');
		}
	}, [selectedQSE]);

	useEffect(() => {
		if (selectedTXG) {
			const newArr = allOrgTreeList
				.filter((a) => a.unitId === selectedQSE)[0]
				.subUnits.filter((s) => s.unitId === selectedTXG)[0].subUnits;
			setRESDropdownList(newArr);
			setSelectedRES('');
		}
	}, [selectedTXG]);

	return (
		<>
			<Card title="補值作業" bordered={false}>
				<Space direction="vertical" size="large" style={{ display: 'flex' }}>
					<Row>
						<Col span={2}>交易商簡稱</Col>
						<Col>
							<Select
								style={{ width: 380 }}
								onDropdownVisibleChange={() => {
									setQSEDropdownIsOpen((prevState: boolean) => !prevState);
								}}
								value={selectedQSE}
								onChange={(arg) => setSelectedQSE(arg)}
								suffixIcon={
									qseDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
								}
								options={qseDropdownList.map((qse: OrgTree) => ({
									value: qse.unitId,
									label: qse.unitName,
									disabled: qse.subUnits.length === 0,
								}))}
							/>
						</Col>
					</Row>
					<Row>
						<Col span={2}>交易群組簡稱</Col>
						<Col>
							<Select
								style={{ width: 380 }}
								onDropdownVisibleChange={() => {
									setTXGDropdownIsOpen((prevState: boolean) => !prevState);
								}}
								value={selectedTXG}
								onChange={(arg) => setSelectedTXG(arg)}
								suffixIcon={
									txgDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
								}
								options={txgDropdownList.map((txg: OrgTree) => ({
									value: txg.unitId,
									label: txg.unitName,
									disabled: txg.subUnits.length === 0,
								}))}
							/>
						</Col>
					</Row>
					<Row>
						<Col span={2}>場域簡稱</Col>
						<Col>
							<Select
								style={{ width: 380 }}
								onDropdownVisibleChange={() => {
									setRESDropdownIsOpen((prevState: boolean) => !prevState);
								}}
								value={selectedRES}
								onChange={(arg) => setSelectedRES(arg)}
								suffixIcon={
									resDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
								}
								options={resDropdownList.map((res: OrgTree) => ({
									value: res.unitId,
									label: res.unitName,
								}))}
							/>
						</Col>
					</Row>
					<Row>
						<Col span={2}>日期／時間</Col>
						<Col>
							<RangePicker
								style={{ width: 380 }}
								showTime={{ format: 'HH:mm' }}
								format="YYYY-MM-DD HH:mm"
								onChange={(arg) => setDateRange(arg)}
								suffixIcon={<Icon.Calendar />}
								value={dateRange}
								placeholder={['開始時間', '結束時間']}
							/>
						</Col>
					</Row>
					<Button
						type="primary"
						onClick={() => download()}
						disabled={
							isLoading ||
							!selectedQSE ||
							!selectedTXG ||
							!selectedRES ||
							!dateRange ||
							!dateRange[0] ||
							!dateRange[1]
						}
					>
						{isLoading ? '下載中' : '下載'}
					</Button>
				</Space>
			</Card>
		</>
	);
}
export default MarketFixData;
