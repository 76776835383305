import React, { useEffect, useState } from 'react';
import type { AppDispatch } from 'src';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	getIsFullscreenState,
	setIsFullscreen,
} from '@store/reducers/utilSlice';
import { Button, Card, DatePicker, Modal, Select, Switch, Tag } from 'antd';
import { OrgTree, Role } from '@models/user';
import { Icon } from '@models/icon';
import { ServiceType, UnitType, weekdays } from '@models/common';
import dayjs, { Dayjs } from 'dayjs';
import fileDownload from 'js-file-download';
import { isEmpty } from 'lodash';
import api from '@api/api';
import './OperatingService.less';

import SRChart from '@pages/OperatingService/SRChart';
import DREGChart from '@pages/OperatingService/DREGChart';
import CommandHistory from '@pages/OperatingService/CommandHistory';

const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

export enum TimeType {
	WeekOrImmediate,
	Specify,
}

export interface SelectedService {
	subTitle: string;
	category: number;
	serviceType: number;
	unitType: string;
}

interface TXGDropdown extends OrgTree {
	className: string;
	value: string;
	label: string;
	disabled: boolean;
}

function OperatingService() {
	const dispatch = useDispatch<AppDispatch>();
	const isFullscreen = useSelector(getIsFullscreenState);

	const [qseDropdownIsOpen, setQSEDropdownIsOpen] = useState(false);
	const [txgDropdownIsOpen, setTXGDropdownIsOpen] = useState(false);
	const [qseDropdownList, setQSEDropdownList] = useState([] as OrgTree[]);
	const [txgDropdownList, setTXGDropdownList] = useState([] as TXGDropdown[]);
	const [selectedQSE, setSelectedQSE] = useState('');
	const [selectedTXG, setSelectedTXG] = useState('');
	const [cardTitle, setCardTitle] = useState('');
	const [updateTime, setUpdateTime] = useState('00:00:00');
	const [timeType, setTimeType] = useState(TimeType.WeekOrImmediate);
	const [serviceObj, setServiceObj] = useState({} as SelectedService);
	const [selectedWeekday, setSelectedWeekday] = useState(dayjs().isoWeekday());
	const [dateRange, setDateRange] = useState<RangeValue>([
		dayjs().subtract(6, 'day'),
		dayjs(),
	]);
	const [dateTime, setDateTime] = useState<Dayjs | null>(
		dayjs().subtract(10, 'minute')
	);
	const [showDayDivider, setShowDayDivider] = useState(false);
	const [apiIsLoading, setApiIsLoading] = useState(false);

	const [selectedTxgIdFromOverview, setSelectedTxgIdFromOverview] =
		useState('');
	const { state } = useLocation();

	const allOrgTreeList: OrgTree[] = JSON.parse(
		localStorage.getItem('energy-dispatch-allOrgTree') as string
	);
	const userJson = JSON.parse(
		localStorage.getItem('energy-dispatch-user') as string
	);

	const onOpenChange = (open: boolean) => {
		if (open) {
			setDateRange([null, null]);
		}
	};

	const cardExtra = (): JSX.Element => {
		let isToday = false;
		if (timeType === TimeType.WeekOrImmediate) {
			const date = (dayjs() as Dayjs)
				.startOf('week')
				.add(selectedWeekday - 1, 'day')
				.format('YYYY-MM-DD');
			isToday = dayjs(date).isToday();
		} else {
			if (!dateRange || !dateRange[0] || !dateRange[1]) {
				return (
					<div className="update-info">
						<div>頁面更新時間: {updateTime}</div>
					</div>
				);
			}

			const dateRangeStart = dayjs(dateRange[0]).format('YYYY-MM-DD');
			const dateRangeEnd = dayjs(dateRange[1]);
			isToday = dayjs(dateRangeEnd).isToday();
		}
		console.log('isToday:', isToday);

		return (
			<div className="update-info">
				<div>頁面更新時間: {updateTime}</div>
				{isToday ? <Tag>自動更新</Tag> : ''}
			</div>
		);
	};

	const toggleFullscreen = () => {
		dispatch(setIsFullscreen(!isFullscreen));
	};

	const handleUpdateTime = (time: string) => {
		setUpdateTime(time);
	};

	const handleApiIsLoading = (isLoading: boolean) => {
		setApiIsLoading(isLoading);
	};

	const serviceTypeCorrespond = (id: string) => {
		console.log('serviceTypeCorrespond:', serviceObj);
		const selectedItem = txgDropdownList.filter((t) => t.unitId === id)[0];
		const title = selectedItem.unitType === UnitType.TXG ? '聚合' : '場域';

		let result = {
			subTitle: '即時備轉監控',
			category: ServiceType.SR,
			serviceType: selectedItem.serviceType,
			unitType: selectedItem.unitType,
		};

		switch (selectedItem.serviceType) {
			case ServiceType.DREG:
				result = {
					...result,
					subTitle: '調頻備轉監控',
					category: ServiceType.DREG,
				};
				break;
			case ServiceType.SREG:
				result = {
					...result,
					subTitle: '靜態調頻備轉監控',
					category: ServiceType.DREG,
				};
				break;
			case ServiceType.SR:
			default:
				result = {
					...result,
					subTitle: '即時備轉監控',
					category: ServiceType.SR,
				};
				break;
			case ServiceType.SUP:
				result = {
					...result,
					subTitle: '補充備轉監控',
					category: ServiceType.SR,
				};
				break;
			case ServiceType.EDREG:
				result = {
					...result,
					subTitle: '增強型動態調頻備轉監控',
					category: ServiceType.DREG,
				};
				break;
		}

		setCardTitle(title + result.subTitle);
		setServiceObj({ ...result });
	};

	const handleExport = async () => {
		console.log('handleExport', serviceObj, timeType);
		const serviceType = serviceObj.serviceType;
		const unitType = serviceObj.unitType;

		let date = '';
		let diff = 0;
		let start = '';
		let end = '';

		if (timeType === TimeType.WeekOrImmediate) {
			date = (dayjs() as Dayjs)
				.startOf('week')
				.add(selectedWeekday - 1, 'day')
				.format('YYYY-MM-DD');
			diff = 1;
		} else {
			if (serviceObj.category === ServiceType.SR) {
				if (!dateRange || !dateRange[0] || !dateRange[1]) {
					return;
				}

				const dateRangeStart = dayjs(dateRange[0]).format('YYYY-MM-DD');
				const dateRangeEnd = dayjs(dateRange[1]);
				date = dateRangeStart;
				diff = dateRangeEnd.diff(dateRangeStart, 'day') + 1;
			} else {
				if (!dateTime) {
					return;
				}

				start = dateTime.format('YYYY-MM-DD HH:mm:ss');
				end = dateTime.add(10, 'minute').format('YYYY-MM-DD HH:mm:ss');
			}
		}
		console.log('date diff start end:', date, diff, start, end);

		const url =
			serviceType === ServiceType.SR
				? unitType === UnitType.TXG
					? `/webapi/history/ed/download/${selectedTXG}?date=${date}&count=${diff}`
					: `/webapi/history/ed/field/download/${selectedTXG}?date=${date}&count=${diff}`
				: unitType === UnitType.TXG
				? `/webapi/history/ed/dreg/download?id=${selectedTXG}&start=${start}&end=${end}`
				: `/webapi/history/ed/dreg/field/download?id=${selectedTXG}&start=${start}&end=${end}`;

		const res = await api.post(url, { responseType: 'blob' });
		console.log('handleExport resresresres', res);

		if (res.data?.status === 'fail') {
			return Modal.error({
				title: '檔案無法下載',
				content: '請確認網路連線狀態，並重新操作。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		fileDownload(res.data, `${selectedTXG}.csv`);

		Modal.success({
			title: '檔案已經下載至您的電腦',
			content: '請點擊確定按鈕返回頁面',
			okText: '確定',
			centered: true,
			closable: true,
			closeIcon: <Icon.ModalClose />,
		});
	};

	useEffect(() => {
		setQSEDropdownList(allOrgTreeList);
		setSelectedQSE(allOrgTreeList[0].unitId);
	}, []);

	useEffect(() => {
		if (state) {
			setSelectedTxgIdFromOverview(state.selectedTxgId);
		}
		return;
	}, [state]);

	useEffect(() => {
		console.log('selectedQSE is changed', allOrgTreeList, selectedQSE);
		if (selectedQSE) {
			const role = userJson.authority[0] ?? 1;
			const mainQSE = allOrgTreeList.filter((a) => a.unitId === selectedQSE)[0]
				.subUnits;
			const newArr: TXGDropdown[] = [];

			mainQSE.forEach((m) => {
				newArr.push({
					...m,
					className: 'group',
					key: 'txg' + m.unitId,
					value: m.unitId,
					label: m.unitName,
					disabled: [Role.SYSAdmin, Role.QSEAdmin, Role.QSEUser].includes(role)
						? false
						: m.unitId !== userJson.unitId,
				});

				m?.subUnits.forEach((s) => {
					newArr.push({
						...s,
						className: 'item',
						serviceType: m.serviceType,
						key: 'res' + s.unitId,
						value: s.unitId,
						label: s.unitName,
						disabled:
							[Role.SYSAdmin, Role.QSEAdmin, Role.QSEUser].includes(role) ||
							m.unitId === userJson.unitId
								? false
								: s.unitId !== userJson.unitId,
					});
				});
			});

			console.log('newArr:', newArr);
			setTXGDropdownList(newArr);

			setSelectedTXG(
				(selectedTxgIdFromOverview ||
					newArr.find((n) => !n.disabled)?.unitId) ??
					''
			);
		}
	}, [selectedQSE]);

	useEffect(() => {
		if (selectedTXG) {
			console.log('selectedTXG is changed', txgDropdownList, selectedTXG);
			serviceTypeCorrespond(selectedTXG);
		}
	}, [selectedTXG]);

	useEffect(() => {
		if (timeType === TimeType.Specify) {
			setDateTime(dayjs().subtract(10, 'minute'));
		}

		if (
			timeType === TimeType.WeekOrImmediate &&
			serviceObj.category === ServiceType.DREG
		) {
			setSelectedWeekday(dayjs().isoWeekday());
		}
	}, [timeType, serviceObj]);

	return (
		<>
			<Card
				title={cardTitle}
				bordered={false}
				extra={cardExtra()}
				className={`operating-service ${isFullscreen ? 'card-fullscreen' : ''}`}
			>
				{apiIsLoading ? <div className="api-is-loading"></div> : ''}

				<div className="dropdown-part">
					<div className="dropdown-group">
						<Select
							popupClassName={'qse-group-dropdown'}
							style={{ width: 125 }}
							onDropdownVisibleChange={() => {
								setQSEDropdownIsOpen((prevState: boolean) => !prevState);
							}}
							value={selectedQSE}
							onChange={(arg) => setSelectedQSE(arg)}
							suffixIcon={
								qseDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
							}
							options={qseDropdownList.map((qse: OrgTree) => ({
								value: qse.unitId,
								label: qse.unitName,
								disabled: qse.subUnits.length === 0,
							}))}
						/>
						<Select
							popupClassName={'txg-group-dropdown'}
							style={{ width: 155 }}
							onDropdownVisibleChange={() => {
								setTXGDropdownIsOpen((prevState: boolean) => !prevState);
							}}
							onChange={(arg) => setSelectedTXG(arg)}
							suffixIcon={
								txgDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
							}
							value={selectedTXG}
							options={txgDropdownList}
						/>
					</div>
					<div className="fullscreen-btn" onClick={() => toggleFullscreen()}>
						{isFullscreen ? (
							<>
								<Icon.Minimize />
								<span>取消全螢幕</span>
							</>
						) : (
							<>
								<Icon.Fullscreen />
								<span>全螢幕</span>
							</>
						)}
					</div>
				</div>

				<div className="date-select-part">
					<div className="type-select">
						<div className="current-month-date">
							{dayjs().format('MMMM')} {dayjs().format('YYYY')}
						</div>
						<div className="type-toggle">
							<Button
								size="large"
								className={
									timeType === TimeType.WeekOrImmediate ? 'selected' : ''
								}
								onClick={() => {
									if (apiIsLoading) return;
									setTimeType(TimeType.WeekOrImmediate);
								}}
							>
								{serviceObj.category === ServiceType.SR ? '當周' : '即時'}
							</Button>
							<Button
								size="large"
								className={
									timeType === TimeType.WeekOrImmediate ? '' : 'selected'
								}
								onClick={() => {
									if (apiIsLoading) return;
									setTimeType(TimeType.Specify);
								}}
							>
								指定
							</Button>
						</div>
					</div>
					<div className="date-select">
						{timeType === TimeType.WeekOrImmediate ? (
							<div className="type-week">
								{weekdays.map((d) => (
									<div
										className={`weekday ${
											weekdays.indexOf(d) + 1 === selectedWeekday
												? 'selected'
												: ''
										} ${
											serviceObj.category === ServiceType.DREG &&
											weekdays.indexOf(d) + 1 !== selectedWeekday
												? 'disabled'
												: ''
										}`}
										key={weekdays.indexOf(d)}
										onClick={() => {
											if (
												serviceObj.category === ServiceType.DREG ||
												apiIsLoading
											)
												return;

											setSelectedWeekday(weekdays.indexOf(d) + 1);
										}}
									>
										<div className="content">
											<div className="title">{d}</div>
											<div className="date">
												{
													(dayjs() as any)
														.startOf('week')
														.add(weekdays.indexOf(d), 'day').$D
												}
											</div>
										</div>
									</div>
								))}
							</div>
						) : (
							<div className="type-specify">
								{serviceObj.category === ServiceType.SR ? (
									<RangePicker
										style={{ width: 260 }}
										value={dateRange}
										disabledDate={(current: Dayjs) =>
											current && current > dayjs().endOf('day')
										}
										onOpenChange={onOpenChange}
										onChange={(range) => {
											if (
												range &&
												range[0] &&
												range[1] &&
												range[1].diff(range[0], 'day') > 6
											) {
												console.log(
													'DateRange > 7',
													range[1].diff(range[0], 'day')
												);
												return Modal.error({
													title: '格式錯誤',
													content: '請確認日期範圍不得大於 7 天，並重新操作。',
													okText: '確定',
													centered: true,
													closable: true,
													closeIcon: <Icon.ModalClose />,
												});
											}

											setDateRange(range);
										}}
										suffixIcon={<Icon.Calendar />}
									/>
								) : (
									<DatePicker
										style={{ width: 210 }}
										value={dateTime}
										suffixIcon={<Icon.Day />}
										format="YYYY-MM-DD HH:mm:ss"
										disabledDate={(current: Dayjs) =>
											current &&
											current > dayjs().subtract(10, 'minute').endOf('second')
										}
										showTime
										onChange={(val) => setDateTime(val)}
									/>
								)}
								<div className="show-day-divider">
									{serviceObj.category === ServiceType.SR ? (
										<>
											<Switch
												checked={showDayDivider}
												onChange={(arg) => setShowDayDivider(arg)}
											/>
											顯示日分隔線
										</>
									) : (
										''
									)}
								</div>
							</div>
						)}
					</div>
					<div className="export-btn">
						<Button
							size="large"
							onClick={() => handleExport()}
							disabled={
								serviceObj.category === ServiceType.DREG &&
								timeType === TimeType.WeekOrImmediate
							}
						>
							匯出
						</Button>
					</div>
				</div>

				{selectedTXG && !isEmpty(serviceObj) ? (
					serviceObj.category === ServiceType.SR ? (
						<SRChart
							selectedTXG={selectedTXG}
							timeType={timeType}
							serviceObj={serviceObj}
							selectedWeekday={selectedWeekday}
							dateRange={dateRange}
							showDayDivider={showDayDivider}
							handleUpdateTime={handleUpdateTime}
							handleApiIsLoading={handleApiIsLoading}
						/>
					) : (
						<DREGChart
							selectedTXG={selectedTXG}
							timeType={timeType}
							serviceObj={serviceObj}
							dateTime={dateTime}
							handleUpdateTime={handleUpdateTime}
							handleApiIsLoading={handleApiIsLoading}
						/>
					)
				) : (
					''
				)}
			</Card>

			<CommandHistory
				selectedTXG={selectedTXG}
				timeType={timeType}
				serviceObj={serviceObj}
				dateTime={dateTime}
				selectedWeekday={selectedWeekday}
				dateRange={dateRange}
			/>
		</>
	);
}
export default OperatingService;
