import React, { ReactNode, useEffect } from 'react';
import NoData from '@components/NoData/NoData';
import type { AppDispatch } from 'src';
import { useDispatch } from 'react-redux';
import { setCustomThemeToken } from '@store/reducers/utilSlice';
import { ConfigProvider, theme } from 'antd';
import locale from 'antd/locale/zh_TW';
import useTheme from '@hooks/useTheme';
interface Props {
	children?: ReactNode;
}

const darkTheme = {
	borderRadius: 6,
	borderRadiusLG: 6,
	colorBgLayout: '#181818',
	colorBgContainer: '#2B2D3F',
	colorPrimary: '#22AC39',
	colorPrimaryBg: '#94D03E',
	colorPrimaryHover: '#22AC39',
	colorPrimaryTextHover: '#22AC39',
	colorErrorText: '#F33535',
	colorHighlight: '#F33535',
	colorSuccess: '#3EC011',
	colorBgMask: 'rgba(0, 0, 0, 0.7)',

	colorText: '#fff',
	colorTextSecondary: '#667085',
	colorTextTertiary: '#888d9e',
	colorTextQuaternary: '#B0B4C0',

	colorBorder: '#4B5466',
	colorBorderSecondary: '#1E1E1E',
	colorChartNormal: '#667085',
};

const lightTheme = {
	borderRadius: 6,
	borderRadiusLG: 6,
	colorBgLayout: '#F2F4F7',
	colorBgContainer: '#fff',
	colorPrimary: '#22AC39',
	colorPrimaryBg: '#CCE198',
	colorPrimaryHover: '#22AC39',
	colorPrimaryTextHover: '#22AC39',
	colorErrorText: '#F33535',
	colorHighlight: '#F33535',
	colorSuccess: '#3EC011',
	colorBgMask: 'rgba(0, 0, 0, 0.7)',

	colorText: '#344054',
	colorTextSecondary: '#344054',
	colorTextTertiary: '#667085',
	colorTextQuaternary: '#B0B4C0',

	colorBorder: '#d0d5dd',
	colorBorderSecondary: '#F2F4F7',
	colorChartNormal: '#B0B4C0',
};

const ThemeProvider = ({ children }: Props) => {
	const dispatch = useDispatch<AppDispatch>();
	const [darkMode] = useTheme();

	const styles = darkMode
		? `
    body {
			--border-radius: ${darkTheme.borderRadius};
			--border-radius-LG: ${darkTheme.borderRadiusLG};
			--color-bg-layout: ${darkTheme.colorBgLayout};
			--color-bg-container: ${darkTheme.colorBgContainer};
			--color-primary: ${darkTheme.colorPrimary};
			--color-primary-bg: ${darkTheme.colorPrimaryBg};
			--color-primary-hover: ${darkTheme.colorPrimaryHover};
			--color-primary-text-hover: ${darkTheme.colorPrimaryTextHover};
			--color-error-text: ${darkTheme.colorErrorText};
			--color-highlight: ${darkTheme.colorHighlight};
			--color-success: ${darkTheme.colorSuccess};
			--color-bg-mask: ${darkTheme.colorBgMask};
			
			--color-text: ${darkTheme.colorText};
			--color-text-secondary: ${darkTheme.colorTextSecondary};
			--color-text-tertiary: ${darkTheme.colorTextTertiary};
			--color-text-quaternary: ${darkTheme.colorTextQuaternary};
			--color-border: ${darkTheme.colorBorder};
			--color-border-secondary: ${darkTheme.colorBorderSecondary};
			--color-chart-normal:${darkTheme.colorChartNormal};

			--color-menu-arrow: ${darkTheme.colorPrimary};
			--color-always-white: #fff;
			--color-date-select-bar-bg: #8FC31F;
			--color-table-head-bg: #475467;
			--color-back-top-border: #00913A;
			--color-svg-path: #D0D5DD;
			--color-table-row-even: #353949;
			--color-card-action-border: #4B5466;
    }
  `
		: `
		body {
			--border-radius: ${lightTheme.borderRadius};
			--border-radius-LG: ${lightTheme.borderRadiusLG};
			--color-bg-layout: ${lightTheme.colorBgLayout};
			--color-bg-container: ${lightTheme.colorBgContainer};
			--color-primary: ${lightTheme.colorPrimary};
			--color-primary-bg: ${lightTheme.colorPrimaryBg};
			--color-primary-hover: ${lightTheme.colorPrimaryHover};
			--color-primary-text-hover: ${lightTheme.colorPrimaryTextHover};
			--color-error-text: ${lightTheme.colorErrorText};
			--color-highlight: ${lightTheme.colorHighlight};
			--color-success: ${lightTheme.colorSuccess};
			--color-bg-mask: ${lightTheme.colorBgMask};
			
			--color-text: ${lightTheme.colorText};
			--color-text-secondary: ${lightTheme.colorTextSecondary};
			--color-text-tertiary: ${lightTheme.colorTextTertiary};
			--color-text-quaternary: ${lightTheme.colorTextQuaternary};
			--color-border: ${lightTheme.colorBorder};
			--color-border-secondary: ${lightTheme.colorBorderSecondary};
			--color-chart-normal:${lightTheme.colorChartNormal};

			--color-menu-arrow: #98A2B3;
			--color-always-white: #fff;
			--color-date-select-bar-bg: #8FC31F;
			--color-table-head-bg: #E4E7EC;
			--color-svg-path: #667085;
			--color-table-row-even: #F2F4F7;
			--color-card-action-border: #F2F4F7;
    }
	`;

	useEffect(() => {
		console.log('setCustomThemeToken');
		dispatch(setCustomThemeToken(darkMode ? darkTheme : lightTheme));
	}, []);

	return (
		<>
			<ConfigProvider
				theme={{
					algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
					token: darkMode ? darkTheme : lightTheme,
				}}
				locale={locale}
				renderEmpty={() => <NoData />}
			>
				{children}
				<style>{styles}</style>
			</ConfigProvider>
		</>
	);
};

export default ThemeProvider;
