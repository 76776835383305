import { ReactComponent as LoginLogo } from '@assets/svg/login-logo.svg';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import { ReactComponent as LoginUser } from '@assets/svg/login-user.svg';
import { ReactComponent as LoginLock } from '@assets/svg/login-lock.svg';
import { ReactComponent as HeaderUser } from '@assets/svg/header-user.svg';
import { ReactComponent as Fullscreen } from '@assets/svg/fullscreen.svg';
import { ReactComponent as Minimize } from '@assets/svg/minimize.svg';
import { ReactComponent as UpArrow } from '@assets/svg/up-arrow.svg';
import { ReactComponent as DownArrow } from '@assets/svg/down-arrow.svg';
import { ReactComponent as ExpandArrow } from '@assets/svg/expand-arrow.svg';
import { ReactComponent as CollapseArrow } from '@assets/svg/collapse-arrow.svg';
import { ReactComponent as Plus } from '@assets/svg/plus.svg';
import { ReactComponent as Minus } from '@assets/svg/minus.svg';
import { ReactComponent as Calendar } from '@assets/svg/calendar.svg';
import { ReactComponent as Edit } from '@assets/svg/edit.svg';
import { ReactComponent as Check } from '@assets/svg/check.svg';
import { ReactComponent as Close } from '@assets/svg/close.svg';
import { ReactComponent as NoData } from '@assets/svg/no-data.svg';
import { ReactComponent as Day } from '@assets/svg/day.svg';
import { ReactComponent as ModalClose } from '@assets/svg/modal-close.svg';
import { ReactComponent as MessageError } from '@assets/svg/message-square-error.svg';
import { ReactComponent as Card } from '@assets/svg/card.svg';
import { ReactComponent as Table } from '@assets/svg/table.svg';
import { ReactComponent as EyeClose } from '@assets/svg/eye-close.svg';
import { ReactComponent as EyeOpen } from '@assets/svg/eye-open.svg';
import { ReactComponent as InputMinus } from '@assets/svg/input-minus.svg';
import { ReactComponent as InputPlus } from '@assets/svg/input-plus.svg';
import { ReactComponent as BackTop } from '@assets/svg/back-top.svg';
import { ReactComponent as ChevronDown } from '@assets/svg/chevron-down.svg';
import { ReactComponent as ChevronRight } from '@assets/svg/chevron-right.svg';
import { ReactComponent as MessageWarn } from '@assets/svg/message-warn.svg';
import { ReactComponent as Link } from '@assets/svg/link.svg';
import { ReactComponent as Chart } from '@assets/svg/chart.svg';
import { ReactComponent as RightArrow } from '@assets/svg/right-arrow.svg';

import { ReactComponent as PageMarket } from '@assets/svg/menu/market.svg';
import { ReactComponent as PageOperate } from '@assets/svg/menu/monitor.svg';
import { ReactComponent as PagePerf } from '@assets/svg/menu/perf.svg';
import { ReactComponent as PageOrg } from '@assets/svg/menu/org.svg';
import { ReactComponent as PageSys } from '@assets/svg/menu/sys.svg';

export const Icon = {
	LoginLogo,
	Logo,
	LoginUser,
	LoginLock,
	HeaderUser,
	Fullscreen,
	Minimize,
	UpArrow,
	DownArrow,
	ExpandArrow,
	CollapseArrow,
	Plus,
	Minus,
	PageMarket,
	PageOperate,
	PagePerf,
	PageOrg,
	PageSys,
	Calendar,
	Edit,
	Check,
	Close,
	NoData,
	Day,
	ModalClose,
	MessageError,
	Card,
	Table,
	EyeClose,
	EyeOpen,
	InputMinus,
	InputPlus,
	BackTop,
	ChevronDown,
	ChevronRight,
	MessageWarn,
	Link,
	Chart,
	RightArrow,
};
