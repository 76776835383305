import React, { useEffect, useState } from 'react';
import { Collapse, Modal, Pagination, Row, Col, Tag } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { ConnectionStatus, loadTypeOptions } from '@models/organization';
import { Icon } from '@models/icon';
import api from '@api/api';
import dayjs from 'dayjs';
import './OperatingDeviceModal.less';

const { Panel } = Collapse;

type Props = {
	isOpenModal: boolean;
	setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
	selectedFieldId: string;
};

type OrganizationInfo = {
	id: string;
	name: string;
	deviceType: string;
	loadType: string;
	reportTime: number | string;
	connectionStatus: string;
	connectionStatusText: string;
	enableStatus: string;
	mainLoad: boolean;
	updateTimestamp: number | string;
	createTimestamp: number | string;
	sync: boolean;
};

type MonitorInfo = {
	activePower: number | null;
	energyNet: number | null;
	energyImp: number | null;
	energyExp: number | null;
	powerFactor: number | null;
	voltageA: number | null;
	currentA: number | null;
	voltageB: number | null;
	currentB: number | null;
	voltageC: number | null;
	currentC: number | null;
	soc: number | null;
	chargeKWh: number | null;
	dischargeKWh: number | null;
	temperature: number | null;
	voltage: number | null;
	current: number | null;
	kva: number | null;
	kvar: number | null;
	kwh: number | null;
};

type DeviceRow = {
	id: string;
	organization: OrganizationInfo;
	monitor: MonitorInfo;
};

const OrgInfoLabels = {
	id: 'ID',
	name: '設備名稱',
	deviceType: '設備類型',
	loadType: '負載類型',
	reportTime: '資料回報時間',
	connectionStatusText: '連線狀態',
	enableStatus: '啟用狀態',
	mainLoad: '是否為總表',
	updateTimestamp: '資料更新時間',
	createTimestamp: '資料建立時間',
	sync: 'sync',
};

const MonitorInfoLabels = [
	'activePower',
	'energyNet',
	'energyImp',
	'energyExp',
	'powerFactor',
	'voltageA',
	'currentA',
	'voltageB',
	'currentB',
	'voltageC',
	'currentC',
	'soc',
	'chargeKWh',
	'dischargeKWh',
	'temperature',
	'voltage',
	'current',
	'kva',
	'kvar',
	'kwh',
];

const renderInfos = (
	type: 'organization' | 'monitor',
	label: string,
	text: any
) => {
	return (
		<Row key={label}>
			<Col
				span={type === 'organization' ? 10 : 12}
				className="device-modal-detail-label"
			>
				{label}
			</Col>
			<Col
				span={type === 'organization' ? 14 : 12}
				className="device-modal-detail-text"
			>
				{text ?? '-'}
			</Col>
		</Row>
	);
};

const getOrganizationData = (fieldId: string) => {
	const response = api.get(`/webapi/organization/dev/list?fieldId=${fieldId}`);
	return response;
};

const getMonitorData = (fieldId: string) => {
	const response = api.get(`/webapi/monitor/device/list?fieldId=${fieldId}`);
	return response;
};

const OperatingDeviceModal = ({
	isOpenModal,
	setIsOpenModal,
	selectedFieldId,
}: Props) => {
	const pageSize = 5;
	const [isLoading, setIsLoading] = useState(false);
	const [allDeviceData, setAllDeviceData] = useState<DeviceRow[]>([]);
	const [displayDeviceData, setDisplayDeviceData] = useState<DeviceRow[]>([]);
	const [pageIndex, setPageIndex] = useState(1);
	const [chosenOne, setChosenOne] = useState<string>('');

	const deviceColumns: ColumnsType<DeviceRow> = [
		{
			title: '設備名稱',
			dataIndex: ['organization', 'name'],
			key: 'organization.name',
			width: '12%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">{record.organization.name}</div>
			),
		},
		{
			title: '設備類型',
			dataIndex: ['organization', 'deviceType'],
			key: 'organization.deviceType',
			width: '8.5%',
			align: 'center',
		},
		{
			title: '即時功率 (kW)',
			dataIndex: ['monitor', 'activePower'],
			key: 'monitor.activePower',
			width: '11%',
			align: 'center',
		},
		{
			title: '設備連線時間',
			dataIndex: ['organization', 'updateTimestamp'],
			key: 'organization.updateTimestamp',
			width: '10.5%',
			align: 'center',
		},
		{
			title: '設備狀態',
			dataIndex: ['organization', 'connectionStatus'],
			key: 'organization.connectionStatus',
			width: '8.5%',
			align: 'center',
			render: (_, record) => (
				<div>
					<Tag
						color={
							ConnectionStatus.filter(
								(c: { text: string; value: string; color: string }) =>
									c.value === record.organization.connectionStatus
							)[0].color
						}
					>
						{record.organization.connectionStatusText}
					</Tag>
				</div>
			),
		},
		{
			title: '設備細部資訊',
			dataIndex: 'id',
			key: 'id',
			align: 'center',
			render: (_, record) => (
				<Collapse
					className="device-modal-collapse"
					expandIcon={(panelProps) =>
						panelProps.isActive ? <Icon.UpArrow /> : <Icon.DownArrow />
					}
					expandIconPosition={'end'}
					onChange={() => {
						if (record.id !== chosenOne) {
							setChosenOne(String(record.id));
						} else {
							setChosenOne('');
						}
					}}
					activeKey={chosenOne}
				>
					<Panel header="設備細部資訊" key={record.id}>
						<Row
							justify={'space-around'}
							style={{ textAlign: 'left', alignItems: 'start' }}
						>
							<Col span={12}>
								{Object.entries(OrgInfoLabels).map(
									([parameter, label]: [string, any]) => {
										return renderInfos(
											'organization',
											label,
											(record.organization as any)[parameter].toString()
										);
									}
								)}
							</Col>
							<Col span={12}>
								{MonitorInfoLabels.map((item) => {
									return renderInfos(
										'monitor',
										item,
										(record.monitor as any)[item]
									);
								})}
							</Col>
						</Row>
					</Panel>
				</Collapse>
			),
		},
	];

	const getDeviceAllData = async (fieldId: string) => {
		setIsLoading(true);

		try {
			const res = await Promise.all([
				getOrganizationData(fieldId),
				getMonitorData(fieldId),
			]);
			console.log('getDeviceAllData res', res);

			const organizationData = res[0].data.list as OrganizationInfo[];
			const monitorData = res[1].data.list;
			const result: DeviceRow[] = organizationData.map((item) => {
				return {
					id: item.id,
					organization: {
						...item,
						loadType: loadTypeOptions.filter(
							(d) => d.value === item.loadType
						)[0].label,
						connectionStatusText: ConnectionStatus.filter(
							(c) => c.value === item.connectionStatus
						)[0].text,
						reportTime: dayjs(item.reportTime).format('YYYY/MM/DD HH:mm:ss'),
						updateTimestamp: dayjs(item.updateTimestamp).format(
							'YYYY/MM/DD HH:mm:ss'
						),
						createTimestamp: dayjs(item.createTimestamp).format(
							'YYYY/MM/DD HH:mm:ss'
						),
					},
					monitor: (monitorData.filter((m: any) => m.id === item.id)[0]
						?.measureData || {}) as MonitorInfo,
				};
			});
			console.log('getDeviceAllData result', result);

			setAllDeviceData(result);
			setIsLoading(false);
		} catch (err) {
			console.log('getDeviceAllData err', err);
			setIsLoading(false);
			return Modal.error({
				title: '找不到資料',
				content: '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}
	};

	useEffect(() => {
		if (selectedFieldId === '') {
			return;
		}

		getDeviceAllData(selectedFieldId);

		const deviceInterval = setInterval(
			() => getDeviceAllData(selectedFieldId),
			60 * 1000
		);
		return () => {
			clearInterval(deviceInterval);
		};
	}, [selectedFieldId]);

	useEffect(() => {
		if (pageIndex && allDeviceData.length > 0) {
			const startIndex = (pageIndex - 1) * pageSize;
			const endIndex = Math.min(
				startIndex + pageSize - 1,
				allDeviceData.length - 1
			);
			setDisplayDeviceData(allDeviceData.slice(startIndex, endIndex + 1));
		} else {
			setDisplayDeviceData([]);
		}
	}, [pageIndex, pageSize, allDeviceData]);

	return (
		<Modal
			title={'設備狀態'}
			open={isOpenModal}
			okButtonProps={{ style: { display: 'none' } }}
			cancelButtonProps={{ style: { display: 'none' } }}
			bodyStyle={{ maxHeight: '78vh', height: 'auto' }}
			width={'95%'}
			style={{ top: 60 }}
			maskClosable={false}
			destroyOnClose={true}
			forceRender={true}
			className="operating-device-modal"
			onCancel={() => {
				setIsOpenModal(false);
			}}
			closeIcon={<Icon.ModalClose />}
		>
			<div className="data-table">
				<Table
					columns={deviceColumns}
					dataSource={displayDeviceData}
					pagination={false}
					loading={isLoading}
					rowKey={(record) => record.id}
				/>
			</div>
			{allDeviceData.length > 0 ? (
				<div className="pagination-bar">
					<div className="right">
						<Pagination
							total={allDeviceData.length}
							current={pageIndex}
							onChange={(page) => {
								setPageIndex(page);
							}}
							pageSize={pageSize}
						/>
					</div>
				</div>
			) : null}
		</Modal>
	);
};

export default OperatingDeviceModal;
