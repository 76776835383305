import React, { useEffect, useState } from 'react';
import {
	DatePicker,
	Select,
	Button,
	Card,
	Table,
	Modal,
	Pagination,
	InputNumber,
	Upload,
	UploadFile,
	Space,
	Row,
	Col,
} from 'antd';
import {
	CheckCircleOutlined,
	DownOutlined,
	RightOutlined,
} from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { Icon } from '@models/icon';
import { OrgTree, Role } from '@models/user';
import { ServiceType } from '@models/common';
import dayjs, { Dayjs } from 'dayjs';
import fileDownload from 'js-file-download';
import { usePapaParse } from 'react-papaparse';
import api from '@api/api';
import './MarketCompetition.less';

interface TableData {
	key: React.ReactNode;
	id: string;
	timestamp: number;
	capacity: number;
	price: number | null;
	awarded_capacity: number;

	txgId?: string;
	resId?: string;
	oldCapacity?: number;
	oldPrice?: number | null;
	oldAwardedCapacity?: number;
	isEdit?: boolean;
	list?: TableData[];
}

enum CsvColumnWinningBidImport {
	DREG = 4,
	SR = 5,
	SUP = 6,
}

function MarketCompetition() {
	const [isLoading, setIsLoading] = useState(false);
	const [qseDropdownIsOpen, setQSEDropdownIsOpen] = useState(false);
	const [txgDropdownIsOpen, setTXGDropdownIsOpen] = useState(false);
	const [qseDropdownList, setQSEDropdownList] = useState([] as OrgTree[]);
	const [txgDropdownList, setTXGDropdownList] = useState([] as OrgTree[]);
	const [selectedQSE, setSelectedQSE] = useState('');
	const [selectedTXG, setSelectedTXG] = useState('');
	const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
	const [selectedCopyDate, setSelectedCopyDate] = useState<Dayjs | null>(null);
	const [copyDays, setCopyDays] = useState<number | null>(1);
	const [isOpenCopyModal, setIsOpenCopyModal] = useState(false);
	const [pageIndex, setPageIndex] = useState(1);
	const [pageSize, setPageSize] = useState(6);
	const [allTableData, setAllTableData] = useState([] as TableData[]);
	const [displayTableData, setDisplayTableData] = useState([] as TableData[]);
	const [energyPrice, setEnergyPrice] = useState(0);
	const { readString, jsonToCSV } = usePapaParse();

	const allOrgTreeList: OrgTree[] = JSON.parse(
		localStorage.getItem('energy-dispatch-allOrgTree') as string
	);
	const userJson = JSON.parse(
		localStorage.getItem('energy-dispatch-user') as string
	);
	const userRole = userJson.authority[0] ?? 1;
	const isOnlyRead = [Role.QSEUser, Role.TXGUser].includes(userRole);

	const columns: ColumnsType<TableData> = [
		{
			title: '時間',
			dataIndex: 'timestamp',
			key: 'timestamp',
			width: '18%',
			align: 'center',
			render: (_, record) => (
				<>{dayjs(record.timestamp).format('YYYY-MM-DD HH:mm')}</>
			),
		},
		{
			title: '可調度容量(MW)',
			dataIndex: 'capacity',
			key: 'capacity',
			width: '18%',
			align: 'center',
			render: (_, record) => (
				<>
					{record.id !== selectedTXG && record.isEdit ? (
						<InputNumber
							min={0}
							defaultValue={record.capacity ?? undefined}
							onChange={(value) =>
								onChangeColumn(
									record.id,
									record.timestamp,
									'capacity',
									value ?? 0
								)
							}
						/>
					) : (
						record.capacity
					)}
				</>
			),
		},
		{
			title: '容量報價(NT$/MW)',
			dataIndex: 'price',
			key: 'price',
			width: '18%',
			align: 'center',
			render: (_, record) => (
				<>
					{record.id === selectedTXG && record.isEdit ? (
						<InputNumber
							min={0}
							defaultValue={record.price ?? undefined}
							onChange={(value) =>
								onChangeColumn(record.id, record.timestamp, 'price', value ?? 0)
							}
						/>
					) : (
						record.price
					)}
				</>
			),
		},
		{
			title: '得標容量(MW)',
			dataIndex: 'awarded_capacity',
			key: 'awarded_capacity',
			width: '18%',
			align: 'center',
			render: (_, record) => (
				<>
					{record.id !== selectedTXG && record.isEdit ? (
						<InputNumber
							min={0}
							max={record.capacity}
							defaultValue={record.awarded_capacity ?? undefined}
							onChange={(value) =>
								onChangeColumn(
									record.id,
									record.timestamp,
									'awarded_capacity',
									value ?? 0
								)
							}
						/>
					) : (
						record.awarded_capacity
					)}
				</>
			),
		},
		{
			title: '場域',
			dataIndex: 'place',
			key: 'place',
			width: '18%',
			align: 'center',
			render: (_, record) => (
				<>
					{record.id === selectedTXG
						? txgDropdownList.filter((t) => t.unitId === selectedTXG)[0]
								?.unitName ?? ''
						: txgDropdownList
								.filter((t) => t.unitId === selectedTXG)[0]
								.subUnits.filter((r) => r.unitId === record.id)[0]?.unitName ??
						  ''}
				</>
			),
		},
		{
			title: '操作',
			key: 'action',
			align: 'center',
			className: isOnlyRead ? 'table-action disabled' : '',
			render: (_, record) => (
				<>
					{record.isEdit ? (
						<div className="action-btn">
							<Icon.Check
								onClick={() => confirmRow(record.id, record.timestamp)}
							/>
							<Icon.Close
								onClick={() => cancelRow(record.id, record.timestamp)}
							/>
						</div>
					) : (
						<Icon.Edit
							onClick={() =>
								!isOnlyRead && editRow(record.id, record.timestamp)
							}
						/>
					)}
				</>
			),
		},
	];

	const getCompetitionData = async () => {
		setIsLoading(true);
		const date = dayjs(
			dayjs(selectedDate).format('YYYY-MM-DD 00:00:00')
		).valueOf();

		const response = await api.get(
			`/webapi/system/bid/${selectedTXG}?date=${date}`
		);
		console.log('getCompetitionData resresresres', response);

		setIsLoading(false);
		if (response.data?.status !== 'ok') {
			return Modal.error({
				title: '找不到資料',
				content: '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		let arr: TableData[] = [];
		if (response.data?.list.length > 0) {
			arr = response.data?.list.map((txg: any, txgIdx: number) => ({
				...txg,
				key: 'txg' + (txgIdx + 1),
				id: txg.txgId,
				isEdit: false,
				list: txg.list.map((res: any, resIdx: number) => ({
					...res,
					key: 'res' + (txgIdx + 1) + (resIdx + 1),
					id: res.resId,
					isEdit: false,
				})),
			}));

			setEnergyPrice(response.data?.list[0].energyPrice);
		} else {
			for (let i = 0; i < 24; i++) {
				const date: number = dayjs(
					dayjs(selectedDate).format(`YYYY-MM-DD ${i < 10 ? `0${i}` : i}:00`)
				).valueOf();

				arr.push({
					key: 'txg' + (i + 1),
					id: selectedTXG,
					txgId: selectedTXG,
					timestamp: date,
					capacity: 0,
					price: null,
					awarded_capacity: 0,
					isEdit: false,
					list: txgDropdownList
						.filter((t) => t.unitId === selectedTXG)[0]
						.subUnits.map((res, idx) => ({
							key: 'res' + (i + 1) + (idx + 1),
							id: res.unitId,
							resId: res.unitId,
							timestamp: date,
							capacity: 0,
							price: null,
							awarded_capacity: 0,
							isEdit: false,
						})),
				});
			}

			setEnergyPrice(0);
		}

		setPageIndex(1);
		setAllTableData([...arr]);
	};

	const onChangeColumn = (
		id: string,
		timestamp: number,
		column: string,
		value: number
	) => {
		let arr = [...allTableData];

		arr = arr.map((a) => {
			if (a.timestamp === timestamp) {
				return {
					...a,
					...(a.id === id && {
						[column]: value,
					}),
					list: a?.list?.map((l) => ({
						...l,
						...(l.id === id && {
							[column]: value,
						}),
					})),
				};
			}

			return { ...a };
		});

		console.log(arr);
		setAllTableData([...arr]);
	};

	const editRow = (id: string, timestamp: number) => {
		let arr = [...allTableData];

		arr = arr.map((a) => {
			if (a.timestamp === timestamp) {
				return {
					...a,
					...(a.id === id && {
						isEdit: true,
						oldPrice: a.price,
					}),
					list: a?.list?.map((l) => ({
						...l,
						...(l.id === id && {
							isEdit: true,
							oldCapacity: l.capacity,
							oldAwardedCapacity: l.awarded_capacity,
						}),
					})),
				};
			}

			return { ...a };
		});

		console.log(arr);
		setAllTableData([...arr]);
	};

	const confirmRow = (id: string, timestamp: number) => {
		let arr = [...allTableData];

		arr = arr.map((a) => {
			if (a.timestamp === timestamp) {
				return {
					...a,
					...(a.id === id && { isEdit: false }),
					list: a?.list?.map((l) => ({
						...l,
						...(l.id === id && {
							isEdit: false,
						}),
					})),
				};
			}

			return { ...a };
		});

		console.log(arr);
		setAllTableData([...arr]);
	};

	const cancelRow = (id: string, timestamp: number) => {
		let arr = [...allTableData];

		arr = arr.map((a) => {
			if (a.timestamp === timestamp) {
				return {
					...a,
					...(a.id === id && { isEdit: false, price: a.oldPrice }),
					list: a?.list?.map((l) => ({
						...l,
						...(l.id === id && {
							isEdit: false,
							capacity: l.oldCapacity,
							awarded_capacity: l.oldAwardedCapacity,
						}),
					})),
				};
			}

			return { ...a };
		});

		console.log(arr);
		setAllTableData([...arr]);
	};

	const copyCompetitionData = async () => {
		const response = await api.put(`/webapi/system/bid/clone/${selectedTXG}`, {
			baseDate:
				'' + dayjs(dayjs(selectedDate).format('YYYY-MM-DD 00:00:00')).valueOf(),
			offset: copyDays ? copyDays - 1 : 1,
			targetDate:
				'' +
				dayjs(dayjs(selectedCopyDate).format('YYYY-MM-DD 00:00:00')).valueOf(),
		});
		console.log('copyCompetitionData resresresres', response);

		setIsOpenCopyModal(false);
		if (response.data?.status !== 'ok') {
			return Modal.error({
				title: '無法複製',
				content: '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		Modal.success({
			title: '複製成功',
			content: '請點擊確定按鈕返回頁面',
			okText: '確定',
			centered: true,
			closable: true,
			closeIcon: <Icon.ModalClose />,
		});
	};

	const bidRemittance = async () => {
		const date = dayjs(
			dayjs(selectedDate).format('YYYY-MM-DD 00:00:00')
		).valueOf();

		const res = await api.post(
			`/webapi/system/bid/download/${selectedTXG}?date=${date}`,
			{ responseType: 'blob' }
		);
		console.log('bidRemittance resresresres', res);

		if (res?.status !== 200) {
			return Modal.error({
				title: '檔案無法下載',
				content: '請確認網路連線狀態，並重新操作。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		fileDownload(
			res.data,
			`${selectedTXG}_${dayjs(selectedDate).format('YYYY-MM-DD')}.csv`
		);

		Modal.success({
			title: '檔案已經下載至您的電腦',
			content: '請點擊確定按鈕返回頁面',
			okText: '確定',
			centered: true,
			closable: true,
			closeIcon: <Icon.ModalClose />,
		});
	};

	const winningBidImport = (file: RcFile): Promise<boolean> => {
		const reader = new FileReader();

		return new Promise(() => {
			reader.readAsText(file);
			reader.onload = async (e) => {
				const content = e.target!.result as string;
				console.log('e.target!.result', e.target!.result);

				await readString(content, {
					worker: true,
					complete: (results) => {
						console.log('---------------------------');
						console.log(results);
						console.log('---------------------------');
						let fileDate = '';
						let fileTXG = '';
						const res = results.data as string[][];
						if (results.data.length > 0) {
							fileDate = res[1][0];
							fileTXG = res[1][2];
						}

						if (
							!fileDate ||
							!dayjs(selectedDate).isSame(fileDate, 'day') ||
							!fileTXG ||
							fileTXG !==
								txgDropdownList.filter((t) => t.unitId === selectedTXG)[0]
									.unitName
						) {
							Modal.error({
								title: '檔案無法匯入',
								content: '請確認檔案中的日期與發電機組，並重新操作。',
								okText: '確定',
								centered: true,
								closable: true,
								closeIcon: <Icon.ModalClose />,
							});
							return false;
						}

						const type: string =
							ServiceType[
								txgDropdownList.filter((t) => t.unitId === selectedTXG)[0]
									?.serviceType ?? ServiceType.SR
							];
						const column =
							CsvColumnWinningBidImport[
								type as keyof typeof CsvColumnWinningBidImport
							];
						console.log('type column:', type, column);

						let arr = [...allTableData];
						arr = arr.map((item) => ({
							...item,
							awarded_capacity: parseInt(
								res.filter(
									(r) => r[1] === dayjs(item.timestamp).format('HH:mm')
								)[0][column]
							),
						}));
						console.log('arr:', arr);

						setAllTableData([...arr]);
						return false;
					},
				});
			};
		});
	};

	const fieldBiddingExport = () => {
		const newArr: TableData[] = [];
		allTableData.forEach((a) => {
			newArr.push(a);
			newArr.push(...(a.list as TableData[]));
		});
		console.log('newArr:', newArr);

		const jsonData = newArr.map((a) => ({
			時間: dayjs(a.timestamp).format('HH:mm'),
			'可調度容量(MW)': a.capacity,
			'容量報價(NT$/MW)': a.price,
			場域: a.id,
		}));

		const results = jsonToCSV(jsonData);
		fileDownload(
			results,
			`${selectedTXG}_${dayjs(selectedDate).format('YYYY-MM-DD')}_field.csv`
		);

		Modal.success({
			title: '檔案已經下載至您的電腦',
			content: '請點擊確定按鈕返回頁面',
			okText: '確定',
			centered: true,
			closable: true,
			closeIcon: <Icon.ModalClose />,
		});
	};

	const fieldBiddingImport = (file: RcFile): Promise<boolean> => {
		const reader = new FileReader();

		return new Promise(() => {
			reader.readAsText(file);
			reader.onload = async (e) => {
				const content = e.target!.result as string;
				console.log('e.target!.result', e.target!.result);

				await readString(content, {
					worker: true,
					complete: (results) => {
						console.log('---------------------------');
						console.log(results);
						console.log('---------------------------');
						let fileRES = '';
						const resp = results.data as string[][];
						const resList = txgDropdownList.filter(
							(t) => t.unitId === selectedTXG
						)[0].subUnits;

						if (results.data.length > 0) {
							fileRES = resp[2][3];
						}

						if (
							!fileRES ||
							resList.filter((t) => t.unitName === fileRES).length === 0
						) {
							Modal.error({
								title: '檔案無法匯入',
								content: '請確認檔案中的場域，並重新操作。',
								okText: '確定',
								centered: true,
								closable: true,
								closeIcon: <Icon.ModalClose />,
							});
							return false;
						}

						let arr = [...allTableData];
						arr = arr.map((item) => {
							const txgRow = resp.filter(
								(r) =>
									r[0] === dayjs(item.timestamp).format('HH:mm') && r[3] === ''
							)[0];
							console.log('txgRow:', txgRow);

							return {
								...item,
								capacity: parseInt(txgRow[1]),
								price: parseInt(txgRow[2]),
								list: (item.list as TableData[]).map((i) => {
									const resRow = resp.filter(
										(r) =>
											r[0] === dayjs(item.timestamp).format('HH:mm') &&
											r[3] ===
												resList.filter((l) => l.unitId === i.id)[0].unitName
									)[0];
									console.log('resRow:', resRow);

									return {
										...i,
										capacity: parseInt(resRow[1]),
									};
								}),
							};
						});
						console.log('arr:', arr);

						setAllTableData([...arr]);
						return false;
					},
				});
			};
		});
	};

	const energyPriceImport = (info: UploadChangeParam<UploadFile<any>>) => {
		if (info.file.status !== 'uploading') {
			console.log(info.file, info.fileList);
		}
		if (info.file.status === 'done') {
			Modal.success({
				title: '檔案已經上傳成功',
				content: '請點擊確定按鈕返回頁面',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		} else if (info.file.status === 'error') {
			Modal.error({
				title: '檔案無法上傳',
				content: '請確認網路連線狀態，並重新操作。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}
	};

	const updateData = async () => {
		const errorOpt = {
			title: '無法更新',
			content: '請確認所有編輯框都有按下確認',
			okText: '確定',
			centered: true,
			closable: true,
			closeIcon: <Icon.ModalClose />,
		};

		for (let i = 0; i < allTableData.length; i++) {
			if (allTableData[i].isEdit) {
				return Modal.error(errorOpt);
			}

			if (allTableData[i].list) {
				if (allTableData[i].list!.some((a) => a.isEdit)) {
					return Modal.error(errorOpt);
				}
			}
		}

		const res = await api.put(
			`/webapi/system/bid/${selectedTXG}?energyPrice=${energyPrice}`,
			[...allTableData]
		);
		console.log('updateData resresresres', res);

		getCompetitionData();
		if (res?.data.status !== 'ok') {
			return Modal.error({
				title: '無法更新',
				content: res?.data.err.msg ?? '請確認網路連線狀態，並重新操作。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		Modal.success({
			title: '更新成功',
			content: '請點擊確定按鈕返回頁面',
			okText: '確定',
			centered: true,
			closable: true,
			closeIcon: <Icon.ModalClose />,
		});
	};

	useEffect(() => {
		if (allOrgTreeList.length > 0) {
			setQSEDropdownList(allOrgTreeList);
			setSelectedQSE(allOrgTreeList[0].unitId);
		}
	}, []);

	useEffect(() => {
		if (selectedQSE) {
			const newArr = allOrgTreeList.filter((a) => a.unitId === selectedQSE)[0]
				.subUnits;
			setTXGDropdownList(newArr);
			setSelectedTXG(newArr[0]?.unitId ?? '');
		}
	}, [selectedQSE]);

	useEffect(() => {
		if (selectedQSE && selectedTXG && selectedDate) {
			getCompetitionData();
		}
	}, [selectedQSE, selectedTXG, selectedDate]);

	useEffect(() => {
		if (pageIndex && pageSize && allTableData.length > 0) {
			const startIndex = (pageIndex - 1) * pageSize;
			const endIndex = Math.min(
				startIndex + pageSize - 1,
				allTableData.length - 1
			);

			setDisplayTableData(allTableData.slice(startIndex, endIndex + 1));
		}
	}, [pageIndex, pageSize, allTableData]);

	return (
		<>
			<Card
				title="競價作業管理"
				bordered={false}
				className="market-competition"
			>
				<div className="tool-bar">
					<div className="left">
						<Select
							style={{ width: 180 }}
							onDropdownVisibleChange={() => {
								setQSEDropdownIsOpen((prevState: boolean) => !prevState);
							}}
							value={selectedQSE}
							onChange={(arg) => setSelectedQSE(arg)}
							suffixIcon={
								qseDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
							}
							options={qseDropdownList.map((qse: OrgTree) => ({
								value: qse.unitId,
								label: qse.unitName,
								disabled: qse.subUnits.length === 0,
							}))}
						/>
						<Select
							style={{ width: 180 }}
							onDropdownVisibleChange={() => {
								setTXGDropdownIsOpen((prevState: boolean) => !prevState);
							}}
							value={selectedTXG}
							onChange={(arg) => setSelectedTXG(arg)}
							suffixIcon={
								txgDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
							}
							options={txgDropdownList.map((txg: OrgTree) => ({
								value: txg.unitId,
								label: txg.unitName,
								disabled: [Role.SYSAdmin, Role.QSEAdmin, Role.QSEUser].includes(
									userRole
								)
									? false
									: txg.unitId !== userJson.unitId,
							}))}
						/>
					</div>
					<div className="right">
						<DatePicker
							style={{ width: 145 }}
							onChange={(arg) => setSelectedDate(arg)}
							suffixIcon={<Icon.Day />}
							value={selectedDate}
							placeholder={'開始時間'}
							format={'YYYY-MM-DD'}
						/>

						<Button
							ghost
							type="primary"
							onClick={() => setIsOpenCopyModal(true)}
							disabled={isOnlyRead}
						>
							複製資料至指定日期
						</Button>
						<Modal
							title="複製即時備轉競標資料"
							centered
							open={isOpenCopyModal}
							okText="複製"
							onOk={() => copyCompetitionData()}
							onCancel={() => setIsOpenCopyModal(false)}
							cancelButtonProps={{ style: { display: 'none' } }}
							width={450}
							maskClosable={false}
							closeIcon={<Icon.ModalClose />}
						>
							<Space
								direction="vertical"
								size="large"
								style={{ display: 'flex' }}
							>
								<Row className="copy-modal-point">
									<CheckCircleOutlined />
									<span>
										已經複製
										{dayjs(selectedDate).format('YYYY-MM-DD')}
										的競標資料，請選擇共用資料日期
									</span>
								</Row>
								<Row>
									<Col span={8}>
										<span className="required-field">*</span> 共用資料起始
									</Col>
									<Col span={16}>
										<DatePicker
											style={{ width: '100%' }}
											onChange={(arg) => setSelectedCopyDate(arg)}
											suffixIcon={<Icon.Day />}
											value={selectedCopyDate}
											placeholder={'請選擇起始日期'}
											format={'YYYY-MM-DD'}
										/>
									</Col>
								</Row>
								<Row>
									<Col span={7}>
										<span className="required-field">*</span>{' '}
										起始日起連續共用天數
									</Col>
									<Col span={16} offset={1}>
										<div className="copy-date-part">
											<InputNumber
												min={1}
												onChange={(arg) => setCopyDays(arg)}
												value={copyDays}
												className="copy-days"
												size="large"
											/>
											<div className="copy-start">
												開始：
												{selectedCopyDate
													? dayjs(selectedCopyDate).format('YYYY-MM-DD')
													: '-'}
											</div>
											<div className="copy-end">
												結束：
												{selectedCopyDate && (copyDays || copyDays === 0)
													? dayjs(selectedCopyDate)
															.add(copyDays - 1, 'day')
															.format('YYYY-MM-DD')
													: '-'}
											</div>
										</div>
									</Col>
								</Row>
							</Space>
						</Modal>

						<Upload
							method="put"
							showUploadList={false}
							action={`/webapi/system/energyPrice/${selectedTXG}`}
							headers={{
								Authorization: `Bearer ${userJson.token}`,
							}}
							accept=".csv"
							onChange={(info) => energyPriceImport(info)}
						>
							<Button ghost type="primary" disabled={isOnlyRead}>
								電能價格匯入
							</Button>
						</Upload>
					</div>
				</div>
				<div className="data-table">
					<Table
						columns={columns}
						dataSource={displayTableData}
						pagination={false}
						loading={isLoading}
						expandable={{
							indentSize: 25,
							childrenColumnName: 'list',
							expandIcon: ({ expanded, onExpand, record }) =>
								record?.list ? (
									expanded ? (
										<DownOutlined
											onClick={(e) => onExpand(record, e)}
											className="expand-icon"
										/>
									) : (
										<RightOutlined
											onClick={(e) => onExpand(record, e)}
											className="expand-icon"
										/>
									)
								) : (
									''
								),
						}}
					/>
				</div>
				<div className="tool-bar">
					<div className="left">
						<Button
							ghost
							type="primary"
							onClick={() => bidRemittance()}
							disabled={isOnlyRead}
						>
							競標匯出
						</Button>

						<Upload
							accept=".csv"
							showUploadList={false}
							beforeUpload={winningBidImport}
						>
							<Button ghost type="primary" disabled={isOnlyRead}>
								得標匯入
							</Button>
						</Upload>

						<Button
							ghost
							type="primary"
							onClick={() => fieldBiddingExport()}
							disabled={isOnlyRead}
						>
							場域競標匯出
						</Button>

						<Upload
							accept=".csv"
							showUploadList={false}
							beforeUpload={fieldBiddingImport}
						>
							<Button ghost type="primary" disabled={isOnlyRead}>
								場域競標匯入
							</Button>
						</Upload>
					</div>
					<div className="right">
						<Pagination
							total={24}
							current={pageIndex}
							onChange={(page, size) => {
								setPageIndex(page);
								setPageSize(size);
							}}
							showSizeChanger
							defaultPageSize={6}
							pageSize={pageSize}
							pageSizeOptions={['6', '12', '24']}
							locale={{ items_per_page: ' / page' }}
						/>
						<Button
							type="primary"
							onClick={() => {
								Modal.confirm({
									wrapClassName: 'custom-confirm-modal',
									title: (
										<div className="header">
											<Icon.MessageWarn className="icon" />
											<div className="title">資料更新確認</div>
										</div>
									),
									content: (
										<div className="context">
											<div className="text">確定要更新資料嗎？</div>
										</div>
									),
									centered: true,
									icon: null,
									closable: true,
									closeIcon: <Icon.ModalClose />,
									onOk: () => updateData(),
								});
							}}
							disabled={isOnlyRead}
						>
							更新
						</Button>
					</div>
				</div>
			</Card>
		</>
	);
}
export default MarketCompetition;
