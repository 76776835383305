import { useEffect, useState } from 'react';

const DARK_MODE = 'dark-mode';
const getDarkMode = localStorage.getItem(DARK_MODE) === 'true';

const useTheme = () => {
	const [darkMode, setDarkMode] = useState<boolean>(getDarkMode);

	useEffect(() => {
		const initialValue = getDarkMode;
		if (initialValue !== darkMode) {
			localStorage.setItem(DARK_MODE, `${darkMode}`);
			window.location.reload();
		}
	}, [darkMode]);

	return [darkMode, setDarkMode] as const;
};
export default useTheme;
