import { Modal } from 'antd';
import axios from 'axios';

const instance = axios.create({
	baseURL:
		// process.env.NODE_ENV === 'production'
		// 	? window.location.origin
		// 	: process.env.REACT_APP_API_URL,
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_API_URL
			: process.env.REACT_APP_API_URL,
	headers: {
		'Content-Type': 'application/json; charset=utf-8',
		Accept: 'application/json',
	},
});

instance.interceptors.request.use(
	(config) => {
		console.log(`[Api-Req]: ${config.url}`, config);
		if (config.method === 'get') config.data = true;

		const user = JSON.parse(
			localStorage.getItem('energy-dispatch-user') as string
		);
		if (user?.token) {
			config.headers = {
				...config.headers,
				Authorization: `Bearer ${user.token}`,
			};
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

const errorModal = (msg: string) => {
	Modal.error({
		title: '錯誤',
		content: msg,
	});
};

instance.interceptors.response.use(
	(response) => {
		console.log(`[Api-Res]: ${response.config.url}`, response);
		return response;
	},
	(error) => {
		if (error.response) {
			switch (error.response.status) {
				case 404:
					console.log('Not Found 404');
					break;
				case 500:
					console.log('Server Error 500');
					break;
				case 401: {
					console.log('token expired');
					localStorage.removeItem('energy-dispatch-user');
					localStorage.removeItem('energy-dispatch-allOrgTree');
					const isInLogin = error.request.responseURL.indexOf('login_api') > -1;

					!isInLogin &&
						Modal.error({
							title: isInLogin ? '帳號或密碼錯誤' : '401 Unauthorized',
							content: '請重新登入',
							okText: '確定',
							centered: true,
							onOk: () => !isInLogin && location.reload(),
						});
					break;
				}
				default:
					console.log(error);
			}
		}
		console.log(error);
		return Promise.reject(error);
	}
);

export default instance;
