import React, { useEffect, useRef, useState } from 'react';
import NoData from '@components/NoData/NoData';
import type { AppDispatch } from 'src';
import { useDispatch, useSelector } from 'react-redux';
import { useMeasure } from 'react-use';
import {
	getIsFullscreenState,
	setIsFullscreen,
	getCustomThemeToken,
} from '@store/reducers/utilSlice';
import { DatePicker, Card, Modal, Switch, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Icon } from '@models/icon';
import { shareChartOption } from '@models/common';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import api from '@api/api';
import './MarketPriceInfo.less';

const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

highchartsMore(Highcharts);

const capacitySettlementLabels = [
	{ labelName: '即時備轉', parameter: 'sr', zIndex: 2, valueSuffix: ' 元' },
	{ labelName: '補充備轉', parameter: 'sup', zIndex: 3, valueSuffix: ' 元' },
	{ labelName: 'dReg', parameter: 'dreg', zIndex: 1, valueSuffix: ' 元' },
	{ labelName: 'E-dReg', parameter: 'edReg', zIndex: 0, valueSuffix: ' 元' },
];

const marginalElectricityLabels = [
	{ labelName: '電能邊際價格', parameter: 'marginal', valueSuffix: ' 元' },
];

function MarketPriceInfo() {
	const token = useSelector(getCustomThemeToken);
	const dispatch = useDispatch<AppDispatch>();
	const isFullscreen = useSelector(getIsFullscreenState);

	const capacitySettlement = {
		...shareChartOption(token),
		chart: {
			height: 300,
			type: 'area',
			zoomType: 'xy',
			animation: false,
			backgroundColor: token.colorBgContainer,
		},
		colors: ['#4391FF', '#72D126', '#FF7A00', '#dbeb34'],
		series: capacitySettlementLabels.map((item) => ({
			name: item.labelName,
			parameter: item.parameter,
			data: [],
			marker: {
				symbol: 'circle',
				radius: 3.5,
			},
			dataLabels: {
				enabled: false,
			},
			zIndex: item.zIndex,
			tooltip: {
				valueSuffix: item.valueSuffix,
			},
		})),
		yAxis: {
			title: {
				text: '容量結清價格 (元)',
				style: {
					color: token.colorChartNormal,
				},
			},
			labels: {
				style: {
					color: token.colorChartNormal,
				},
			},
			gridLineColor: token.colorChartNormal,
		},
	};
	const marginalElectricity = {
		...shareChartOption(token),
		chart: {
			height: 300,
			type: 'area',
			zoomType: 'xy',
			animation: false,
			backgroundColor: token.colorBgContainer,
		},
		colors: ['#4391FF'],
		series: marginalElectricityLabels.map((item) => ({
			name: item.labelName,
			parameter: item.parameter,
			data: [],
			marker: {
				symbol: 'circle',
				radius: 3.5,
			},
			dataLabels: {
				enabled: false,
			},
			tooltip: {
				valueSuffix: item.valueSuffix,
			},
		})),
		yAxis: {
			title: {
				text: '電能邊際價格 (元)',
				style: {
					color: token.colorChartNormal,
				},
			},
			labels: {
				style: {
					color: token.colorChartNormal,
				},
			},
			gridLineColor: token.colorChartNormal,
		},
	};

	const [dateRange, setDateRange] = useState<RangeValue>([dayjs(), dayjs()]);
	const [showDayDivider, setShowDayDivider] = useState(false);
	const [isNoData, setIsNoData] = useState(false);

	const [expandCapacitySettlementChart, setExpandCapacitySettlementChart] =
		useState(false);
	const [isCapacitySettlementLoading, setIsCapacitySettlementLoading] =
		useState(true);
	const capacitySettlementChartRef = useRef<null | HTMLDivElement>(null);
	const [capacitySettlementChartOptions, setCapacitySettlementChartOptions] =
		useState({ ...capacitySettlement });
	const [
		capacitySettlementPartRef,
		{
			x: csX,
			y: csY,
			width: csWidth,
			height: csHeight,
			top: csTop,
			right: csRight,
			bottom: csBottom,
			left: csLeft,
		},
	] = useMeasure<HTMLDivElement>();

	const [expandMarginalElectricityChart, setExpandMarginalElectricityChart] =
		useState(false);
	const [isMarginalElectricityLoading, setIsMarginalElectricityLoading] =
		useState(true);
	const marginalElectricityChartRef = useRef<null | HTMLDivElement>(null);
	const [marginalElectricityChartOptions, setMarginalElectricityChartOptions] =
		useState({ ...marginalElectricity });
	const [
		marginalElectricityPartRef,
		{
			x: meX,
			y: meY,
			width: meWidth,
			height: meHeight,
			top: meTop,
			right: meRight,
			bottom: meBottom,
			left: meLeft,
		},
	] = useMeasure<HTMLDivElement>();

	const toggleFullscreen = async () => {
		setIsCapacitySettlementLoading(true);
		setIsMarginalElectricityLoading(true);

		await dispatch(setIsFullscreen(!isFullscreen));

		await setTimeout(() => {
			setIsCapacitySettlementLoading(false);
			setIsMarginalElectricityLoading(false);
		}, 500);
	};

	const getChartData = async () => {
		if (!dateRange || !dateRange[0] || !dateRange[1]) {
			return;
		}

		const start = dayjs(dateRange[0]).format('YYYY-MM-DD');
		const end = dayjs(dateRange[1]);
		const diff = end.diff(start, 'day') + 1;
		console.log('diff:', diff);

		const res = await api.get(
			`/webapi/market/settlement/multi?date=${start}&count=${diff}`
		);
		console.log('getChartData resresresres', res);

		if (res.data?.status !== 'ok') {
			return Modal.error({
				title: '找不到資料',
				content: '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		const result: any = [];
		const resList = res.data.list.map((r: any) => r.list);
		resList.forEach((item: any[]) => result.push(...item));
		console.log('resList result:', resList, result);

		setCapacitySettlementChartOptions(() => ({
			...capacitySettlement,
			series: capacitySettlement.series.map((s) => ({
				...s,
				data: result.map((r: any) => [r.dateTime, r[s.parameter] ?? null]),
			})),
		}));

		setMarginalElectricityChartOptions(() => ({
			...marginalElectricity,
			series: marginalElectricity.series.map((s) => ({
				...s,
				data: result.map((r: any) => [r.dateTime, r[s.parameter] ?? null]),
			})),
		}));

		setIsNoData(result.length === 0);
		setTimeout(() => {
			setIsCapacitySettlementLoading(false);
			setIsMarginalElectricityLoading(false);
		}, 500);
	};

	const onOpenChange = (open: boolean) => {
		if (open) {
			setDateRange([null, null]);
		}
	};

	const disabledDate = (current: Dayjs) => {
		if (!dateRange) {
			return false;
		}
		const tooLate = dateRange[0] && current.diff(dateRange[0], 'days') > 6;
		const tooEarly = dateRange[1] && dateRange[1].diff(current, 'days') > 6;

		return !!tooEarly || !!tooLate;
	};

	useEffect(() => {
		console.log('dateRange', dateRange);
		dateRange && dateRange[0] && dateRange[1] && token && getChartData();
	}, [dateRange, token]);

	useEffect(() => {
		setCapacitySettlementChartOptions((prevState) => ({
			...prevState,
			chart: {
				...prevState.chart,
				height: expandCapacitySettlementChart ? 600 : 300,
			},
		}));

		console.log('csHeight', csHeight);
		if (csHeight > 0 && csHeight < 600) {
			capacitySettlementChartRef.current?.scrollIntoView({
				block: 'center',
				behavior: 'smooth',
			});
		}

		if (csHeight !== 0) {
			setTimeout(() => {
				setIsCapacitySettlementLoading(false);
			}, 300);
		}
	}, [expandCapacitySettlementChart]);

	useEffect(() => {
		setMarginalElectricityChartOptions((prevState) => ({
			...prevState,
			chart: {
				...prevState.chart,
				height: expandMarginalElectricityChart ? 600 : 300,
			},
		}));

		console.log('meHeight', meHeight);
		if (meHeight > 0 && meHeight < 600) {
			marginalElectricityChartRef.current?.scrollIntoView({
				block: 'center',
				behavior: 'smooth',
			});
		}

		if (meHeight !== 0) {
			setTimeout(() => {
				setIsMarginalElectricityLoading(false);
			}, 300);
		}
	}, [expandMarginalElectricityChart]);

	useEffect(() => {
		if (showDayDivider && dateRange && dateRange[0] && dateRange[1]) {
			const start = dayjs(dateRange[0]);
			const end = dayjs(dateRange[1]);
			const diff = end.diff(start, 'day');
			const dayArr: number[] = [];

			for (let i = 0; i <= diff; i++) {
				const resp = dayjs(start.add(i, 'day')).startOf('day').valueOf();
				dayArr.push(resp);
			}
			console.log('dayArr:', dayArr);

			const lines = dayArr.map((d) => ({
				color: token.colorText,
				dashStyle: 'dash',
				value: d,
				width: 1,
				zIndex: 4,
				label: {
					text: dayjs(d).format('MM/DD'),
					rotation: 270,
					x: 14,
					y: 40,
					style: { color: token.colorText },
				},
			}));

			setCapacitySettlementChartOptions((prevState) => ({
				...prevState,
				xAxis: {
					...prevState.xAxis,
					plotLines: isNoData ? [] : lines,
				},
			}));
			setMarginalElectricityChartOptions((prevState) => ({
				...prevState,
				xAxis: {
					...prevState.xAxis,
					plotLines: isNoData ? [] : lines,
				},
			}));
		} else {
			console.log('no showDayDivider');
			setCapacitySettlementChartOptions((prevState) => ({
				...prevState,
				xAxis: {
					...prevState.xAxis,
					plotLines: [],
				},
			}));
			setMarginalElectricityChartOptions((prevState) => ({
				...prevState,
				xAxis: {
					...prevState.xAxis,
					plotLines: [],
				},
			}));
		}
	}, [showDayDivider, dateRange]);

	return (
		<>
			<Card
				title="市場資訊"
				bordered={false}
				className={`market-price-info ${isFullscreen ? 'card-fullscreen' : ''}`}
			>
				<div className="tool-bar">
					<div className="date-select">
						<span>日期</span>
						<RangePicker
							style={{ width: 260 }}
							value={dateRange}
							disabledDate={disabledDate}
							onOpenChange={onOpenChange}
							onCalendarChange={(val) => setDateRange(val)}
							suffixIcon={<Icon.Calendar />}
						/>
					</div>
					<div className="fullscreen-btn" onClick={() => toggleFullscreen()}>
						{isFullscreen ? (
							<>
								<Icon.Minimize />
								<span>取消全螢幕</span>
							</>
						) : (
							<>
								<Icon.Fullscreen />
								<span>全螢幕</span>
							</>
						)}
					</div>
				</div>
				<div className="show-day-divider">
					<Switch
						checked={showDayDivider}
						onChange={(arg) => setShowDayDivider(arg)}
					/>
					顯示日分隔線
				</div>

				<div className="capacity-settlement" ref={capacitySettlementPartRef}>
					<div className="chart-bar">
						<div className="title-part">容量結清價格</div>
						<div className="switch-part">
							<div className="switch-label">
								<span className="switch-label-title">顯示標籤</span>
								{capacitySettlementChartOptions.series.map((item, currIdx) => {
									return (
										<div
											key={currIdx}
											className={item.dataLabels.enabled ? 'switch-on' : ''}
										>
											<Switch
												checked={item.dataLabels.enabled}
												onChange={(checked: boolean) => {
													setCapacitySettlementChartOptions((prevState) => ({
														...prevState,
														series: prevState.series.map((s, prevIdx) => ({
															...s,
															dataLabels: {
																enabled:
																	currIdx === prevIdx
																		? checked
																		: s.dataLabels.enabled,
															},
														})),
													}));
												}}
											/>
											<span>{item.name}</span>
										</div>
									);
								})}
							</div>
							<div
								className="switch-expand"
								onClick={() => {
									setIsCapacitySettlementLoading(true);
									setExpandCapacitySettlementChart((prevState) => !prevState);
								}}
							>
								{expandCapacitySettlementChart ? (
									<>
										<Icon.CollapseArrow />
										收回圖表
									</>
								) : (
									<>
										<Icon.ExpandArrow />
										展開圖表
									</>
								)}
							</div>
						</div>
					</div>
					<div
						className={`chart ${
							expandCapacitySettlementChart ? 'expand-chart' : ''
						}`}
						ref={capacitySettlementChartRef}
					>
						{isCapacitySettlementLoading ? (
							<Spin className="spin-center" />
						) : (
							<>
								<HighchartsReact
									highcharts={Highcharts}
									options={capacitySettlementChartOptions}
								/>
								<div className="hint-text">滑鼠拖拉可以放大圖表</div>
								<div className="fixed-no-data">
									{isNoData ? <NoData /> : ''}
								</div>
							</>
						)}
					</div>
				</div>

				<div className="marginal-electricity" ref={marginalElectricityPartRef}>
					<div className="chart-bar">
						<div className="title-part">電能邊際價格</div>
						<div className="switch-part">
							<div className="switch-label">
								<span className="switch-label-title">顯示標籤</span>
								{marginalElectricityChartOptions.series.map((item, currIdx) => {
									return (
										<div
											key={currIdx}
											className={item.dataLabels.enabled ? 'switch-on' : ''}
										>
											<Switch
												checked={item.dataLabels.enabled}
												onChange={(checked: boolean) => {
													setMarginalElectricityChartOptions((prevState) => ({
														...prevState,
														series: prevState.series.map((s, prevIdx) => ({
															...s,
															dataLabels: {
																enabled:
																	currIdx === prevIdx
																		? checked
																		: s.dataLabels.enabled,
															},
														})),
													}));
												}}
											/>
											<span>{item.name}</span>
										</div>
									);
								})}
							</div>
							<div
								className="switch-expand"
								onClick={() => {
									setIsMarginalElectricityLoading(true);
									setExpandMarginalElectricityChart((prevState) => !prevState);
								}}
							>
								{expandMarginalElectricityChart ? (
									<>
										<Icon.CollapseArrow />
										收回圖表
									</>
								) : (
									<>
										<Icon.ExpandArrow />
										展開圖表
									</>
								)}
							</div>
						</div>
					</div>
					<div
						className={`chart ${
							expandMarginalElectricityChart ? 'expand-chart' : ''
						}`}
						ref={marginalElectricityChartRef}
					>
						{isMarginalElectricityLoading ? (
							<Spin className="spin-center" />
						) : (
							<>
								<HighchartsReact
									highcharts={Highcharts}
									options={marginalElectricityChartOptions}
								/>
								<div className="hint-text">滑鼠拖拉可以放大圖表</div>
								<div className="fixed-no-data">
									{isNoData ? <NoData /> : ''}
								</div>
							</>
						)}
					</div>
				</div>
			</Card>
		</>
	);
}
export default MarketPriceInfo;
