import React, { useEffect, useState } from 'react';
import OperatingDeviceModal from '@pages/OperatingDevice/OperatingDeviceModal';
import { Card, Modal, Pagination, Select, Tag } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { ConnectionStatus } from '@models/organization';
import { Icon } from '@models/icon';
import dayjs from 'dayjs';
import api from '@api/api';
import './OperatingDevice.less';

type fieldMonitorData = {
	fieldId: string;
	fieldName: string;
	m0: number;
	m1: number;
	m2: number;
	m3: number;
	m10: number;
	tcStatus: string;
	devStatus: string;
};

const operatingReserveOptions = [
	{
		value: 0,
		label: '即時備轉',
	},
	{
		value: 1,
		label: '調頻備轉',
	},
	{
		value: 2,
		label: '補充備轉',
	},
	// {
	// 	value: 3,
	// 	label: '靜態調頻備轉',
	// },
	// {
	// 	value: 4,
	// 	label: '增強型動態調頻備轉',
	// },
	// {
	// 	value: 5,
	// 	label: '自動調頻備轉',
	// },
];

const OperatingDevice = () => {
	const pageSize = 9;
	const [updateTime, setUpdateTime] = useState(dayjs().format('HH:mm:ss'));
	const [selectedOperatingReserve, setSelectedOperatingReserve] = useState(0);
	const [isOperatingReserveDropdownOpen, setIsOperatingReserveDropdownOpen] =
		useState<boolean>();

	const [allTxgData, setAllTxgData] = useState<
		{ id: string; name: string }[][]
	>([]);
	const [selectedTxg, setSelectedTxg] = useState('');
	const [isTxgDropdownOpen, setIsTxgDropdownOpen] = useState<boolean>();

	const [allFieldData, setAllFieldData] = useState<fieldMonitorData[]>([]);
	const [displayFieldData, setDisplayFieldData] = useState<fieldMonitorData[]>(
		[]
	);
	const [selectedFieldId, setSelectedFieldId] = useState('');
	const [pageIndex, setPageIndex] = useState(1);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

	const fieldColumns: ColumnsType<fieldMonitorData> = [
		{
			title: <>場域名稱</>,
			dataIndex: 'fieldName',
			key: 'fieldName',
			width: '12%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">{record.fieldName}</div>
			),
		},
		{
			title: (
				<>
					原始用電量
					<br />
					(kW)
				</>
			),
			dataIndex: 'm0',
			key: 'm0',
			width: '9%',
			align: 'center',
		},
		{
			title: (
				<>
					實際用電量 <br />
					(kW)
				</>
			),
			dataIndex: 'm1',
			key: 'm1',
			width: '10%',
			align: 'center',
		},
		{
			title: (
				<>
					太陽能發電量 <br />
					(kW)
				</>
			),
			dataIndex: 'm2',
			key: 'm2',
			width: '10.5%',
			align: 'center',
		},
		{
			title: (
				<>
					儲能系統充放電量 <br />
					(kW)
				</>
			),
			dataIndex: 'm3',
			key: 'm3',
			width: '14%',
			align: 'center',
		},
		{
			title: (
				<>
					發電機發電量 <br />
					(kW)
				</>
			),
			dataIndex: 'm10',
			key: 'm10',
			width: '10.5%',
			align: 'center',
		},
		{
			title: '用戶端控制系統狀態',
			dataIndex: 'tcStatus',
			key: 'tcStatus',
			width: '14%',
			align: 'center',
			render: (_, record) => (
				<Tag
					color={
						ConnectionStatus.filter(
							(c: { text: string; value: string; color: string }) =>
								c.value === record.tcStatus
						)[0].color
					}
				>
					{ConnectionStatus.filter((c) => c.value === record.tcStatus)[0].text}
				</Tag>
			),
		},
		{
			title: '設備連線狀態',
			dataIndex: 'devStatus',
			key: 'devStatus',
			width: '11.5%',
			align: 'center',
			render: (_, record) => (
				<Tag
					color={
						ConnectionStatus.filter(
							(c: { text: string; value: string; color: string }) =>
								c.value === record.devStatus
						)[0].color
					}
				>
					{ConnectionStatus.filter((c) => c.value === record.devStatus)[0].text}
				</Tag>
			),
		},
		{
			title: '查看設備',
			key: 'details',
			align: 'center',
			render: (_, record) => (
				<Icon.EyeOpen
					onClick={() => {
						setIsOpenModal(true);
						setSelectedFieldId(record.fieldId);
					}}
				/>
			),
		},
	];

	const getTxgListData = async () => {
		const response = await api.get('/webapi/system/taipower/list');
		if (response.data?.status !== 'ok') {
			return Modal.error({
				title: '找不到資料',
				content: '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		const result: { id: string; name: string }[][] = [
			[...(response.data.srlist ?? [])],
			[...(response.data.dregList ?? [])],
			[...(response.data.suplist ?? [])],
			[...(response.data.sregList ?? [])],
			[...(response.data.edregList ?? [])],
			[...(response.data.afclist ?? [])],
		];
		console.log('this is my result', result);
		setAllTxgData(result);
	};

	const getFieldData = async (selectedTxg: string) => {
		setIsLoading(true);

		if (selectedTxg === '') {
			setIsLoading(false);
			return;
		}

		const response = await api.get(
			`/webapi/monitor/field/list?txgId=${selectedTxg}`
		);
		if (response.data?.status !== 'ok') {
			return Modal.error({
				title: '找不到資料',
				content: '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		const result: fieldMonitorData[] = response.data?.list[0].list;
		console.log('getFieldData', result);

		setAllFieldData(result);
		setIsLoading(false);
	};

	useEffect(() => {
		getTxgListData();
	}, []);

	useEffect(() => {
		console.log('allTxgData:', allTxgData);
		setSelectedTxg('');

		if (allTxgData && allTxgData.length > 0) {
			const arr = allTxgData[selectedOperatingReserve];
			console.log('arr', arr);

			if (arr && arr.length > 0) {
				const firstTxg: string = arr[0].id;
				setSelectedTxg(firstTxg);
				return;
			}
		}
	}, [allTxgData, selectedOperatingReserve]);

	useEffect(() => {
		if (selectedTxg === '') {
			setAllFieldData([]);
			return;
		}

		getFieldData(selectedTxg);

		const fieldInterval = setInterval(() => {
			setUpdateTime(dayjs().format('HH:mm:ss'));
			getFieldData(selectedTxg);
		}, 60 * 1000);

		return () => {
			clearInterval(fieldInterval);
		};
	}, [selectedTxg]);

	useEffect(() => {
		if (pageIndex && allFieldData.length > 0) {
			const startIndex = (pageIndex - 1) * pageSize;
			const endIndex = Math.min(
				startIndex + pageSize - 1,
				allFieldData.length - 1
			);
			setDisplayFieldData(allFieldData.slice(startIndex, endIndex + 1));
		} else {
			setDisplayFieldData([]);
		}
	}, [pageIndex, pageSize, allFieldData]);

	return (
		<Card
			title="網路連線異常偵測"
			bordered={false}
			className="operating-device"
			extra={
				<div className="update-info">
					<div>頁面更新時間: {updateTime}</div>
					<Tag>自動更新</Tag>
				</div>
			}
		>
			<div className="tool-bar">
				<div className="left">
					<Select
						style={{ width: 180 }}
						onDropdownVisibleChange={() => {
							setIsOperatingReserveDropdownOpen((prevState) => !prevState);
						}}
						value={selectedOperatingReserve}
						onChange={(arg) => setSelectedOperatingReserve(arg)}
						suffixIcon={
							isOperatingReserveDropdownOpen ? (
								<Icon.UpArrow />
							) : (
								<Icon.DownArrow />
							)
						}
						options={operatingReserveOptions}
					/>
					{allTxgData.length !== 0 ? (
						<Select
							style={{ width: 180 }}
							onDropdownVisibleChange={() => {
								setIsTxgDropdownOpen((prevState) => !prevState);
							}}
							value={selectedTxg}
							onChange={(arg) => setSelectedTxg(arg)}
							suffixIcon={
								isTxgDropdownOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
							}
							options={allTxgData[selectedOperatingReserve].map(
								(txg: { id: string; name: string }) => {
									return {
										value: txg.id,
										label: txg.name,
									};
								}
							)}
						/>
					) : null}
				</div>
			</div>
			<div className="data-table">
				<Table
					columns={fieldColumns}
					dataSource={displayFieldData}
					pagination={false}
					loading={isLoading}
					rowKey={(record) => record.fieldId}
				/>
			</div>
			{allFieldData.length > 0 ? (
				<div className="pagination-bar">
					<div className="right">
						<Pagination
							total={allFieldData.length}
							current={pageIndex}
							onChange={(page) => {
								setPageIndex(page);
							}}
							pageSize={pageSize}
						/>
					</div>
				</div>
			) : null}
			<OperatingDeviceModal
				isOpenModal={isOpenModal}
				setIsOpenModal={setIsOpenModal}
				selectedFieldId={selectedFieldId}
			/>
		</Card>
	);
};

export default OperatingDevice;
