import React from 'react';
import { useLocation } from 'react-router-dom';
import { Icon } from '@models/icon';

import './NoData.less';

function NoData() {
	const { pathname } = useLocation();

	return (
		<div className="no-data">
			<Icon.NoData />
			<div>{pathname === '/market/abandon' ? '暫無數據資料' : '暫無資料'}</div>
		</div>
	);
}
export default NoData;
