import React, { useEffect, useState } from 'react';
import NoData from '@components/NoData/NoData';
import { useSelector } from 'react-redux';
import { getCustomThemeToken } from '@store/reducers/utilSlice';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Pagination, Spin } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { ImplementData } from '@pages/PerformanceImplement/PerformanceImplement';
import { shareChartOption } from '@models/common';
import dayjs, { Dayjs } from 'dayjs';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';

interface Props {
	allData: ImplementData[];
	isLoading: boolean;
	selectedDate: Dayjs | null;
	selectedTXGName: string;
}

const dregTableColumns: ColumnsType<ImplementData> = [
	{
		title: '用戶/場域名稱',
		dataIndex: 'name',
		key: 'name',
		width: '25%',
		align: 'center',
	},
	{
		title: '通知類型',
		dataIndex: 'noticeType',
		key: 'noticeType',
		width: '25%',
		align: 'center',
	},
	{
		title: '執行時段(一小時)',
		dataIndex: 'servicePeriod',
		key: 'servicePeriod',
		width: '25%',
		align: 'center',
		render: (_, record) => (
			<div>
				{record.servicePeriod
					? dayjs(record.servicePeriod).format('HH:mm')
					: null}
			</div>
		),
	},
	{
		title: '執行率(%)',
		dataIndex: 'spm',
		key: 'spm',
		width: '25%',
		align: 'center',
	},
];

highchartsMore(Highcharts);

const DREGImplement = ({
	allData,
	isLoading,
	selectedDate,
	selectedTXGName,
}: Props) => {
	const token = useSelector(getCustomThemeToken);
	const [displayData, setDisplayData] = useState([] as ImplementData[]);
	const [chartData, setChartData] = useState([] as any);
	const [pageIndex, setPageIndex] = useState(1);
	const [pageSize, setPageSize] = useState(6);

	const options = {
		...shareChartOption(token),
		chart: {
			height: 300,
			type: 'line',
			zoomType: 'xy',
			animation: false,
			backgroundColor: token.colorBgContainer,
		},
		yAxis: {
			title: {
				text: '執行率｜%',
				style: {
					color: token.colorChartNormal,
				},
			},
			labels: {
				style: {
					color: token.colorChartNormal,
				},
			},
			gridLineColor: token.colorChartNormal,
		},
		series: [
			{
				name: `${selectedTXGName} 執行率`,
				data: chartData,
				color: '#EE4D7E',
				pointInterval: 3600 * 1000,
				pointStart: dayjs(
					dayjs(selectedDate).format('YYYY-MM-DD 00:00:00')
				).valueOf(),
				marker: {
					symbol: 'circle',
					radius: 3.5,
				},
				dataLabels: {
					enabled: false,
				},
				tooltip: {
					valueSuffix: '%',
				},
			},
		],
	};

	useEffect(() => {
		if (allData.length === 0) {
			setDisplayData([]);
		}

		if (pageIndex && pageSize && allData.length > 0) {
			const startIndex = (pageIndex - 1) * pageSize;
			const endIndex = Math.min(startIndex + pageSize - 1, allData.length - 1);

			setDisplayData(allData.slice(startIndex, endIndex + 1));
		}
	}, [pageIndex, pageSize, allData]);

	useEffect(() => {
		setChartData([]);

		if (allData.length >= 0) {
			const result = allData.map((record) => {
				return record.spm;
			});
			setChartData(result);
		}
	}, [allData]);

	return (
		<>
			<div className="chart">
				{isLoading ? (
					<Spin className="spin-center" />
				) : (
					<>
						<HighchartsReact highcharts={Highcharts} options={options} />
						<div className="hint-text">滑鼠拖拉可以放大圖表</div>
						<div className="fixed-no-data">
							{allData.length === 0 ? <NoData /> : ''}
						</div>
					</>
				)}
			</div>
			<div className="data-table">
				<Table
					columns={dregTableColumns}
					dataSource={displayData}
					pagination={false}
					loading={isLoading}
					expandable={{
						indentSize: 31,
						childrenColumnName: 'list',
						expandIcon: ({ expanded, onExpand, record }) =>
							record?.list ? (
								expanded ? (
									<DownOutlined
										onClick={(e) => onExpand(record, e)}
										className="expand-icon"
									/>
								) : (
									<RightOutlined
										onClick={(e) => onExpand(record, e)}
										className="expand-icon"
									/>
								)
							) : (
								''
							),
					}}
					rowKey={(record) => record.idx.toString()}
				/>
			</div>
			{allData.length > 0 ? (
				<div className="pagination-bar">
					<div className="right">
						<Pagination
							total={allData.length}
							current={pageIndex}
							onChange={(page, size) => {
								setPageIndex(page);
								setPageSize(size);
							}}
							defaultPageSize={6}
							pageSizeOptions={['6', '12', '24']}
							showSizeChanger
							locale={{ items_per_page: ' / page' }}
						/>
					</div>
				</div>
			) : null}
		</>
	);
};

export default DREGImplement;
