export interface CompanyListType {
	name: string;
	companyId: string;
}
export interface TxgData {
	txgId: string;
	name: string;
	isSuspend?: boolean;
	suspendStart?: string;
	suspendEnd?: string;
	suspendReason?: string;
}

export const getCompanyName = (
	companyList: CompanyListType[],
	companyId: string
) => {
	const result =
		companyList.filter((company) => company.companyId === companyId)[0]?.name ||
		'';

	console.log('getCompanyName', result);
	return result;
};

export const ConnectionStatus = [
	{ text: '新建', value: 'Init', color: 'default' },
	{ text: '正常', value: 'Connected', color: 'success' },
	{ text: '斷線', value: 'Disconnected', color: 'error' },
	{ text: '故障', value: 'Malfunction', color: 'error' },
	{ text: '部分異常', value: 'PartialError', color: 'warning' },
];

export const LoadTypeWithDeviceProperty: Record<string, string[]> = {
	M1: ['ct', 'pt'],
	M2: ['pvCapacity', 'unitCost', 'maintenanceCost'],
	M21: ['pvCapacity', 'unitCost', 'maintenanceCost'],
	M3: [
		'ratedVoltage',
		'capacity',
		'chargeKw',
		'dischargeKw',
		'chargeEfficiency',
		'dischargeEfficiency',
		'dod',
		'socMax',
		'socMin',
		'selfDischargeKw',
		'lifecycle',
		'constructionCost',
		'capacityCost',
		'kwcost',
		'maintenanceCost',
	],
	M5: ['ct', 'pt'],
	M6: [
		'fullCapacity',
		'unloadCapacity',
		'unloadTime',
		'returnTime',
		'cost',
		'maintenanceCost',
	],
	M8: ['power', 'unitCost', 'maintenanceCost'],
	M9: ['power', 'unitCost', 'maintenanceCost'],
	M10: ['power', 'unitCost', 'maintenanceCost'],
};

export const AllDeviceProperties = [
	{ label: 'CT ratio', name: 'ct' },
	{ label: 'PT ratio', name: 'pt' },
	{ label: 'PV發電容量 (kWp)', name: 'pvCapacity' },
	{ label: '發電功率 (kW)', name: 'power' },
	{ label: '單位採購成本 ($/kWp)', name: 'unitCost' },
	{ label: '滿載容量 (kW)', name: 'fullCapacity' },
	{ label: '可卸容量 (kW)', name: 'unloadCapacity' },
	{ label: '卸載時間 (sec)', name: 'unloadTime' },
	{ label: '覆歸時間 (sec)', name: 'returnTime' },
	{ label: '採購成本 ($)', name: 'cost' },
	{ label: '額定電壓 (V)', name: 'ratedVoltage' },
	{ label: '蓄電容量 (kWh)', name: 'capacity' },
	{ label: '最大充電功率 (kW)', name: 'chargeKw' },
	{ label: '最大放電功率 (kW)', name: 'dischargeKw' },
	{ label: '充電效率 (%)', name: 'chargeEfficiency' },
	{ label: '放電效率 (%)', name: 'dischargeEfficiency' },
	{ label: '放電深度 (%)', name: 'dod' },
	{ label: 'SOC充電上限 (%)', name: 'socMax' },
	{ label: 'SOC底線 (%)', name: 'socMin' },
	{ label: '自放電功率 (kW)', name: 'selfDischargeKw' },
	{ label: '循環壽命次數', name: 'lifecycle' },
	{ label: '施工費用 ($)', name: 'constructionCost' },
	{ label: '單位容量成本 ($/kWh)', name: 'capacityCost' },
	{ label: '單位功率成本 ($/kW)', name: 'kwcost' },
	{ label: '維護費用 (%)', name: 'maintenanceCost' },
];

export const loadTypeOptions = [
	{ value: 'M1', label: '實際負載' },
	{ value: 'M2', label: 'PV' },
	{ value: 'M21', label: '蠆售PV' },
	{ value: 'M3', label: '電池' },
	{ value: 'M5', label: '其他負載' },
	{ value: 'M6', label: '可控負載' },
	{ value: 'M8', label: '燃料電池' },
	// { value: 'M9', label: '發電機' },
	{ value: 'M10', label: '發電機' },
];
