import React, { useEffect, useState } from 'react';
import OperatingOverviewCard from '@pages/OperatingOverview/OperatingOverviewCard';
import NoData from '@components/NoData/NoData';
import { Card, Modal, Select, Spin } from 'antd';
import { OrgTree, Role } from '@models/user';
import { Icon } from '@models/icon';
import { isArray, isObject } from 'lodash';
import dayjs from 'dayjs';
import api from '@api/api';
import './OperatingOverview.less';

export interface TXGBoardData {
	state: string;
	org: {
		txgId: string;
		txgName: string;
		registryCapacity: number;
		serviceType: number;
		resourceType: number;
		resourceCount: number;
	};
	current: {
		awardedCapacity: string;
		clippedCapacity: number | string;
		noticeTime: number | string;
		clippedPeriod: { begin: string; end: string };
	};
	today: {
		awardedHours: number;
		awardedPeriod: { begin: string; end: string };
		awardedRange: { begin: string; end: string };
		capacityPriceRange: { begin: string; end: string };
		energyPriceRange: { begin: string; end: string };
		abandonPeriod: { begin: string; end: string };
	};
}

export enum TXGBoardStates {
	ALL = 0,
	STAND_BY,
	NOT_ENOUGH,
	DISPATCH,
	ABANDON,
	NOT_AWARDED,
	NOT_BIDDING,
}

export const TXGBoardStatesFilterOptions = [
	{
		label: '顯示所有',
		value: TXGBoardStates.ALL,
		color: '',
		bgColor: '',
	},
	{
		label: '待命',
		value: TXGBoardStates.STAND_BY,
		color: '#3EC011',
		bgColor: 'rgba(62, 192, 17, 0.1)',
	},
	{
		label: '待命 (準備量不足)',
		value: TXGBoardStates.NOT_ENOUGH,
		color: '#72D126',
		bgColor: 'rgba(62, 192, 17, 0.1)',
	},
	{
		label: '調度',
		value: TXGBoardStates.DISPATCH,
		color: '#F33535',
		bgColor: 'rgba(227, 6, 19, 0.1)',
	},
	{
		label: '中止待命',
		value: TXGBoardStates.ABANDON,
		color: '#4391FF',
		bgColor: 'rgba(0, 178, 255, 0.1)',
	},
	{
		label: '未得標',
		value: TXGBoardStates.NOT_AWARDED,
		color: '#98A2B3',
		bgColor: 'rgba(152, 162, 179, 0.1)',
	},
	{
		label: '未投標',
		value: TXGBoardStates.NOT_BIDDING,
		color: '#667085',
		bgColor: 'rgba(152, 162, 179, 0.1)',
	},
];

const replaceNullValues = (data: any) => {
	if (isArray(data)) {
		data.forEach((item) => {
			Object.keys(item).forEach((key) => {
				if (isObject(item[key])) replaceNullValues(item[key]);

				item[key] = item[key] ?? '----';
			});
		});
	} else {
		Object.keys(data).forEach((key) => {
			if (isObject(data[key])) replaceNullValues(data[key]);

			data[key] = data[key] ?? '----';
		});
	}
	return data;
};

const adjustTxgOrder = (data: TXGBoardData[]) => {
	const adjustedData = data.sort((a, b) => {
		return (
			Number(TXGBoardStates[a.state as any]) -
			Number(TXGBoardStates[b.state as any])
		);
	});
	return adjustedData;
};

const OperatingOverview = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [systemTime, setSystemTime] = useState(
		dayjs().format('YYYY-MM-DD HH:mm:ss')
	);
	const [qseDropdownIsOpen, setQSEDropdownIsOpen] = useState(false);
	const [qseDropdownList, setQSEDropdownList] = useState([] as OrgTree[]);
	const [selectedQSE, setSelectedQSE] = useState('');

	const [filterDropdownIsOpen, setFilterDropdownIsOpen] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState(TXGBoardStates.ALL);

	const [allQseData, setAllQseData] = useState<TXGBoardData[]>([]);
	const [selectedQseData, setSelectedQseData] = useState<TXGBoardData[]>([]);

	const allOrgTreeList: OrgTree[] = JSON.parse(
		localStorage.getItem('energy-dispatch-allOrgTree') as string
	);
	const userJson = JSON.parse(
		localStorage.getItem('energy-dispatch-user') as string
	);
	const userRole = userJson.authority[0] ?? 1;

	const getQSEData = async () => {
		setIsLoading(true);

		const response = await api.get(
			`/webapi/mainBoard${userRole === Role.SYSAdmin ? '/' + selectedQSE : ''}`
		);

		if (response.data?.status !== 'ok') {
			return Modal.error({
				title: '找不到資料',
				content: '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		const data = response.data?.list;
		const replacedData = replaceNullValues(data);
		const result = adjustTxgOrder(replacedData);
		setAllQseData(result);
		setIsLoading(false);
	};

	useEffect(() => {
		setQSEDropdownList(allOrgTreeList);
		setSelectedQSE(allOrgTreeList[0].unitId);
		setSelectedFilter(TXGBoardStatesFilterOptions[0].value);
	}, []);

	useEffect(() => {
		const updateTimer = setInterval(() => {
			setSystemTime(dayjs().format('YYYY-MM-DD HH:mm:ss'));
		}, 1000);

		return () => {
			clearInterval(updateTimer);
		};
	}, [systemTime]);

	useEffect(() => {
		getQSEData();
	}, [selectedQSE]);

	useEffect(() => {
		setSelectedQseData(
			allQseData.filter((txg: TXGBoardData) => {
				return selectedFilter === TXGBoardStates.ALL
					? txg
					: txg.state === TXGBoardStates[selectedFilter];
			})
		);
		console.log('selectedQseData', selectedQseData);
	}, [selectedFilter, allQseData]);

	return (
		<Card
			title="服務內容總覽"
			bordered={false}
			className="operating-overview"
			extra={<div className="system-time">系統時間: {systemTime}</div>}
		>
			<div className="tool-bar">
				<div className="left">
					<Select
						popupClassName={'qse-group-dropdown'}
						style={{ width: 125 }}
						onDropdownVisibleChange={() => {
							setQSEDropdownIsOpen((prevState: boolean) => !prevState);
						}}
						value={selectedQSE}
						onChange={(arg) => setSelectedQSE(arg)}
						suffixIcon={
							qseDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
						}
						options={qseDropdownList.map((qse: OrgTree) => ({
							value: qse.unitId,
							label: qse.unitName,
							disabled: qse.subUnits.length === 0,
						}))}
					/>
				</div>
				<div className="right">
					<Select
						style={{ width: 150 }}
						onDropdownVisibleChange={() => {
							setFilterDropdownIsOpen((prevState: boolean) => !prevState);
						}}
						value={selectedFilter}
						onChange={(arg) => setSelectedFilter(arg)}
						suffixIcon={
							filterDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
						}
						options={TXGBoardStatesFilterOptions}
					/>
				</div>
			</div>
			<div className="card-section">
				{isLoading ? (
					<Spin className="spin-center" />
				) : selectedQseData.length === 0 ? (
					<NoData />
				) : (
					selectedQseData.map((txg) => {
						return <OperatingOverviewCard txgData={txg} key={txg.org.txgId} />;
					})
				)}
			</div>
		</Card>
	);
};

export default OperatingOverview;
