import React, { useState, useEffect } from 'react';
import MarketAbandonCard from '@pages/MarketAbandon/MarketAbandonCard';
import NoData from '@components/NoData/NoData';
import { Card, Col, Modal, Row, Select, Spin } from 'antd';
import { Icon } from '@models/icon';
import { OrgTree } from '@models/user';
import { TxgData } from '@models/organization';
import api from '@api/api';
import dayjs from 'dayjs';
import './MarketAbandon.less';

const abandonFilterList = [
	{
		value: 'All',
		label: '顯示所有',
	},
	{
		value: 'Aborted',
		label: '已中止待命',
	},
	{
		value: 'NotSuspended',
		label: '未中止待命',
	},
];

const getAbandonData = async (fieldData: TxgData, date: string) => {
	const response = await api.get(
		`/webapi/history/ed/sr/list?id=${fieldData.txgId}&date=${date}`
	);

	if (response.data.status !== 'ok') {
		return 'error';
	}

	const data = response.data.list;
	const filteredData: any[] = [];
	data.forEach((datum: any) => {
		if (Object.prototype.hasOwnProperty.call(datum, 'abandon')) {
			filteredData.push(datum);
		}
	});

	if (filteredData.length === 0) {
		return 'error';
	}

	return filteredData;
};

const getStartAndEnd = (data: any): any => {
	if (data === 'error') return;

	const result = data
		.map((ele: any) => ele.time)
		.reduce((accumulator: number, currentValue: number) => {
			return [
				Math.min(accumulator, currentValue),
				Math.max(accumulator, currentValue),
			];
		});
	return result;
};

const MarketAbandon = () => {
	const [qseDropdownIsOpen, setQSEDropdownIsOpen] = useState(false);
	const [qseDropdownList, setQSEDropdownList] = useState([] as OrgTree[]);
	const [filterDropdownIsOpen, setFilterDropdownIsOpen] = useState(false);
	const [selectedQSE, setSelectedQSE] = useState('');
	const [selectedFilter, setSelectedFilter] = useState('All');
	const [txgData, setTXGData] = useState([] as any[]);
	const [isLoading, setIsLoading] = useState(true);
	const [alwaysFetch, setAlwaysFetch] = useState(false);

	const allOrgTreeList: OrgTree[] = JSON.parse(
		localStorage.getItem('energy-dispatch-allOrgTree') as string
	);

	const getTXGData = async (unitId: string) => {
		setIsLoading(true);
		setSelectedQSE(unitId);
		const response = await api.get(`/webapi/organization/txg-list/${unitId}`);

		if (response.data?.status !== 'ok') {
			setIsLoading(false);
			return Modal.error({
				title: '找不到資料',
				content: '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		const data = response.data.list;
		abandonDataHandler(data);
	};

	const abandonDataHandler = async (data: TxgData[]) => {
		const today = dayjs().format('YYYY-MM-DD');
		const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');
		const afterTomorrow = dayjs().add(2, 'day').format('YYYY-MM-DD');
		const dateArr = [today, tomorrow, afterTomorrow];

		const dataCopy = await Promise.all(
			data.map(async (field: TxgData) => {
				let fieldCopy = { ...field };

				for (const date of dateArr) {
					const dateRes = await getAbandonData(field, date);
					// dateRes =
					// 	date === afterTomorrow && field.txgId === 'TXG-0001-01'
					// 		? mockApi
					// 		: dateRes;
					console.log('date dateRes:', date, dateRes);
					if (dateRes !== 'error' && dateRes.length > 0) {
						const now = dayjs().valueOf();
						const result = getStartAndEnd(dateRes);
						console.log('result now:', result, now);
						if (dayjs(result[1]).isAfter(dayjs(now))) {
							console.log('result[1].valueOf():', result[1].valueOf());
							fieldCopy = {
								...fieldCopy,
								suspendStart: dayjs(result[0]).format('YYYY-MM-DD HH:mm'),
								suspendEnd: dayjs(result[1]).format('YYYY-MM-DD HH:mm'),
								isSuspend: true,
							};

							return fieldCopy;
						}
					}
				}

				return fieldCopy;
			})
		);

		console.log('dataCopy', dataCopy);
		setTXGData(dataCopy);
		setIsLoading(false);
	};

	useEffect(() => {
		if (allOrgTreeList.length > 0) {
			console.log('fetching....');
			setQSEDropdownList(allOrgTreeList);
			setSelectedQSE(allOrgTreeList[0].unitId);
			getTXGData(allOrgTreeList[0].unitId);
		}
	}, [alwaysFetch]);

	return (
		<Card title="中止待命管理" bordered={false} className="market-abandon">
			<div className="tool-bar">
				<div className="left">
					<Select
						style={{ width: 210 }}
						onDropdownVisibleChange={() => {
							setQSEDropdownIsOpen((prevState: boolean) => !prevState);
						}}
						value={selectedQSE}
						onChange={(arg) => getTXGData(arg)}
						suffixIcon={
							qseDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
						}
						options={qseDropdownList.map((qse: OrgTree) => ({
							value: qse.unitId,
							label: qse.unitName,
							disabled: qse.subUnits.length === 0,
						}))}
					/>
				</div>
				<div className="right">
					<Select
						style={{ width: 117 }}
						onDropdownVisibleChange={() => {
							setFilterDropdownIsOpen((prevState: boolean) => !prevState);
						}}
						value={selectedFilter}
						onChange={(arg) => setSelectedFilter(arg)}
						suffixIcon={
							filterDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
						}
						options={abandonFilterList}
					/>
				</div>
			</div>
			{isLoading ? (
				<div className="loading">
					<Spin className="spin-center" />
				</div>
			) : txgData.filter((field) =>
					selectedFilter === 'All'
						? true
						: selectedFilter === 'Aborted'
						? field.isSuspend === true
						: field.isSuspend === undefined
			  ).length === 0 ? (
				<NoData />
			) : (
				<>
					<Row gutter={[20, 28]} className="data-card">
						{txgData
							.filter((field) =>
								selectedFilter === 'All'
									? true
									: selectedFilter === 'Aborted'
									? field.isSuspend === true
									: field.isSuspend === undefined
							)
							.map((field: TxgData, idx) => {
								return (
									<Col sm={24} md={12} lg={6} key={field.txgId}>
										<MarketAbandonCard
											fieldData={field}
											callFetch={setAlwaysFetch}
										/>
									</Col>
								);
							})}
					</Row>
				</>
			)}
		</Card>
	);
};

export default MarketAbandon;
