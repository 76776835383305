import { combineReducers } from 'redux';
import userSlice from './userSlice';
import utilSlice from '@store/reducers/utilSlice';

const rootReducer = combineReducers({
	userSlice,
	utilSlice,
});

export type IRootState = ReturnType<typeof rootReducer>;
export default rootReducer;
