import React, { useEffect, useState } from 'react';
import { Icon } from '@models/icon';
import { Button, InputNumber } from 'antd';
import './CustomNumberInput.less';

interface CustomNumberInputPropsType {
	step: number;
	min: number;
	max: number;
	precision: number;
	value?: number;
	onChange?: (value: number) => void;
}

const CustomNumberInput = ({
	step,
	min,
	max,
	precision,
	value = 0,
	onChange,
}: CustomNumberInputPropsType) => {
	const [isMinus, setIsMinus] = useState(true);
	const [isPlus, setIsPlus] = useState(true);

	const triggerChange = (changedValue: number) => {
		console.log('triggerChange:', changedValue);
		onChange?.(changedValue);
	};

	const minusHandler = () => {
		if (value - step >= min) {
			onNumberChange(value - step);
		}
	};

	const plusHandler = () => {
		if (value + step <= max) {
			onNumberChange(value + step);
		}
	};

	const onNumberChange = (e: number) => {
		console.log('onNumberChange:', e);
		triggerChange(e);
	};

	const minusButton = (
		<Button
			disabled={!isMinus}
			onClick={minusHandler}
			type="text"
			className="btn"
		>
			<Icon.InputMinus />
		</Button>
	);

	const plusButton = (
		<Button
			disabled={!isPlus}
			onClick={plusHandler}
			type="text"
			className="btn"
		>
			<Icon.InputPlus />
		</Button>
	);

	useEffect(() => {
		console.log('useEffect', value);
		const str = `${value}`;
		const precisionNum =
			str.indexOf('.') == -1
				? str
				: Number(str.substring(0, str.indexOf('.') + 1 + precision));

		if (precisionNum <= min) {
			setIsMinus(false);
		} else {
			setIsMinus(true);
		}
		if (precisionNum >= max) {
			setIsPlus(false);
		} else {
			setIsPlus(true);
		}
	}, [value]);

	useEffect(() => {
		console.log('useEffect init', value);
	}, []);

	return (
		<InputNumber
			addonBefore={minusButton}
			addonAfter={plusButton}
			className="custom-number-input"
			value={value}
			controls={false}
			min={min}
			max={max}
			precision={precision}
			onChange={(e) => onNumberChange(Number(e))}
		/>
	);
};

export default CustomNumberInput;
