import React, { useEffect, useState } from 'react';
import CardTableSwitch from '@components/CardTableSwitch/CardTableSwitch';
import {
	Select,
	Button,
	Card,
	Row,
	Col,
	Modal,
	Space,
	Pagination,
	Input,
	Form,
} from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { Icon } from '@models/icon';
import dayjs from 'dayjs';
import api from '@api/api';
import './OrganizationQSE.less';

interface TableData {
	key: React.ReactNode;
	qseId: string;
	name: string;
	qseCode: string;
	companyId: string;

	vpnLanIp: string;
	vpnWanIp: string;
	dnpUrl: string;
	createTimestamp: number;
	updateTimestamp: number;
}

function OrganizationQSE() {
	const [isLoading, setIsLoading] = useState(false);
	const [isCardType, setIsCardType] = useState(true);
	const [displayData, setDisplayData] = useState([] as TableData[]);
	const [allData, setAllData] = useState([] as TableData[]);
	const [pageIndex, setPageIndex] = useState(1);
	const [pageSize, setPageSize] = useState(8);
	const [companyDropdownIsOpen, setCompanyDropdownIsOpen] = useState(false);
	const [companyDropdownList, setCompanyDropdownList] = useState(
		[] as { companyId: string; name: string }[]
	);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);

	const [form] = Form.useForm();

	const tableColumns: ColumnsType<TableData> = [
		{
			title: '交易商ID',
			dataIndex: 'qseId',
			key: 'qseId',
			width: '22%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">{record.qseId}</div>
			),
		},
		{
			title: '交易商簡稱',
			dataIndex: 'name',
			key: 'name',
			width: '22%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">{record.name}</div>
			),
		},
		{
			title: '交易商代碼',
			dataIndex: 'qseCode',
			key: 'qseCode',
			width: '22%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">{record.qseCode}</div>
			),
		},
		{
			title: '所屬公司ID',
			dataIndex: 'companyId',
			key: 'companyId',
			width: '22%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">{record.companyId}</div>
			),
		},
		{
			title: '操作',
			key: 'action',
			align: 'center',
			render: (_, record) => (
				<>
					<Icon.Edit
						onClick={() => {
							setIsEdit(true);
							setIsOpenModal(true);
							form.setFieldsValue({ ...record });
						}}
					/>
				</>
			),
		},
	];

	const inputColumns = [
		{
			title: '交易商ID',
			key: 'qseId',
		},
		{
			title: '交易商簡稱',
			key: 'name',
		},
		{
			title: '公司簡稱',
			key: 'companyId',
		},
		{
			title: '交易商代碼',
			key: 'qseCode',
		},
		{
			title: (
				<>
					內網位址
					<br />
					VPN LAN IP
				</>
			),
			key: 'vpnLanIp',
			text: '內網位址 VPN LAN IP',
		},
		{
			title: (
				<>
					外網位址
					<br />
					VPN WAN IP
				</>
			),
			key: 'vpnWanIp',
			text: '外網位址 VPN WAN IP',
		},
		{
			title: 'DNP 位址',
			key: 'dnpUrl',
		},
	];

	const noneValue = {
		qseId: '',
		name: '',
		qseCode: '',
		companyId: '',
		vpnLanIp: '',
		vpnWanIp: '',
		dnpUrl: '',
		createTimestamp: 0,
		updateTimestamp: 0,
	};

	const validateMessages = {
		required: '請輸入 ${label} ',
	};

	const getAllData = async () => {
		setIsLoading(true);
		const response: any = await api.get('/webapi/organization/qse/list');
		if (response.data?.status !== 'ok') {
			return Modal.error({
				title: '找不到資料',
				content: '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		setDisplayData([]);
		setAllData(response.data?.list);
		setIsLoading(false);
	};

	const getCompanyData = async () => {
		const response: any = await api.get('/webapi/system/company/list');

		if (response.data?.status !== 'ok') {
			return;
		}

		setCompanyDropdownList(response.data?.list);
	};

	const displayTypeChange = (arg: string) => {
		setIsCardType(arg === 'CARD');
	};

	const submitHandler = async (requestData: TableData) => {
		console.log('submitHandler', requestData);

		let response;
		if (isEdit) {
			response = await api.put(`/webapi/organization/qse`, requestData);
		} else {
			response = await api.post(`/webapi/organization/qse`, requestData);
		}

		if (response.data?.status !== 'ok') {
			return Modal.error({
				title: `${isEdit ? '更新' : '新增'}失敗`,
				content:
					response.data?.err?.msg || '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		Modal.success({
			title: `${isEdit ? '更新' : '新增'}成功`,
			okText: '確定',
			centered: true,
			closable: true,
			closeIcon: <Icon.ModalClose />,
		});

		getAllData();
		setIsOpenModal(false);
	};

	useEffect(() => {
		getCompanyData();
		getAllData();
	}, []);

	useEffect(() => {
		setPageSize(isCardType ? 8 : 10);
		setPageIndex(1);
	}, [isCardType]);

	useEffect(() => {
		if (pageIndex && pageSize && allData.length > 0) {
			const startIndex = (pageIndex - 1) * pageSize;
			const endIndex = Math.min(startIndex + pageSize - 1, allData.length - 1);

			setDisplayData(allData.slice(startIndex, endIndex + 1));
		}
	}, [pageIndex, pageSize, allData]);

	return (
		<>
			<Card
				title="合格交易商 (QSE) 管理"
				bordered={false}
				extra={
					<Button
						type="primary"
						onClick={() => {
							setIsEdit(false);
							setIsOpenModal(true);
							form.setFieldsValue({ ...noneValue });
						}}
					>
						新增
					</Button>
				}
				className="organization-qse"
			>
				<CardTableSwitch displayTypeChange={displayTypeChange} />
				{isCardType ? (
					<Row gutter={[20, 28]} className="data-card">
						{displayData.map((d, idx) => {
							return (
								<Col sm={24} md={12} lg={6} key={idx}>
									<Card
										key={idx}
										actions={[
											<div
												className="setting"
												key={d.qseId}
												onClick={() => {
													setIsEdit(true);
													setIsOpenModal(true);
													form.setFieldsValue({ ...d });
												}}
											>
												編輯
											</div>,
										]}
									>
										<div className="title">{d.name}</div>
										<Row className="content">
											<Col span={8}>
												<div className="text">交易商ID</div>
											</Col>
											<Col span={16}>
												<div className="result ellipsis">{d.qseId}</div>
											</Col>
										</Row>
										<Row className="content">
											<Col span={8}>
												<div className="text">交易商代碼</div>
											</Col>
											<Col span={16}>
												<div className="result ellipsis">{d.qseCode}</div>
											</Col>
										</Row>
										<Row className="content">
											<Col span={8}>
												<div className="text">所屬公司ID</div>
											</Col>
											<Col span={16}>
												<div className="result ellipsis">{d.companyId}</div>
											</Col>
										</Row>
									</Card>
								</Col>
							);
						})}
					</Row>
				) : (
					<div className="data-table">
						<Table
							columns={tableColumns}
							dataSource={displayData}
							pagination={false}
							loading={isLoading}
							rowKey={(record) => record.qseId}
						/>
					</div>
				)}
				{allData.length > 0 ? (
					<div className="pagination-bar">
						<div className="right">
							<Pagination
								total={allData.length}
								current={pageIndex}
								onChange={(page, size) => {
									setPageIndex(page);
									setPageSize(size);
								}}
								showSizeChanger
								showQuickJumper
								defaultPageSize={isCardType ? 8 : 10}
								pageSize={pageSize}
								pageSizeOptions={
									isCardType ? ['8', '16', '24'] : ['10', '20', '30']
								}
								locale={{
									items_per_page: ' / page',
									jump_to: 'Go to ',
									page: '',
								}}
								showTotal={(total) => `Total ${total} items`}
							/>
						</div>
					</div>
				) : (
					''
				)}
			</Card>
			<Modal
				title={`${isEdit ? '編輯' : '新增'}合格交易商 (QSE)`}
				open={isOpenModal}
				centered
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				width={420}
				className="form-modal"
				maskClosable={false}
				destroyOnClose={true}
				closeIcon={<Icon.ModalClose />}
				onCancel={() => {
					setIsOpenModal(false);
					form.setFieldsValue({ ...noneValue });
				}}
			>
				<Space direction="vertical" size="middle" style={{ display: 'flex' }}>
					<Form
						labelCol={{ span: 7 }}
						wrapperCol={{ span: 17 }}
						form={form}
						labelAlign="left"
						colon={false}
						onFinish={submitHandler}
						validateMessages={validateMessages}
					>
						{inputColumns.map((i, idx) => {
							return (
								<div key={idx}>
									{i.key === 'companyId' ? (
										<Form.Item label={i.title} required>
											<Row gutter={[8, 0]}>
												<Col span={12}>
													<Form.Item
														name="companyId"
														noStyle
														rules={[
															{ required: true, message: '請輸入 公司簡稱' },
														]}
													>
														<Select
															style={{ width: '100%' }}
															placeholder="請選擇"
															onDropdownVisibleChange={() => {
																setCompanyDropdownIsOpen(
																	(prevState: boolean) => !prevState
																);
															}}
															suffixIcon={
																companyDropdownIsOpen ? (
																	<Icon.UpArrow />
																) : (
																	<Icon.DownArrow />
																)
															}
															options={companyDropdownList.map((item) => ({
																value: item.companyId,
																label: item.name,
															}))}
														/>
													</Form.Item>
												</Col>
												<Col span={12}>
													<Form.Item name="companyId" noStyle>
														<Input disabled={true} placeholder="所屬公司ID" />
													</Form.Item>
												</Col>
											</Row>
										</Form.Item>
									) : (
										<Form.Item
											name={i.key}
											label={i.title}
											rules={
												i.key === 'qseCode'
													? [
															{ required: true },
															{ pattern: /^\d+$/, message: '僅輸入數字' },
													  ]
													: [
															{
																required: true,
																message: `請輸入 ${
																	i.key === 'vpnLanIp' || i.key === 'vpnWanIp'
																		? i.text
																		: i.title
																}`,
															},
													  ]
											}
											className={
												i.key === 'vpnLanIp' || i.key === 'vpnWanIp'
													? 'label-multi-line'
													: ''
											}
										>
											<Input
												disabled={isEdit && i.key === 'qseId' ? true : false}
											/>
										</Form.Item>
									)}
								</div>
							);
						})}
						{isEdit ? (
							<div className="time-area">
								<div className="time-info">
									資料建立時間：
									{dayjs(form.getFieldValue('createTimestamp')).format(
										'YYYY-MM-DD HH:mm:ss'
									)}
								</div>
								<div className="time-info">
									資料更新時間：
									{dayjs(form.getFieldValue('updateTimestamp')).format(
										'YYYY-MM-DD HH:mm:ss'
									)}
								</div>
							</div>
						) : null}

						<Button type="primary" htmlType="submit" className="submit-btn">
							{isEdit ? '更新' : '新增'}
						</Button>
					</Form>
				</Space>
			</Modal>
		</>
	);
}
export default OrganizationQSE;
