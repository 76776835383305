import React, { useEffect, useState } from 'react';
import CardTableSwitch from '@components/CardTableSwitch/CardTableSwitch';
import NumberInput from '@components/CustomNumberInput/CustomNumberInput';
import {
	AllDeviceProperties,
	ConnectionStatus,
	loadTypeOptions,
	LoadTypeWithDeviceProperty,
} from '@models/organization';
import {
	Button,
	Card,
	Col,
	Form,
	Input,
	Modal,
	Pagination,
	Radio,
	Row,
	Select,
	Space,
	Switch,
	Tag,
} from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { Icon } from '@models/icon';
import dayjs from 'dayjs';
import api from '@api/api';
import './OrganizationDEV.less';

interface DevDataType {
	id: string;
	name: string;
	resId: string;
	deviceType: string;
	loadType: string;
	enableStatus: string;
	mainLoad: boolean;
	ct: number;
	pt: number;
	pvCapacity: number;
	unitCost: number;
	maintenanceCost: number;
	ratedVoltage: number;
	capacity: number;
	chargeKw: number;
	dischargeKw: number;
	chargeEfficiency: number;
	dischargeEfficiency: number;
	dod: number;
	socMax: number;
	socMin: number;
	selfDischargeKw: number;
	lifecycle: number;
	constructionCost: number;
	capacityCost: number;
	kwcost: number;
	fullCapacity: number;
	unloadCapacity: number;
	unloadTime: number;
	returnTime: number;
	cost: number;
	power: number;
}

const noneValue: DevDataType = {
	id: '',
	name: '',
	deviceType: '',
	resId: '',
	loadType: '',
	mainLoad: true,
	enableStatus: '',
	ct: 0,
	pt: 0,
	pvCapacity: 0,
	unitCost: 0,
	maintenanceCost: 0,
	ratedVoltage: 0,
	capacity: 0,
	chargeKw: 0,
	dischargeKw: 0,
	chargeEfficiency: 0,
	dischargeEfficiency: 0,
	dod: 0,
	socMax: 0,
	socMin: 0,
	selfDischargeKw: 0,
	lifecycle: 0,
	constructionCost: 0,
	capacityCost: 0,
	kwcost: 0,
	fullCapacity: 0,
	unloadCapacity: 0,
	unloadTime: 0,
	returnTime: 0,
	cost: 0,
	power: 0,
};

const deviceTypeOptions = [
	{ value: 'Meter', label: '電錶' },
	{ value: 'Meter12', label: 'ST12電錶' },
	{ value: 'Battery', label: '電池' },
];

const OrganizationDEV = () => {
	const [isCardType, setIsCardType] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [allData, setAllData] = useState([] as any[]);
	const [displayData, setDisplayData] = useState([] as any[]);
	const [pageIndex, setPageIndex] = useState(1);
	const [pageSize, setPageSize] = useState(8);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);

	const [resDropdownIsOpen, setResDropdownIsOpen] = useState(false);
	const [resDropdownList, setResDropdownList] = useState(
		[] as { value: string; label: string }[]
	);
	const [loadTypeDropdownIsOpen, setLoadTypeDropdownIsOpen] = useState(false);
	const [deviceTypeDropdownIsOpen, setDeviceTypeDropdownIsOpen] =
		useState(false);
	const [form] = Form.useForm();

	const tableColumns: ColumnsType<DevDataType> = [
		{
			title: '設備ID',
			dataIndex: 'devId',
			key: 'id',
			width: '13%',
			align: 'center',
			render: (_, record) => <div className="table-ellipsis">{record.id}</div>,
		},
		{
			title: '設備簡稱',
			dataIndex: 'name',
			key: 'name',
			width: '13%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">{record.name}</div>
			),
		},
		{
			title: '所屬場域ID',
			dataIndex: 'resId',
			key: 'resId' + 'id',
			width: '13%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">{record.resId}</div>
			),
		},
		{
			title: '設備類型',
			dataIndex: 'deviceType',
			key: 'deviceType',
			width: '13%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">
					{
						deviceTypeOptions.filter((d) => d.value === record.deviceType)[0]
							.label
					}
				</div>
			),
		},
		{
			title: '負載類型',
			dataIndex: 'loadType',
			key: 'loadType',
			width: '13%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">
					{loadTypeOptions.filter((d) => d.value === record.loadType)[0].label}
				</div>
			),
		},
		{
			title: '啟用狀態',
			dataIndex: 'enableStatus',
			key: 'enableStatus',
			width: '13%',
			align: 'center',
			render: (_, record) => (
				<div
					className={`table-ellipsis ${
						record.enableStatus === 'enable'
							? 'status-enable'
							: 'status-disable'
					}`}
				>
					{record.enableStatus === 'enable' ? '開啟' : '關閉'}
				</div>
			),
		},
		{
			title: '是否為總表',
			dataIndex: 'mainLoad',
			key: 'mainLoad',
			width: '13%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">{record.mainLoad ? '是' : '否'}</div>
			),
		},
		{
			title: '操作',
			key: 'action',
			align: 'center',
			render: (_, record) => (
				<>
					<Icon.Edit
						onClick={() => {
							setIsEdit(true);
							setIsOpenModal(true);
							form.setFieldsValue({
								...record,
								enableStatus: record.enableStatus === 'enable' ? true : false,
							});
						}}
					/>
				</>
			),
		},
	];

	const validateMessages = {
		required: '請輸入 ${label} ',
	};

	const displayTypeChange = (arg: string) => {
		setIsCardType(arg === 'CARD');
	};

	const getDevData = async () => {
		setIsLoading(true);
		const response: any = await api.get(
			'/webapi/organization/dev/list?fieldId'
		);
		if (response.data?.status !== 'ok') {
			return Modal.error({
				title: '找不到資料',
				content: '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}
		setAllData(response.data?.list);
		setIsLoading(false);
	};

	const getResData = async () => {
		const response: any = await api.get('/webapi/organization/res/list?txgId');
		if (response.data?.status !== 'ok') {
			return;
		}

		const result = response.data?.list.map((res: any) => {
			return {
				value: res.resId,
				label: `[${res.resId}] ${res.name}`,
			};
		});
		setResDropdownList(result);
	};

	const submitHandler = async (formData: DevDataType) => {
		const requestData: DevDataType = {
			...formData,
			enableStatus: formData.enableStatus ? 'enable' : 'disable',
		};

		let response;
		if (isEdit) {
			response = await api.put(
				`/webapi/organization/dev/${requestData.id}`,
				requestData
			);
		} else {
			response = await api.post(`/webapi/organization/dev`, requestData);
		}

		if (response.data?.status !== 'ok') {
			return Modal.error({
				title: `${isEdit ? '更新' : '新增'}失敗`,
				content:
					response.data?.err?.msg || '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		Modal.success({
			title: `${isEdit ? '更新' : '新增'}成功`,
			okText: '確定',
			centered: true,
			closable: true,
			closeIcon: <Icon.ModalClose />,
		});

		getDevData();
		setIsOpenModal(false);
	};

	useEffect(() => {
		getDevData();
		getResData();
	}, []);

	useEffect(() => {
		setPageSize(isCardType ? 8 : 10);
		setPageIndex(1);
	}, [isCardType]);

	useEffect(() => {
		if (pageIndex && pageSize && allData.length > 0) {
			const startIndex = (pageIndex - 1) * pageSize;
			const endIndex = Math.min(startIndex + pageSize - 1, allData.length - 1);
			setDisplayData(allData.slice(startIndex, endIndex + 1));
		}
	}, [pageIndex, pageSize, allData]);

	return (
		<Card
			title="設備 (DEV) 管理"
			className="organization-dev"
			bordered={false}
			extra={
				<Button
					type="primary"
					onClick={() => {
						setIsEdit(false);
						setIsOpenModal(true);
						form.setFieldsValue({ ...noneValue });
					}}
				>
					新增
				</Button>
			}
		>
			<CardTableSwitch displayTypeChange={displayTypeChange} />
			{isCardType ? (
				<Row gutter={[20, 28]} className="data-card">
					{displayData.map((dev, idx) => {
						return (
							<Col sm={24} md={12} lg={6} key={idx}>
								<Card
									className="card"
									key={idx}
									actions={[
										<div
											className="setting"
											key={dev.devId}
											onClick={() => {
												setIsEdit(true);
												setIsOpenModal(true);
												form.setFieldsValue({
													...dev,
													enableStatus: dev.enableStatus === 'enable',
												});
											}}
										>
											編輯
										</div>,
									]}
								>
									<div className="title">{dev.name}</div>
									<Row className="content">
										<Col span={11}>
											<div className="text">設備ID</div>
										</Col>
										<Col span={13}>
											<div className="result ellipsis">{dev.id}</div>
										</Col>
									</Row>
									<Row className="content">
										<Col span={11}>
											<div className="text">所屬場域ID</div>
										</Col>
										<Col span={13}>
											<div className="result ellipsis">{dev.resId}</div>
										</Col>
									</Row>
									<Row className="content">
										<Col span={11}>
											<div className="text">設備類型</div>
										</Col>
										<Col span={13}>
											<div className="result ellipsis">
												{
													deviceTypeOptions.filter(
														(d) => d.value === dev.deviceType
													)[0].label
												}
											</div>
										</Col>
									</Row>
									<Row className="content">
										<Col span={11}>
											<div className="text">負載類型</div>
										</Col>
										<Col span={13}>
											<div className="result ellipsis">
												{
													loadTypeOptions.filter(
														(d) => d.value === dev.loadType
													)[0].label
												}
											</div>
										</Col>
									</Row>
									<Row className="content">
										<Col span={11}>
											<div className="text">啟用狀態</div>
										</Col>
										<Col span={13}>
											{dev.enableStatus === 'enable' ? (
												<div className="status ellipsis status-enabled">
													開啟
												</div>
											) : (
												<div className="status ellipsis status-disabled">
													關閉
												</div>
											)}
										</Col>
									</Row>
									<Row className="content">
										<Col span={11}>
											<div className="text">是否為總表</div>
										</Col>
										<Col span={13}>
											<div className="result ellipsis">
												{dev.mainLoad ? '是' : '否'}
											</div>
										</Col>
									</Row>
								</Card>
							</Col>
						);
					})}
				</Row>
			) : (
				<div className="data-table">
					<Table
						columns={tableColumns}
						dataSource={displayData}
						pagination={false}
						loading={isLoading}
						rowKey={(record) => record.id}
					/>
				</div>
			)}
			{allData.length > 0 ? (
				<div className="pagination-bar">
					<div className="right">
						<Pagination
							total={allData.length}
							current={pageIndex}
							onChange={(page, size) => {
								setPageIndex(page);
								setPageSize(size);
							}}
							showSizeChanger
							showQuickJumper
							defaultPageSize={isCardType ? 8 : 10}
							pageSize={pageSize}
							pageSizeOptions={
								isCardType ? ['8', '16', '24'] : ['10', '20', '30']
							}
							locale={{
								items_per_page: ' / page',
								jump_to: 'Go to ',
								page: '',
							}}
							showTotal={(total) => `Total ${total} items`}
						/>
					</div>
				</div>
			) : null}
			<Modal
				title={isEdit ? '設備編輯' : '新增設備 (DEV)'}
				open={isOpenModal}
				centered
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				width={420}
				bodyStyle={{ maxHeight: '85vh', height: 'auto' }}
				className="form-modal organization-dev-modal"
				closeIcon={<Icon.ModalClose />}
				destroyOnClose={true}
				forceRender
				onCancel={() => {
					setIsOpenModal(false);
					form.setFieldsValue({ ...noneValue });
				}}
			>
				<Space direction="vertical" size="middle" style={{ display: 'flex' }}>
					<Form
						labelCol={{ span: 8 }}
						wrapperCol={{ span: 16 }}
						form={form}
						labelAlign="left"
						colon={false}
						onFinish={submitHandler}
						validateMessages={validateMessages}
					>
						<Form.Item name="id" label="設備ID" rules={[{ required: true }]}>
							<Input disabled={isEdit} />
						</Form.Item>
						<Form.Item
							name="name"
							label="設備簡稱"
							rules={[{ required: true }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name="resId"
							label="所屬場域ID"
							rules={[{ required: true }]}
						>
							<Select
								style={{ width: '100%' }}
								placeholder="請選擇"
								options={resDropdownList}
								onDropdownVisibleChange={() => {
									setResDropdownIsOpen((prevState: boolean) => !prevState);
								}}
								suffixIcon={
									resDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
								}
							/>
						</Form.Item>
						<Form.Item
							name="deviceType"
							label="設備類型"
							rules={[{ required: true }]}
						>
							<Select
								options={deviceTypeOptions}
								placeholder="請選擇"
								style={{ width: '100%' }}
								onDropdownVisibleChange={() => {
									setDeviceTypeDropdownIsOpen(
										(prevState: boolean) => !prevState
									);
								}}
								suffixIcon={
									deviceTypeDropdownIsOpen ? (
										<Icon.UpArrow />
									) : (
										<Icon.DownArrow />
									)
								}
							/>
						</Form.Item>
						<Form.Item
							name="loadType"
							label="負載類型"
							rules={[{ required: true }]}
						>
							<Select
								options={loadTypeOptions}
								placeholder="請選擇"
								style={{ width: '100%' }}
								onDropdownVisibleChange={() => {
									setLoadTypeDropdownIsOpen((prevState: boolean) => !prevState);
								}}
								suffixIcon={
									loadTypeDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
								}
							/>
						</Form.Item>
						<Form.Item
							name="mainLoad"
							label="是否為總表"
							rules={[{ required: true }]}
						>
							<Radio.Group>
								<Radio value={true}>是</Radio>
								<Radio value={false}>否</Radio>
							</Radio.Group>
						</Form.Item>
						<Form.Item
							name="enableStatus"
							label="啟用狀態"
							valuePropName="checked"
						>
							<Switch />
						</Form.Item>
						{isEdit ? (
							<Form.Item
								name="connectionStatus"
								label="連線狀態"
								className="connection-status"
							>
								<Tag
									color={
										ConnectionStatus.filter(
											(c) => c.value === form.getFieldValue('connectionStatus')
										)[0].color
									}
								>
									{
										ConnectionStatus.filter(
											(c) => c.value === form.getFieldValue('connectionStatus')
										)[0].text
									}
								</Tag>
							</Form.Item>
						) : null}
						{form.getFieldValue('loadType') ? (
							<div className="device-property-section">
								<div className="device-property-section-title">裝置屬性</div>
								{AllDeviceProperties.filter(
									(prop) =>
										LoadTypeWithDeviceProperty[
											form.getFieldValue('loadType')
										].indexOf(prop.name) > -1
								).map((prop, idx: number) => {
									return (
										<Form.Item
											label={prop.label}
											name={prop.name}
											key={idx}
											labelCol={{ span: 12 }}
											wrapperCol={{ span: 12 }}
											rules={[{ required: true }]}
											className="visible-item form-item"
										>
											<NumberInput
												step={1}
												min={0}
												precision={0}
												max={9999999}
											/>
										</Form.Item>
									);
								})}
								{AllDeviceProperties.filter(
									(prop) =>
										LoadTypeWithDeviceProperty[
											form.getFieldValue('loadType')
										].indexOf(prop.name) === -1
								).map((prop, idx: number) => {
									return (
										<Form.Item
											label={prop.label}
											name={prop.name}
											key={idx + 15}
											hidden={true}
										>
											<Input />
										</Form.Item>
									);
								})}
							</div>
						) : null}
						{isEdit ? (
							<>
								<div className="time-area">
									<div className="time-info">
										資料回報時間：
										{form.getFieldValue('reportTime')
											? dayjs(form.getFieldValue('reportTime')).format(
													'YYYY-MM-DD HH:mm:ss'
											  )
											: '無資料'}
									</div>

									<div className="time-info">
										資料建立時間：
										{dayjs(form.getFieldValue('createTimestamp')).format(
											'YYYY-MM-DD HH:mm:ss'
										)}
									</div>
									<div className="time-info">
										資料更新時間：
										{dayjs(form.getFieldValue('updateTimestamp')).format(
											'YYYY-MM-DD HH:mm:ss'
										)}
									</div>
								</div>
							</>
						) : null}
						<Button type="primary" htmlType="submit" className="submit-btn">
							{isEdit ? '更新' : '新增'}
						</Button>
					</Form>
				</Space>
			</Modal>
		</Card>
	);
};

export default OrganizationDEV;
