import React, { useEffect, useState } from 'react';
import {
	TXGBoardData,
	TXGBoardStates,
	TXGBoardStatesFilterOptions,
} from '@pages/OperatingOverview/OperatingOverview';
import { Link } from 'react-router-dom';
import { Card, Col, Row, Tag, Tooltip } from 'antd';
import { ServiceType, ResourceType } from '@models/common';
import { Icon } from '@models/icon';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import borderRadius from 'highcharts-rounded-corners';
import dayjs from 'dayjs';
import './OperatingOverviewCard.less';

highchartsMore(Highcharts);
borderRadius(Highcharts);

type Props = {
	txgData: TXGBoardData;
};

type Series = {
	name: string;
	data: number[];
	color?: string;
	zIndex?: number;
	borderRadius?: number;
	borderWidth?: number;
	borderColor?: string;
	borderRadiusTopLeft?: string;
	borderRadiusBottomLeft?: string;
	borderRadiusTopRight?: string;
	borderRadiusBottomRight?: string;
};

const todayString = dayjs().format('YYYY-MM-DD');

const getAwardedScale = (txgData: TXGBoardData) => {
	const begin = txgData.today.awardedPeriod?.begin ?? '';
	const end = txgData.today.awardedPeriod?.end ?? '';
	const startAwardedHour = begin ? dayjs(`${todayString} ${begin}`).hour() : '';
	const endAwardedHour = end ? dayjs(`${todayString} ${end}`).hour() : '';
	console.log(
		'begin end startAwardedHour endAwardedHour:',
		begin,
		end,
		startAwardedHour,
		endAwardedHour
	);

	const adjustedLabel = {
		formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
			const gray =
				'<span style="fill: #B0B4C0;font-weight: 400;font-size: 15px;">' +
				this.value +
				'</span>';
			const green =
				'<span style="fill: #72D126;font-weight: 700;font-size: 15px;">' +
				this.value +
				'</span>';

			if (!begin || !end) {
				return gray;
			}

			return this.value >= startAwardedHour && this.value <= endAwardedHour
				? green
				: gray;
		},
	};

	return adjustedLabel;
};

const getTimeOrder = (txgData: TXGBoardData) => {
	if (txgData.today.awardedPeriod?.begin) {
		const series: Series[] = [];

		// 得標
		const startOfAwarded = txgData.today.awardedPeriod?.begin
			? dayjs(`${todayString} ${txgData.today.awardedPeriod.begin}`)
			: '';

		const endOfAwarded = txgData.today.awardedPeriod?.end
			? dayjs(`${todayString} ${txgData.today.awardedPeriod?.end}`)
			: '';

		// 調度
		const startOfClipped = txgData.current.clippedPeriod?.begin
			? dayjs(`${todayString} ${txgData.current.clippedPeriod?.begin}`)
			: '';
		const endOfClipped = txgData.current.clippedPeriod?.end
			? dayjs(`${todayString} ${txgData.current.clippedPeriod?.end}`)
			: '';

		// 中止待命、棄標
		const startOfAbandoned = txgData.today.abandonPeriod?.begin
			? dayjs(`${todayString} ${txgData.today.abandonPeriod?.begin}`)
			: '';
		const endOfAbandoned = txgData.today.abandonPeriod?.end
			? dayjs(`${todayString} ${txgData.today.abandonPeriod?.end}`)
			: '';

		for (let i = 0; i < 24; i++) {
			const curr = dayjs(`${todayString} ${i < 10 ? '0' + i : i}:00`);
			let params = { name: '', color: '' };

			switch (true) {
				case i === dayjs(startOfClipped).hour() ||
					curr.isBetween(startOfClipped, endOfClipped):
					console.log('調度', i);
					params = { name: 'Clipped', color: '#FF7A00' };
					break;
				case i === dayjs(startOfAbandoned).hour() ||
					curr.isBetween(startOfAbandoned, endOfAbandoned):
					console.log('棄標', i);
					params = { name: 'Abandoned', color: '#667085' };
					break;
				case i === dayjs(startOfAwarded).hour() ||
					curr.isBetween(startOfAwarded, endOfAwarded):
					console.log('得標', i);
					params = { name: 'Awarded', color: '#72D126' };
					break;
				default:
					console.log('Other', i);
					params = { name: 'Other', color: '#fff' };
					break;
			}

			series.push({
				data: [1],
				...(i === 0
					? {
							borderRadiusBottomLeft: '100%',
							borderRadiusBottomRight: '100%',
					  }
					: {}),
				...(i === 23
					? {
							borderRadiusTopLeft: '100%',
							borderRadiusTopRight: '100%',
					  }
					: {}),
				borderWidth: 0,
				...params,
			});
		}

		return series;
	} else {
		return [
			{
				name: 'nothing',
				data: [24],
				color: '#fff',
				borderRadius: 5,
				borderWidth: 1,
				borderColor: '#D0D0D0',
			},
		];
	}
};

const OperatingOverviewCard = ({ txgData }: Props) => {
	const [cardOptions, setCardOptions] = useState({
		chart: {
			type: 'bar',
			height: 54,
			backgroundColor: 'transparent',
			marginBottom: 35,
		},
		title: {
			style: {
				display: 'none',
			},
		},
		tooltip: {
			enabled: false,
		},
		xAxis: {
			visible: false,
			allowDecimals: true,
		},
		yAxis: {
			min: 0,
			max: 24,
			tickInterval: 1,
			title: {
				enabled: false,
			},
			height: 8,
			type: 'bar',
			gridLineColor: 'transparent',
		},
		legend: {
			enabled: false,
		},
		plotOptions: {
			getExtremesFromAll: true,
			series: {
				stacking: 'normal',
				dataLabels: {
					enabled: false,
				},
				pointWidth: 8,
				animation: false,
				enableMouseTracking: false,
			},
			bar: {
				borderColor: 'transparent',
			},
		},
		series: [
			{
				name: 'null',
				data: [0],
			},
		] as Series[],
		credits: {
			enabled: false,
		},
	});
	const mainColor =
		TXGBoardStatesFilterOptions.filter(
			(state) => TXGBoardStates[state.value] === txgData.state
		)[0]?.color ?? '';
	const mainBgColor =
		TXGBoardStatesFilterOptions.filter(
			(state) => TXGBoardStates[state.value] === txgData.state
		)[0]?.bgColor ?? '-';

	useEffect(() => {
		const series = getTimeOrder(txgData);
		const labels = getAwardedScale(txgData);
		console.log('series:', series);

		setCardOptions((prev: any) => ({
			...prev,
			series: series.reverse(),
			yAxis: {
				...prev.yAxis,
				labels: labels,
			},
		}));
		console.log(cardOptions);
	}, [txgData]);

	return (
		<Card className="card-view" key={txgData.org.txgId}>
			<Row className="card-view-info" gutter={[4, 0]}>
				<Col span={5}>
					<div className="card-view-header">交易群組</div>
					<div className="card-view-title" style={{ color: mainColor }}>
						{txgData.org.txgName}
						<br />
						{txgData.org.txgId}
						<br />
						{`${ServiceType[txgData.org.serviceType as number]}/${
							ResourceType[txgData.org.resourceType as number]
						} (${txgData.org.resourceCount})`}
					</div>
				</Col>
				<Col span={9}>
					<div className="card-view-header">運轉資訊</div>
					<Row gutter={[14, 0]}>
						<Col span={6}>
							<div className="card-view-subtitle">得標量｜MW</div>
							<div className="card-view-value">
								{txgData.current.awardedCapacity}
							</div>
						</Col>
						<Col span={6}>
							<div className="card-view-subtitle">調度量｜MW</div>
							<div className="card-view-value">
								{txgData.current.clippedCapacity}
							</div>
						</Col>
						<Col span={12}>
							<div className="card-view-subtitle">調度通知時間</div>
							<div className="card-view-value">
								{txgData.current.noticeTime !== '----'
									? dayjs(txgData.current.noticeTime).format(
											'YYYY-MM-DD HH:mm:ss'
									  )
									: txgData.current.noticeTime}
							</div>
						</Col>
					</Row>
				</Col>
				<Col span={10}>
					<div className="card-view-header">得標資訊</div>
					<Row gutter={[14, 0]}>
						<Col span={6}>
							<div className="card-view-subtitle">容量｜MW</div>
							<div className="card-view-value">
								{txgData.today.awardedRange?.begin ?? '----'}
							</div>
						</Col>
						<Col span={7}>
							<div className="card-view-subtitle">容量費｜元/MW</div>
							<div className="card-view-value">
								{txgData.today.capacityPriceRange?.begin ?? '----'}
							</div>
						</Col>
						<Col span={11}>
							<div className="card-view-subtitle">電能費｜元/kW-h</div>
							<div className="card-view-value">
								{txgData.today.energyPriceRange?.begin ?? '----'}
							</div>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<div className="card-view-subtitle">得標區間</div>
							<div className="card-view-time-section">
								<div className="card-view-value">
									{txgData.today.awardedPeriod?.begin
										? `${todayString} ${txgData.today.awardedPeriod.begin}`
										: '----'}
								</div>
								<Icon.RightArrow />
								<div className="card-view-value">
									{txgData.today.awardedPeriod?.end
										? `${todayString} ${txgData.today.awardedPeriod.end}`
										: '----'}
								</div>
							</div>
						</Col>
					</Row>
				</Col>
				<div className="btn-group">
					<Link
						className="icon-btn"
						to="/market/abandon"
						state={{ selectedKey: 'market/abandon' }}
					>
						<Icon.Link />
					</Link>
					<Link
						className="icon-btn"
						to="/operating/service"
						state={{
							selectedKey: 'operating/service',
							selectedTxgId: txgData.org.txgId,
						}}
					>
						<Icon.Chart />
					</Link>
				</div>
			</Row>
			<div className="card-view-chart-details">
				<Row
					gutter={[14, 0]}
					className="card-view-chart"
					align="top"
					justify="space-between"
				>
					<Col span={2.5} style={{ position: 'relative' }}>
						{txgData.state === 'INSUFFICIENT' ? (
							<Tooltip
								placement="topLeft"
								title={'準備量不足'}
								trigger="click"
								open={true}
								getPopupContainer={(triggerNode: HTMLElement) =>
									triggerNode.parentNode as HTMLElement
								}
								color="#72D126"
								zIndex={3}
							/>
						) : null}
						<div className="card-view-header">目前運轉狀態</div>
						<Tag
							style={{
								color: mainColor,
								backgroundColor: mainBgColor,
								borderColor: mainColor,
							}}
							className="card-view-tag"
						>
							{TXGBoardStatesFilterOptions.filter(
								(state) => TXGBoardStates[state.value] === txgData.state
							)[0]?.label ?? '-'}
						</Tag>
					</Col>
					<Col span={4}>
						<div className="card-view-header">
							<div className="dot dot-awarded" />
							今日得標時數｜小時
						</div>
						<div className="card-view-value">
							{txgData.today.awardedHours === 0
								? '----'
								: `${txgData.today.awardedHours} Hr`}
						</div>
					</Col>
					<Col span={9} style={{ minWidth: '400px' }}>
						<div className="card-view-header">
							<div className="dot dot-clipped" />
							調度降載區間
						</div>
						<div className="card-view-time-section">
							<div className="card-view-value">
								{txgData.current.clippedPeriod?.begin
									? `${todayString} ${txgData.current.clippedPeriod.begin}`
									: '----'}
							</div>
							<Icon.RightArrow />{' '}
							<div className="card-view-value">
								{txgData.current.clippedPeriod?.end
									? `${todayString} ${txgData.current.clippedPeriod.end}`
									: '----'}
							</div>
						</div>
					</Col>
					<Col span={8.5} style={{ minWidth: '400px' }}>
						<div className="card-view-header">
							<div className="dot dot-abandoned" />
							棄標區間
						</div>
						<div className="card-view-time-section">
							<div className="card-view-value">
								{txgData.today.abandonPeriod?.begin
									? `${todayString} ${txgData.today.abandonPeriod.begin}`
									: '----'}
							</div>
							<Icon.RightArrow />
							<div className="card-view-value">
								{txgData.today.abandonPeriod?.end
									? `${todayString} ${txgData.today.abandonPeriod.end}`
									: '----'}
							</div>
						</div>
					</Col>
				</Row>
				<div
					style={{
						margin: '0 4px',
					}}
				>
					<HighchartsReact highcharts={Highcharts} options={cardOptions} />
				</div>
			</div>
		</Card>
	);
};

export default OperatingOverviewCard;
