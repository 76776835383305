import React, { useEffect, useState } from 'react';
import NoData from '@components/NoData/NoData';
import { useSelector } from 'react-redux';
import { getIsFullscreenState } from '@store/reducers/utilSlice';
import { Card, Modal, Spin, Timeline, TimelineItemProps } from 'antd';
import { ServiceType, UnitType } from '@models/common';
import { Icon } from '@models/icon';
import {
	SelectedService,
	TimeType,
} from '@pages/OperatingService/OperatingService';
import { flatten, isEmpty } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import api from '@api/api';

interface CommandHistoryProps {
	selectedTXG: string;
	timeType: number;
	serviceObj: SelectedService;
	dateTime: Dayjs | null;
	selectedWeekday: number;
	dateRange: [Dayjs | null, Dayjs | null] | null;
}

const EventType = {
	TYPE_A: '#4391FF',
	TYPE_B: '#F33535',
	TYPE_C: '#72D126',
	REACT: '#667085',
};

const CommandHistory = (props: CommandHistoryProps) => {
	const isFullscreen = useSelector(getIsFullscreenState);
	const [isLoading, setIsLoading] = useState(false);
	const [timelineArr, setTimelineArr] = useState([] as TimelineItemProps[]);

	const getHistory = async (id: string) => {
		if (isLoading) return;
		setIsLoading(true);

		const isNowUnitTypeTXG = props.serviceObj.unitType === UnitType.TXG;
		const category = props.serviceObj.category;

		let date = '';
		let diff = 0;

		if (props.timeType === TimeType.WeekOrImmediate) {
			date = (dayjs() as any)
				.startOf('week')
				.add(props.selectedWeekday - 1, 'day')
				.format('YYYY-MM-DD');
			diff = 1;
		} else {
			if (category === ServiceType.SR) {
				if (!props.dateRange || !props.dateRange[0] || !props.dateRange[1]) {
					return setIsLoading(false);
				}

				const dateRangeStart = dayjs(props.dateRange[0]).format('YYYY-MM-DD');
				const dateRangeEnd = dayjs(props.dateRange[1]);
				date = dateRangeStart;
				diff = dateRangeEnd.diff(dateRangeStart, 'day') + 1;
			} else {
				if (!props.dateTime) {
					return setIsLoading(false);
				}

				date = props.dateTime.format('YYYY-MM-DD');
				diff = 1;
			}
		}
		console.log('getHistory date diff:', date, diff);

		const url = isNowUnitTypeTXG
			? `/webapi/trial/event/list/multi/${id}/${date}?count=${diff}`
			: `/webapi/trial/event/field/multi/${id}/${date}?count=${diff}`;

		const res = await api.get(url);
		console.log('getHistory res:', res);

		if (res.data?.status === 'fail') {
			setIsLoading(false);
			return Modal.error({
				title: '找不到資料',
				content: '請確認網路連線狀態，並重新操作。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		const resArr = flatten(res.data.list.map((r: any) => r.list)) as any[];
		console.log('getHistory resArr:', resArr);

		setTimelineArr(
			resArr
				.sort((a, b) => b.eventTime - a.eventTime)
				.map((r) => {
					const color = EventType[
						r.eventType as keyof typeof EventType
					] as string;
					return {
						label: (
							<div style={{ color }}>
								{dayjs(r.eventTime).format('YYYY-MM-DD HH:mm:ss')}
							</div>
						),
						children: r.eventDesc,
						color,
					};
				})
		);
		setIsLoading(false);
	};

	useEffect(() => {
		console.log('CommandHistory props:', props, isLoading);

		if (!isEmpty(props.serviceObj) && props.selectedTXG && !isLoading) {
			getHistory(props.selectedTXG);
		}
	}, [
		props.timeType,
		props.serviceObj,
		props.dateTime,
		props.selectedWeekday,
		props.dateRange,
	]);

	return (
		<Card
			title="台電指令歷程"
			bordered={false}
			className={`command-history ${isFullscreen ? 'hide' : ''}`}
		>
			{isLoading ? (
				<Spin className="spin-center" />
			) : (
				<>
					<Timeline mode="alternate" items={timelineArr} />
					<div className="fixed-no-data">
						{timelineArr.length === 0 ? <NoData /> : ''}
					</div>
				</>
			)}
		</Card>
	);
};

export default CommandHistory;
