import { CustomToken } from '@models/common';
import { createSlice } from '@reduxjs/toolkit';
import type { IRootState } from '@store/reducers';

export const utilSlice = createSlice({
	name: 'utilSlice',
	initialState: {
		isFullscreen: false,
		customThemeToken: {} as CustomToken,
	},
	reducers: {
		setIsFullscreen: (state, action) => {
			const elem = document.documentElement;

			if (action.payload && elem.requestFullscreen) {
				elem.requestFullscreen();
			} else {
				document.exitFullscreen();
			}

			state.isFullscreen = action.payload;
		},
		setCustomThemeToken: (state, action) => {
			state.customThemeToken = action.payload;
		},
	},
});

export const getIsFullscreenState = (state: IRootState) =>
	state.utilSlice.isFullscreen;

export const getCustomThemeToken = (state: IRootState) =>
	state.utilSlice.customThemeToken;

export const { setIsFullscreen, setCustomThemeToken } = utilSlice.actions;

export default utilSlice.reducer;
