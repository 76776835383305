import { GlobalToken } from 'antd/es/theme/interface';

export interface CustomToken extends GlobalToken {
	colorChartNormal: string;
}

export enum ServiceType {
	AFC = 1,
	DREG,
	SREG,
	SR,
	SUP,
	EDREG,
}

export enum ResourceType {
	'無資源類型' = 0,
	DR = 1,
	CGEN,
	UGEN,
	GESS,
}

export enum UnitType {
	QSE = 'QSE',
	TXG = 'TXG',
	RES = 'RES',
}

export const weekdays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

export const shareChartOption = (token: CustomToken) => {
	return {
		title: null,
		tooltip: {
			shared: true,
			backgroundColor: token.colorBgContainer,
			borderColor: token.colorBorder,
			borderRadius: token.borderRadius,
			borderWidth: 1,
			shadow: true,
			animation: true,
			style: {
				fontSize: '12px',
				fontWeight: 400,
			},
			useHTML: true,
			headerFormat: `<div style="color: ${token.colorTextQuaternary};">{point.key}</div>`,
			pointFormat: `<div style="margin-top: 8px; display: flex; justify-content: space-between;">
											<div style="color:{series.color};margin-right: 15px;">{series.name}</div>
											<div style="color:${token.colorTextTertiary};text-align: right;">{point.y}</div>
										</div>
										`,
			xDateFormat: '%Y-%m-%d %H:%M:%S',
		},
		legend: {
			useHTML: true,
			labelFormatter: function (this: any) {
				return `<svg width="20" height="11" viewBox="0 0 20 11" fill="none">
						<path d="M18 5C17.7596 5.00172 17.5214 5.04677 17.297 5.133L14.899 3.17C14.958 2.956 15 2.734 15 2.5C15 1.114 13.886 0 12.5 0C11.114 0 10 1.114 10 2.5C10 2.896 10.1 3.265 10.262 3.597L7.353 7.035C7.23656 7.01321 7.11845 7.00149 7 7C6.821 7 6.652 7.03 6.488 7.074L3.925 4.511C3.97 4.348 4 4.179 4 4C4 2.892 3.108 2 2 2C0.892 2 0 2.892 0 4C0 5.108 0.892 6 2 6C2.179 6 2.348 5.97 2.512 5.926L5.075 8.489C5.02693 8.65514 5.0017 8.82705 5 9C5 10.108 5.892 11 7 11C8.108 11 9 10.108 9 9C9 8.763 8.952 8.54 8.877 8.329L11.79 4.887C12.017 4.953 12.252 5 12.5 5C12.8946 4.99782 13.2831 4.90148 13.633 4.719L16.032 6.682C16.0132 6.78702 16.0025 6.89334 16 7C16 8.108 16.892 9 18 9C19.108 9 20 8.108 20 7C20 5.892 19.108 5 18 5Z" fill="${this.color}"/>
					</svg>
					<span style="color: ${this.color}; font-weight: 400;">${this.name}</span>
					`;
			},
		},
		credits: {
			enabled: false,
		},
		time: {
			useUTC: false,
		},
		xAxis: {
			type: 'datetime',
			dateTimeLabelFormats: {
				millisecond: '%H:%M:%S.%L',
				second: '%H:%M:%S',
				minute: '%H:%M',
				hour: '%H:%M',
				day: '%m-%d',
				week: '%m-%d',
				month: '%Y-%m',
				year: '%Y',
			},
			labels: {
				style: {
					color: token.colorChartNormal,
				},
			},
			lineColor: token.colorChartNormal,
			tickColor: token.colorChartNormal,
		},
		accessibility: {
			enabled: false,
		},
		plotOptions: {
			series: {
				animation: false,
				connectNulls: true,
				// fillOpacity: 1,
			},
		},
	};
};
