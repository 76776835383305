import React, { useEffect, useState } from 'react';
import type { AppDispatch } from 'src';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { getIsFullscreenState } from '@store/reducers/utilSlice';
import { Outlet, useNavigate, Link, useLocation } from 'react-router-dom';
import {
	Menu,
	MenuProps,
	Popover,
	Switch,
	Layout,
	FloatButton,
	Modal,
} from 'antd';
import { logOut } from '@store/reducers/userSlice';
import useTheme from '@hooks/useTheme';
import { Icon } from '@models/icon';
import { flatten } from 'lodash';
import { MenuInfo, menuItems } from './Menu';
import './AuthMainLayout.less';

const { Header, Content, Footer, Sider } = Layout;

function AuthMainLayout() {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const isFullscreen = useSelector(getIsFullscreenState);
	const [darkMode, setDarkMode] = useTheme();
	const [selectedMenuKey, setSelectedMenuKey] = useState([] as string[]);
	const [openUserDropdown, setOpenUserDropdown] = useState(false);
	const [authMenus, setAuthMenus] = useState<MenuInfo[]>(menuItems);
	const { pathname } = useLocation();

	const userJson = JSON.parse(
		localStorage.getItem('energy-dispatch-user') as string
	);

	useEffect(() => {
		if (userJson?.authority && userJson?.authority.length > 0) {
			console.log('userJson?.authority:', userJson?.authority);
			const role = userJson.authority[0] ?? 1;
			const result: MenuInfo[] = [];

			menuItems.forEach((parent) => {
				if (parent.auth.includes(role)) {
					const arr = parent.children.filter((child) =>
						child.auth.includes(role)
					);
					result.push({ ...parent, children: arr });
				}
			});
			console.log('result:', result);

			setAuthMenus(result);
		}
	}, []);

	useEffect(() => {
		if (pathname) {
			const menus = flatten(menuItems.map((m) => m.children));
			const nowItem = menus.filter((m) => '/' + m.key === pathname)[0];
			console.log('nowItem:', nowItem);

			if (!nowItem) return;
			setSelectedMenuKey([nowItem.key]);
			navigate('/' + nowItem.key);
		}
	}, [pathname]);

	const logOutHandler = () => {
		setOpenUserDropdown(false);

		Modal.confirm({
			wrapClassName: 'custom-confirm-modal',
			title: (
				<div className="header">
					<Icon.MessageWarn className="icon" />
					<div className="title">確認登出</div>
				</div>
			),
			content: (
				<div className="context">
					<div className="text">您確定要登出嗎？</div>
				</div>
			),
			centered: true,
			icon: null,
			closable: true,
			closeIcon: <Icon.ModalClose />,
			onOk: () => {
				dispatch(logOut())
					.then(unwrapResult)
					.then(async () => {
						navigate('/login');
					});
			},
		});
	};

	const handleMenuClick: MenuProps['onClick'] = (e) => {
		setSelectedMenuKey([e.key]);
		navigate(e.key);
	};

	const handleOpenChange = (newOpen: boolean) => {
		setOpenUserDropdown(newOpen);
	};

	const dropdownStyles = (
		<div className="header-dropdown">
			<div className="header-dropdown-item toggle-theme">
				深色模式
				<Switch size="small" checked={darkMode} onChange={setDarkMode} />
			</div>
			<Link to="/system/userEdit" className="header-dropdown-item">
				使用者資訊
			</Link>
			<div className="header-dropdown-item pointer" onClick={logOutHandler}>
				登出
			</div>
		</div>
	);

	return (
		<div id="main-layout" className={isFullscreen ? 'hide' : ''}>
			<Layout>
				<Header className="header">
					<Link
						to="/"
						onClick={() => {
							setSelectedMenuKey([]);
						}}
					>
						<Icon.Logo />
						<div className="header-title">智慧能源調度系統</div>
					</Link>
					<Popover
						placement="bottomRight"
						content={dropdownStyles}
						trigger="click"
						open={openUserDropdown}
						onOpenChange={handleOpenChange}
					>
						<div className="user-logo" style={{ width: '40px' }}>
							<Icon.HeaderUser />
						</div>
					</Popover>
				</Header>
				<Layout>
					<Sider className="sidebar">
						<Menu
							style={{ width: 215 }}
							selectedKeys={selectedMenuKey}
							onClick={handleMenuClick}
							defaultSelectedKeys={['1']}
							defaultOpenKeys={menuItems.map((item: any) => item.key)} // 初始展开的 SubMenu 菜单项 key
							theme={darkMode ? 'dark' : 'light'}
							mode="inline"
							items={authMenus}
							inlineIndent={16} // 40px
						/>
					</Sider>
					<Layout>
						<Content className="main-content">
							<Outlet />
							<FloatButton.BackTop
								className="back-top-btn"
								icon={<Icon.BackTop />}
								type="primary"
								description="回上層"
								target={() =>
									document.getElementsByClassName(
										'main-content'
									)[0] as HTMLElement
								}
								visibilityHeight={300}
								shape="square"
							/>
						</Content>
						<Footer className="footer">
							Copyright ©️ 2022 泓德能源科技股份有限公司
						</Footer>
					</Layout>
				</Layout>
			</Layout>
		</div>
	);
}
export default AuthMainLayout;
