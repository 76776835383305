import React, { useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import {
	DatePicker,
	Select,
	Button,
	Card,
	Table,
	Modal,
	Radio,
	Pagination,
} from 'antd';
import { Icon } from '@models/icon';
import { OrgTree, Role } from '@models/user';
import { commafy } from '@utils/format';
import api from '@api/api';
import dayjs, { Dayjs } from 'dayjs';
import { jsonToCSV } from 'react-papaparse';
import fileDownload from 'js-file-download';
import './PerformanceExpense.less';

interface TableData {
	key: React.ReactNode;
	avgCapacityPrice: number;
	avgRevenueFactor: number;
	awardedCounter: number;
	capacityRevenue: number;
	efficiencyRevenue: number;
	kwhRevenue: number;
	noticeCounter: number;
	revenue: number;
	servingIndex: number;
	srName: string;
	time: number;
	totalAwardedCapacity: number;
	list?: TableData[];
	fieldName: string;
	name: string;
}

const columns: ColumnsType<TableData> = [
	{
		title: '用戶/場域名稱',
		dataIndex: 'name',
		key: 'name',
		width: '16.5%',
	},
	{
		title: '日期',
		dataIndex: 'time',
		key: 'time',
		width: '10%',
		align: 'center',
	},
	{
		title: (
			<>
				得標時數
				<br />
				(Hours)
			</>
		),
		dataIndex: 'awardedCounter',
		key: 'awardedCounter',
		width: '9%',
		align: 'center',
		render: (_, record) => <>{commafy(record.awardedCounter)}</>,
	},
	{
		title: (
			<>
				總得標容量 <br />
				(MW)
			</>
		),
		dataIndex: 'totalAwardedCapacity',
		key: 'totalAwardedCapacity',
		width: '10%',
		align: 'center',
		render: (_, record) => <>{commafy(record.totalAwardedCapacity)}</>,
	},
	{
		title: '執行時數',
		dataIndex: 'noticeCounter',
		key: 'noticeCounter',
		width: '8.5%',
		align: 'center',
		render: (_, record) => <>{commafy(record.noticeCounter)}</>,
	},
	{
		title: (
			<>
				容量費用 <br />
				(NT$)
			</>
		),
		dataIndex: 'capacityRevenue',
		key: 'capacityRevenue',
		width: '9%',
		align: 'center',
		render: (_, record) => <>{commafy(record.capacityRevenue)}</>,
	},
	{
		title: (
			<>
				平均得標價格 <br />
				(NT$)
			</>
		),
		dataIndex: 'avgCapacityPrice',
		key: 'avgCapacityPrice',
		width: '11%',
		align: 'center',
		render: (_, record) => <>{commafy(record.avgCapacityPrice)}</>,
	},
	{
		title: (
			<>
				電能費用 <br />
				(NT$)
			</>
		),
		dataIndex: 'kwhRevenue',
		key: 'kwhRevenue',
		width: '9%',
		align: 'center',
		render: (_, record) => <>{commafy(record.kwhRevenue)}</>,
	},
	{
		title: (
			<>
				效能費用 <br />
				(NT$)
			</>
		),
		dataIndex: 'efficiencyRevenue',
		key: 'efficiencyRevenue',
		width: '9%',
		align: 'center',
		render: (_, record) => <>{commafy(record.efficiencyRevenue)}</>,
	},
	{
		title: (
			<>
				總費用 <br />
				(NT$)
			</>
		),
		dataIndex: 'revenue',
		key: 'revenue',
		width: '8%',
		align: 'center',
		render: (_, record) => <>{commafy(record.revenue)}</>,
	},
];

const PerformanceExpense = () => {
	const [isDownloading, setIsDownloading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [qseDropdownIsOpen, setQSEDropdownIsOpen] = useState(false);
	const [txgDropdownIsOpen, setTXGDropdownIsOpen] = useState(false);
	const [qseDropdownList, setQSEDropdownList] = useState([] as OrgTree[]);
	const [txgDropdownList, setTXGDropdownList] = useState([] as OrgTree[]);
	const [selectedQSE, setSelectedQSE] = useState('');
	const [selectedTXG, setSelectedTXG] = useState('');
	const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
	const [selectedType, setSelectedType] = useState('day');
	const [allData, setAllData] = useState([] as TableData[]);
	const [displayData, setDisplayData] = useState<TableData[]>([]);
	const [pageIndex, setPageIndex] = useState(1);
	const [pageSize, setPageSize] = useState(13);

	const allOrgTreeList: OrgTree[] = JSON.parse(
		localStorage.getItem('energy-dispatch-allOrgTree') as string
	);
	const userJson = JSON.parse(
		localStorage.getItem('energy-dispatch-user') as string
	);

	const getTableData = async () => {
		setIsLoading(true);
		const date = dayjs(
			dayjs(selectedDate).format('YYYY-MM-DD 00:00:00')
		).valueOf();

		const response = await api.get(
			`/webapi/revenue/sr/list?id=${selectedTXG}&date=${date}&statisticsType=${selectedType}`
		);
		console.log('table data', response.data);

		setIsLoading(false);
		if (response.data?.status !== 'ok') {
			return Modal.error({
				title: '找不到資料',
				content: '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		let arr: TableData[] = [];
		if (response.data?.list.length > 0) {
			arr = response.data?.list.map((txg: any, txgIdx: number) => ({
				...txg,
				name: txg.srName,
				key: 'txg' + (txgIdx + 1),
				time:
					selectedType === 'day'
						? dayjs(txg.time).format('YYYY/MM/DD')
						: dayjs(txg.time).format('YYYY/MM'),
				list: txg.list.map((res: any, resIdx: number) => ({
					...res,
					name: res.fieldName,
					time: '',
					key: 'res' + (txgIdx + 1) + (resIdx + 1),
				})),
			}));
		}
		console.log('data', arr);
		setAllData(arr);
	};

	const downloadCSV = () => {
		const newArr: TableData[] = [];
		allData.forEach((item) => {
			newArr.push(item);
			newArr.push(...(item.list as TableData[]));
		});
		console.log('newArr:', newArr);

		const jsonData = newArr.map((item) => ({
			用戶名稱: item.srName,
			日期: item.time,
			場域名稱: item.fieldName,
			'得標時數(Hours)': item.awardedCounter,
			'總得標容量(MW)': item.totalAwardedCapacity,
			執行降載次數: item.noticeCounter,
			'容量費用(NT$)': item.capacityRevenue,
			'平均得標價格(NT$)': item.avgCapacityPrice,
			'電能費用(NT$)': item.kwhRevenue,
			'效能費用(NT$)': item.efficiencyRevenue,
			'總費用(NT$)': item.revenue,
		}));

		const txgName = txgDropdownList.filter(
			(txg) => txg.unitId === selectedTXG
		)[0].unitName;

		const results = jsonToCSV(jsonData);
		fileDownload(
			results,
			`服務費用統計_${txgName}_${
				selectedType === 'day'
					? dayjs(selectedDate).format('YYYY/MM')
					: dayjs(selectedDate).format('YYYY')
			}.csv`
		);

		Modal.success({
			title: '檔案已經下載至您的電腦',
			content: '請點擊確定按鈕返回頁面',
			okText: '確定',
			centered: true,
			closable: true,
			closeIcon: <Icon.ModalClose />,
		});
	};

	useEffect(() => {
		if (isDownloading) setIsDownloading(false);
	}, [isDownloading]);

	useEffect(() => {
		if (allOrgTreeList.length > 0) {
			setQSEDropdownList(allOrgTreeList);
			setSelectedQSE(allOrgTreeList[0].unitId);
		}
	}, []);

	useEffect(() => {
		if (selectedQSE) {
			const newArr = allOrgTreeList.filter((a) => a.unitId === selectedQSE)[0]
				.subUnits;
			setTXGDropdownList(newArr);
			setSelectedTXG(newArr[0]?.unitId ?? '');
		}
	}, [selectedQSE]);

	useEffect(() => {
		if (selectedQSE && selectedTXG && selectedDate) {
			getTableData();
		}
	}, [selectedQSE, selectedTXG, selectedDate, selectedType]);

	useEffect(() => {
		if (pageIndex && pageSize && allData.length > 0) {
			const startIndex = (pageIndex - 1) * pageSize;
			const endIndex = Math.min(startIndex + pageSize - 1, allData.length - 1);

			setDisplayData(allData.slice(startIndex, endIndex + 1));
		}
	}, [pageIndex, pageSize, allData]);

	return (
		<Card title="服務費用統計" bordered={false} className="performance-expense">
			<div className="tool-bar">
				<div className="left">
					<Select
						style={{ width: 180 }}
						onDropdownVisibleChange={() => {
							setQSEDropdownIsOpen((prevState: boolean) => !prevState);
						}}
						value={selectedQSE}
						onChange={(arg) => setSelectedQSE(arg)}
						suffixIcon={
							qseDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
						}
						options={qseDropdownList.map((qse: OrgTree) => ({
							value: qse.unitId,
							label: qse.unitName,
							disabled: qse.subUnits.length === 0,
						}))}
					/>
					<Select
						style={{ width: 180 }}
						onDropdownVisibleChange={() => {
							setTXGDropdownIsOpen((prevState: boolean) => !prevState);
						}}
						value={selectedTXG}
						onChange={(arg) => setSelectedTXG(arg)}
						suffixIcon={
							txgDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
						}
						options={txgDropdownList.map((txg: OrgTree) => ({
							value: txg.unitId,
							label: txg.unitName,
							disabled: [Role.SYSAdmin, Role.QSEAdmin, Role.QSEUser].includes(
								userJson.authority[0] ?? 1
							)
								? false
								: txg.unitId !== userJson.unitId,
						}))}
					/>
				</div>
				<div className="right">
					統計單位
					<Radio.Group
						onChange={(arg) => {
							setSelectedType(arg.target.value);
						}}
						value={selectedType}
					>
						<Radio value={'day'}>日</Radio>
						<Radio value={'month'}>月</Radio>
					</Radio.Group>
					<DatePicker
						style={{ width: 145 }}
						onChange={(arg) => setSelectedDate(arg)}
						suffixIcon={<Icon.Day />}
						value={selectedDate}
						placeholder={'開始時間'}
						format={selectedType === 'day' ? 'YYYY-MM' : 'YYYY'}
						picker={selectedType === 'day' ? 'month' : 'year'}
					/>
					<Button ghost type="primary" onClick={() => downloadCSV()}>
						匯出CSV
					</Button>
				</div>
			</div>
			<div className="data-table">
				<Table
					columns={columns}
					dataSource={displayData}
					loading={isLoading}
					pagination={false}
					expandable={{
						indentSize: 25,
						childrenColumnName: 'list',
						expandIcon: ({ expanded, onExpand, record }) =>
							record?.list ? (
								expanded ? (
									<DownOutlined
										onClick={(e) => onExpand(record, e)}
										className="expand-icon"
									/>
								) : (
									<RightOutlined
										onClick={(e) => onExpand(record, e)}
										className="expand-icon"
									/>
								)
							) : (
								''
							),
					}}
				/>
			</div>
			{allData.length > 0 ? (
				<div className="pagination-bar">
					<div className="right">
						<Pagination
							total={allData.length}
							current={pageIndex}
							onChange={(page, size) => {
								setPageIndex(page);
								setPageSize(size);
							}}
							showSizeChanger
							showQuickJumper
							defaultPageSize={6}
							pageSize={pageSize}
							pageSizeOptions={['6', '12', '24']}
							locale={{
								items_per_page: ' / page',
								jump_to: 'Go to ',
								page: '',
							}}
							showTotal={(total) => `Total ${total} items`}
						/>
					</div>
				</div>
			) : null}
		</Card>
	);
};

export default PerformanceExpense;
