import React, { useEffect, useState } from 'react';
import { useFirstMountState } from 'react-use';
import { FileOutlined } from '@ant-design/icons';
import {
	DatePicker,
	Card,
	Modal,
	Tree,
	Descriptions,
	Spin,
	Row,
	Col,
} from 'antd';
import { ServiceType, ResourceType } from '@models/common';
import { Icon } from '@models/icon';
import { Role } from '@models/user';
import { commafy } from '@utils/format';
import dayjs, { Dayjs } from 'dayjs';
import api from '@api/api';
import './OrganizationStructure.less';

interface DescriptionData {
	type: 'QSE' | 'TXG' | 'RES';
	data: any;
}

interface TreeData {
	title: string;
	key: string;
	children: TreeData[];
	switcherIcon: JSX.Element | boolean;
	unitType: string;
	disabled?: boolean;
}

const updateDescriptionData = (
	descriptionData: DescriptionData
): React.ReactElement | null => {
	const { type, data } = descriptionData;
	console.log('data:', data);

	if (type === 'QSE') {
		return (
			<Descriptions title={data.name} bordered column={2}>
				<Descriptions.Item label="交易商ID">{data.qseId}</Descriptions.Item>
				<Descriptions.Item label="交易商簡稱">{data.name}</Descriptions.Item>
				<Descriptions.Item label="所屬公司ID">
					{data.companyId}
				</Descriptions.Item>
				<Descriptions.Item label="交易商代碼">{data.qseCode}</Descriptions.Item>
				<Descriptions.Item label="內網位址 (VPN LAN IP)">
					{data.vpnLanIp}
				</Descriptions.Item>
				<Descriptions.Item label="外網位址 (VPN WAN IP)">
					{data.vpnWanIp}
				</Descriptions.Item>
				<Descriptions.Item label="DNP位址" span={2}>
					{data.dnpUrl}
				</Descriptions.Item>
				<Descriptions.Item span={2}>
					<div>
						資料建立時間：
						{dayjs(data.createTimestamp).format('YYYY/MM/DD HH:mm:ss')}
					</div>
					<div>
						資料更新時間：
						{dayjs(data.updateTimestamp).format('YYYY/MM/DD HH:mm:ss')}
					</div>
				</Descriptions.Item>
			</Descriptions>
		);
	} else if (type === 'TXG') {
		return (
			<Descriptions title={data.name} bordered column={2}>
				<Descriptions.Item label="交易群組ID">{data.txgId}</Descriptions.Item>
				<Descriptions.Item label="交易群組簡稱">{data.name}</Descriptions.Item>
				<Descriptions.Item label="所屬公司ID">
					{data.companyId}
				</Descriptions.Item>
				<Descriptions.Item label="交易群組代碼">
					{data.txgCode}
				</Descriptions.Item>
				<Descriptions.Item label="所屬交易商ID">{data.qseId}</Descriptions.Item>
				<Descriptions.Item label="輔助服務類型">
					{ServiceType[data.serviceType]}
				</Descriptions.Item>
				<Descriptions.Item label="註冊容量">
					{commafy(data.registerCapacity)}
				</Descriptions.Item>
				<Descriptions.Item label="效能價格">
					{data.efficiencyPrice}
				</Descriptions.Item>
				<Descriptions.Item span={2}>
					<div>
						資料建立時間：
						{dayjs(data.createTimestamp).format('YYYY/MM/DD HH:mm:ss')}
					</div>
					<div>
						資料更新時間：
						{dayjs(data.updateTimestamp).format('YYYY/MM/DD HH:mm:ss')}
					</div>
				</Descriptions.Item>
			</Descriptions>
		);
	} else if (type === 'RES') {
		return (
			<Descriptions title={data.name} bordered column={2}>
				<Descriptions.Item label="場域ID">{data.resId}</Descriptions.Item>
				<Descriptions.Item label="場域簡稱">{data.name}</Descriptions.Item>
				<Descriptions.Item label="所屬公司ID">
					{data.companyId}
				</Descriptions.Item>
				<Descriptions.Item label="場域代碼">{data.resCode}</Descriptions.Item>
				<Descriptions.Item label="所屬交易群組ID">
					{data.txgId}
				</Descriptions.Item>
				<Descriptions.Item label="資源類型">
					{ResourceType[data.resType]}
				</Descriptions.Item>
				<Descriptions.Item label="場域誤差因子">
					{data.accFactor}
				</Descriptions.Item>
				<Descriptions.Item label="註冊容量">
					{commafy(data.registerCapacity)}
				</Descriptions.Item>
				<Descriptions.Item label="ThinClient 啟用狀態">
					{data.tcEnable === 'enable' ? (
						<div style={{ color: '#3EC011' }}>開啟</div>
					) : (
						<div>未開啟</div>
					)}
				</Descriptions.Item>
				<Descriptions.Item label="ThinClient URL">
					{data.tcUrl}
				</Descriptions.Item>
				<Descriptions.Item span={2}>
					<div>
						資料建立時間：
						{dayjs(data.createTimestamp).format('YYYY/MM/DD HH:mm:ss')}
					</div>
					<div>
						資料更新時間：
						{dayjs(data.updateTimestamp).format('YYYY/MM/DD HH:mm:ss')}
					</div>
				</Descriptions.Item>
			</Descriptions>
		);
	} else {
		return null;
	}
};

const OrganizationStructure = () => {
	const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
	const [treeData, setTreeData] = useState([] as any);
	const [treeDataIsLoading, setTreeDataIsLoading] = useState(true);
	const [descriptionData, setDescriptionData] = useState({} as DescriptionData);
	const [descriptionIsLoading, setDescriptionIsLoading] = useState(true);
	const [descriptionTable, setDescriptionTable] = useState(
		(<></>) as React.ReactElement
	);
	const [qseList, setQseList] = useState([]);
	const isFirstMount = useFirstMountState();

	const userJson = JSON.parse(
		localStorage.getItem('energy-dispatch-user') as string
	);
	const userRole = userJson.authority[0] ?? 1;

	const getOrgTree = (date: string) =>
		api.get(`/webapi/operating/org-tree-list?date=${date}`);
	const getQseList = () => api.get(`/webapi/organization/qse/list`);

	const getMineOrgTree = (date: string) =>
		api.get(`/webapi/operating/org-tree/mine?date=${date}`);
	const getSingleTxg = (id: string) =>
		api.get(`/webapi/organization/txg/${id}`);
	const getSingleRes = (id: string) =>
		api.get(`/webapi/organization/res/${id}`);

	const getInitData = async () => {
		setTreeDataIsLoading(true);
		setDescriptionIsLoading(true);
		const date = dayjs(selectedDate).format('YYYY-MM-DD').valueOf();

		try {
			switch (userRole) {
				case Role.SYSAdmin:
				default: {
					const res = await Promise.all([getOrgTree(date), getQseList()]);
					const orgTree = res[0].data.list;
					const qse = res[1].data.list;

					const initType = orgTree[0].myUnit.unitType;

					organizeAndSetTreeData(orgTree);

					setQseList(qse);
					setDescriptionData({ type: initType, data: qse[0] });
					setDescriptionIsLoading(false);
					break;
				}
				case Role.QSEAdmin:
				case Role.QSEUser: {
					const res = await Promise.all([getMineOrgTree(date), getQseList()]);
					const orgTree = [res[0].data.data];

					const qse = res[1].data.list;

					organizeAndSetTreeData(orgTree);

					setQseList(qse);
					setDescriptionData({ type: 'QSE', data: qse[0] });
					setDescriptionIsLoading(false);
					break;
				}
				case Role.TXGAdmin:
				case Role.TXGUser: {
					const orgRes = await getMineOrgTree(date);
					const orgTree = [orgRes.data.data];

					const txgRes = await getSingleTxg(
						orgTree[0].myUnit.subUnits[0].unitId
					);

					organizeAndSetTreeData(orgTree);

					setDescriptionData({ type: 'TXG', data: txgRes.data.data });
					setDescriptionIsLoading(false);
					break;
				}
			}
		} catch (err) {
			setTreeDataIsLoading(false);
			return Modal.error({
				title: '找不到資料',
				content: '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}
	};

	const organizeAndSetTreeData = (tree: any) => {
		console.log('organizeAndSetTreeData tree:', tree);
		const organizedData = tree.map(({ myUnit }: any) => {
			const { subUnits, unitId, unitName, unitType } = myUnit;
			const organizedObject = {
				title: unitName,
				key: 'qse' + unitId,
				unitId,
				children: [],
				switcherIcon: <FileOutlined />,
				unitType,
				disabled: userRole === Role.TXGAdmin || userRole === Role.TXGUser,
			} as TreeData;

			// 如果該 QSE 下面擁有 TXG，就取得 TXG 資訊
			if (subUnits?.length) {
				const txgArray = subUnits.map((txgUnit: any) => {
					const { subUnits, unitId, unitName, unitType } = txgUnit;

					let resArray = [] as any;
					// 如果該 TXG 下面擁有 RES，就取得 RES 資訊
					if (subUnits?.length) {
						resArray = subUnits.map((resUnit: any) => {
							const { unitId, unitName, unitType } = resUnit;
							return {
								title: unitName,
								key: 'res' + unitId,
								unitId,
								switcherIcon: <FileOutlined />,
								unitType,
							};
						}) as TreeData;
					}

					return {
						title: unitName,
						key: 'txg' + unitId,
						unitId,
						children: resArray,
						switcherIcon: resArray.length ? false : <FileOutlined />,
						unitType,
					};
				});

				organizedObject.children = txgArray;
				organizedObject.switcherIcon = false;
			}

			return organizedObject;
		});
		console.log('organizedData:', organizedData);

		setTreeData(organizedData);
		setTreeDataIsLoading(false);
	};

	const handleTreeSelect = async (selectedKeys: any, e: any) => {
		console.log('DEBUG handleTreeSelect selectedKeys, e', selectedKeys, e);
		const selectedUnitId = e.node.unitId;
		const selectedUnitType = e.node.unitType;

		// 依照 Ant Tree 邏輯，重複點擊已經選取的 Tree item 視作取消選取
		// 因此同一個 item 被點擊第二次時 selectedKeys 會變為空陣列（沒有任何選取的 items）
		// 這時不應該有任何行為。
		if (!selectedUnitId) return;

		setDescriptionIsLoading(true);
		let selectedData: any;

		switch (selectedUnitType) {
			case 'QSE':
				selectedData = qseList.filter(
					(datum: any) => datum.qseId === selectedUnitId
				)[0];
				break;
			case 'TXG': {
				const res = await getSingleTxg(selectedUnitId);
				selectedData = res.data.data;
				break;
			}
			case 'RES': {
				const res = await getSingleRes(selectedUnitId);
				selectedData = res.data.data;
				break;
			}
			default:
				return Modal.error({
					title: '找不到資料',
					content: '請確認網路連線狀態，並重新整理網頁。',
					okText: '確定',
					centered: true,
					closable: true,
					closeIcon: <Icon.ModalClose />,
				});
		}

		console.log('selectedData:', selectedData);
		setDescriptionData({ type: selectedUnitType, data: selectedData });
	};

	const updateOrgTree = async () => {
		console.log('updateOrgTree:');
		const date = dayjs(selectedDate).format('YYYY-MM-DD').valueOf();
		setTreeDataIsLoading(true);

		try {
			const res = await (userRole === Role.SYSAdmin
				? getOrgTree(date)
				: getMineOrgTree(date));
			const orgTree =
				userRole === Role.SYSAdmin ? res.data.list : [res.data.data];

			const isQSEType = [Role.SYSAdmin, Role.QSEAdmin, Role.QSEUser].includes(
				userRole
			);
			const txgRes = await getSingleTxg(orgTree[0].myUnit.subUnits[0].unitId);

			organizeAndSetTreeData(orgTree);
			setDescriptionData({
				type: isQSEType ? 'QSE' : 'TXG',
				data: isQSEType ? qseList[0] : txgRes.data.data,
			});
		} catch (err) {
			setTreeDataIsLoading(false);
			return Modal.error({
				title: '找不到資料',
				content: '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}
	};

	useEffect(() => {
		getInitData();
	}, []);

	useEffect(() => {
		console.log('descriptionData:', descriptionData);

		if (!Object.keys(descriptionData).length) return;

		const newDescriptionData = updateDescriptionData(descriptionData);

		if (newDescriptionData !== null) {
			setDescriptionTable(newDescriptionData);
		}

		setDescriptionIsLoading(false);
	}, [descriptionData]);

	useEffect(() => {
		if (isFirstMount) {
			return;
		}

		if (selectedDate) {
			updateOrgTree();
		}
	}, [selectedDate]);

	return (
		<Card title="組織架構" bordered={false} className="organization-structure">
			<div className="tool-bar">
				<DatePicker
					style={{ width: 210 }}
					onChange={(arg) => setSelectedDate(arg)}
					suffixIcon={<Icon.Day />}
					value={selectedDate}
					placeholder={'開始時間'}
					format={'YYYY-MM-DD'}
				/>
			</div>
			<Row gutter={{ md: 16, lg: 28 }} className="content">
				<Col xs={24} sm={24} md={24} lg={8} xl={6}>
					<div className="tree-part">
						{treeDataIsLoading ? (
							<div className="spinner">
								<Spin size="large" />
							</div>
						) : (
							<Tree
								defaultSelectedKeys={[userJson.unitId || treeData[0]?.key]}
								defaultExpandAll={true}
								showLine={true}
								showIcon={true}
								onSelect={handleTreeSelect}
								treeData={treeData}
							/>
						)}
					</div>
				</Col>
				<Col xs={24} sm={24} md={24} lg={16} xl={18}>
					<div className="description-part">
						{descriptionIsLoading ? (
							<div className="spinner">
								<Spin size="large" />
							</div>
						) : (
							descriptionTable
						)}
					</div>
				</Col>
			</Row>
		</Card>
	);
};

export default OrganizationStructure;
