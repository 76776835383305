import React, { useEffect } from 'react';
import './NotFound.less';

function NotFound() {
	useEffect(() => {
		document.title = 'Not Found - 智慧能源調度系統';
	}, []);

	return (
		<div className="not-found">
			<div>404</div>
			<div>Sorry, the page you visited does not exist.</div>
		</div>
	);
}
export default NotFound;
