import React, { useEffect, useState } from 'react';
import { Card, Col, DatePicker, Modal, Row } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import { TxgData } from '@models/organization';
import { Icon } from '@models/icon';
import dayjs, { Dayjs } from 'dayjs';
import api from '@api/api';
import './MarketAbandonCard.less';

type Props = {
	fieldData: TxgData;
	callFetch: any;
};

type SuspendSchema = {
	suspendStart: Dayjs | null;
	suspendEnd: Dayjs | null;
};

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
	return current && current < dayjs().add(-1, 'day');
};

const MarketAbandonCard = ({ fieldData, callFetch }: Props) => {
	const [isSuspend, setIsSuspend] = useState(false);
	const [isCardOpen, setIsCardOpen] = useState(false);
	const [suspensionData, setSuspensionData] = useState<SuspendSchema>({
		suspendStart: null,
		suspendEnd: null,
	});

	const startTimeHandler = (e: any) => {
		const start = e?.valueOf();
		setSuspensionData((prevState: any) => ({
			...prevState,
			suspendStart: start,
		}));
	};

	const endTimeHandler = (e: any) => {
		const start = e?.valueOf();
		setSuspensionData((prevState: any) => ({
			...prevState,
			suspendEnd: start,
		}));
	};

	const cardAction = (id: any) => {
		if (isSuspend) {
			return [
				<div className="setting" key={id}>
					<div className="setting-disable">中止待命設定</div>
				</div>,
			];
		}

		if (isCardOpen) {
			return [
				<div className="setting" key={id}>
					<div className="action-row">
						<div className="setting-cancel" onClick={cardOpenHandler}>
							取消
						</div>
						<div className="vertical-divider" />
						<div className="setting-enable" onClick={confirmHandler}>
							中止待命
						</div>
					</div>
				</div>,
			];
		}

		return [
			<div className="setting" key={id} onClick={cardOpenHandler}>
				<div className="setting-enable">中止待命設定</div>
			</div>,
		];
	};

	const cardOpenHandler = (e: any) => {
		e.stopPropagation();
		setIsCardOpen(!isCardOpen);
	};

	const confirmHandler = (e: any) => {
		e.stopPropagation();
		modalOpenHandler();
	};

	const modalStatus = {
		startLessThanNinety: {
			title: '中止開始時間需為 90 分鐘後',
			text: <>中止開始時間不可低於現在時間後 90 分鐘內。</>,
		},
		endLessThanStart: {
			title: '中止開始時間須大於中止結束時間',
			text: <>中止開始時間不可小於中止結束時間，且需大於現在時間一小時半後。</>,
		},
		isSpace: {
			title: '時間欄位不可為空',
			text: <>時間欄位不可為空。</>,
		},
		confirmed: {
			title: '確認中止待命',
			text: (
				<>
					<div className="text">交易群組：{fieldData.txgId}</div>
					<div className="text">
						中止待命時段：
						<br />從{' '}
						{dayjs(suspensionData.suspendStart).format('YYYY-MM-DD HH:mm')}{' '}
						<br />至{' '}
						{dayjs(suspensionData.suspendEnd).format('YYYY-MM-DD HH:mm')}
					</div>
				</>
			),
			isConfirmed: true,
		},
	};

	const generateModalConfig = ({
		title = '',
		text = <></>,
		isConfirmed = false,
	}) => {
		return {
			wrapClassName: 'confirm-abandon-modal',
			title: (
				<div className="header">
					<Icon.MessageError className="icon" />
					<div className="title">{title}</div>
				</div>
			),
			content: (
				<div style={{ width: '280px' }}>
					<div className="context">
						<div className="text">{text}</div>
					</div>
				</div>
			),
			centered: true,
			icon: null,
			okText: isConfirmed ? '確定中止' : '確定',
			onOk: () => closeModalHandler(isConfirmed),
			closable: true,
			closeIcon: <Icon.ModalClose />,
			...(isConfirmed && {
				cancelText: '取消',
				onCancel: () => closeModalHandler(),
			}),
		};
	};

	const modalOpenHandler = () => {
		if (!suspensionData.suspendStart || !suspensionData.suspendEnd) {
			return Modal.warning(generateModalConfig({ ...modalStatus['isSpace'] }));
		}

		if (suspensionData.suspendStart >= suspensionData.suspendEnd) {
			return Modal.warning(
				generateModalConfig({ ...modalStatus['endLessThanStart'] })
			);
		}

		if (
			Number(suspensionData.suspendStart) < dayjs().add(85, 'minute').valueOf()
		) {
			return Modal.warning(
				generateModalConfig({ ...modalStatus['startLessThanNinety'] })
			);
		}

		return Modal.confirm(generateModalConfig({ ...modalStatus['confirmed'] }));
	};

	const closeModalHandler = async (status = false) => {
		if (status) {
			const response = await api.post(
				`/webapi/trial/abandon/${fieldData.txgId}`,
				{
					from: dayjs(suspensionData.suspendStart).toJSON(),
					to: dayjs(suspensionData.suspendEnd).toJSON(),
				}
			);

			if (response.data?.status !== 'ok') {
				return Modal.error({
					title: '無法中止待命',
					content: '請確認網路連線狀態，並重新整理網頁。',
					okText: '確定',
					centered: true,
					closable: true,
					closeIcon: <Icon.ModalClose />,
				});
			}

			// setIsSuspend(true);
			callFetch();
			setIsCardOpen(false);
			setSuspensionData({
				suspendStart: null,
				suspendEnd: null,
			});
		}
	};

	useEffect(() => {
		if (fieldData.isSuspend === true) {
			setIsSuspend(true);
		}
	}, [fieldData]);

	return (
		<>
			{!isCardOpen ? (
				<Card className="card-close" actions={cardAction(fieldData.txgId)}>
					<div className="title">{fieldData.name}</div>
					<Row className="content">
						<Col span={7}>
							<div className="text">中止開始</div>
						</Col>
						<Col span={17}>
							<div className="result">
								{!fieldData.suspendStart
									? '-'
									: dayjs(fieldData.suspendStart).format('YYYY-MM-DD HH:mm')}
							</div>
						</Col>
					</Row>
					<Row className="content">
						<Col span={7}>
							<div className="text">中止結束</div>
						</Col>
						<Col span={17}>
							<div className="result">
								{!fieldData.suspendEnd
									? '-'
									: dayjs(fieldData.suspendEnd).format('YYYY-MM-DD HH:mm')}
							</div>
						</Col>
					</Row>
				</Card>
			) : (
				<Card className="card-open" actions={cardAction(fieldData.txgId)}>
					<div className="title">{fieldData.name}</div>
					<div className="setting-group">
						<DatePicker
							className="date-picker"
							suffixIcon={<Icon.Day />}
							format="YYYY-MM-DD HH:mm"
							placeholder="中止待命開始日"
							disabledDate={disabledDate}
							showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm') }}
							onChange={(e) => startTimeHandler(e)}
						/>
						<DatePicker
							className="date-picker"
							suffixIcon={<Icon.Day />}
							format="YYYY-MM-DD HH:mm"
							placeholder="中止待命結束日"
							disabledDate={disabledDate}
							showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm') }}
							onChange={(e) => endTimeHandler(e)}
						/>
					</div>
				</Card>
			)}
		</>
	);
};

export default MarketAbandonCard;
