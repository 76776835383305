import React, { useEffect, useState } from 'react';
import CardTableSwitch from '@components/CardTableSwitch/CardTableSwitch';
import {
	Button,
	Card,
	Col,
	Divider,
	Form,
	Input,
	Modal,
	Pagination,
	Row,
	Select,
	Space,
} from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { Icon } from '@models/icon';
import { ServiceType } from '@models/common';
import api from '@api/api';
import dayjs from 'dayjs';
import './OrganizationTXG.less';

interface TxgListType {
	txgId: string;
	name: string;
	companyId: string;
	txgCode: number;
	qseId: string;
	registerCapacity: number;
	efficiencyPrice: number;
	serviceType: number;
	createTime: number;
	updateTime: number;
}

interface CompanyListType {
	name: string;
	companyId: string;
}

interface QseListType {
	name: string;
	qseId: string;
}

const inputColumns = [
	{
		title: '交易群組ID',
		key: 'txgId',
	},
	{
		title: '交易群組簡稱',
		key: 'name',
	},
	{
		title: '公司簡稱',
		key: 'companyId',
	},
	{
		title: '交易群組代碼',
		key: 'txgCode',
	},
	{
		title: '所屬交易商ID',
		key: 'qseId',
	},
	{
		title: '輔助服務類型',
		key: 'serviceType',
	},
	{
		title: '效能價格',
		key: 'efficiencyPrice',
	},
];

const noneValue = {
	txgId: '',
	name: '',
	companyId: '',
	companyName: '',
	txgCode: null,
	qseId: '',
	registerCapacity: 0,
	efficiencyPrice: null,
	serviceType: null,
	createTime: null,
	updateTime: null,
};

const serviceTypeList = Object.keys(ServiceType)
	.filter((v) => isNaN(Number(v)))
	.map((key, index) => ({ value: index + 1, label: key }));

const getCompanyName = (companyList: CompanyListType[], companyId: string) => {
	const result =
		companyList.filter((company) => company.companyId === companyId)[0]?.name ||
		'';

	console.log('plz check', result);
	return result;
};

const OrganizationTXG = () => {
	const [isCardType, setIsCardType] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [allData, setAllData] = useState([] as TxgListType[]);
	const [displayData, setDisplayData] = useState([] as TxgListType[]);
	const [pageIndex, setPageIndex] = useState(1);
	const [pageSize, setPageSize] = useState(8);

	const [isOpenModal, setIsOpenModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);

	const [companyDropdownIsOpen, setCompanyDropdownIsOpen] = useState(false);
	const [companyDropdownList, setCompanyDropdownList] = useState(
		[] as CompanyListType[]
	);
	const [qseDropdownIsOpen, setQSEDropdownIsOpen] = useState(false);
	const [qseDropdownList, setQseDropdownList] = useState(
		[] as { value: string; label: string }[]
	);
	const [serviceTypeDropdownIsOpen, setServiceTypeDropdownIsOpen] =
		useState(false);

	const [form] = Form.useForm();

	const tableColumns: ColumnsType<TxgListType> = [
		{
			title: '交易群組ID',
			dataIndex: 'txgId',
			key: 'txgId',
			width: '13%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">{record.txgId}</div>
			),
		},
		{
			title: '交易群組簡稱',
			dataIndex: 'name',
			key: 'name',
			width: '13%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">{record.name}</div>
			),
		},
		{
			title: '所屬公司ID',
			dataIndex: 'companyId',
			key: 'companyId',
			width: '13%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">{record.companyId}</div>
			),
		},
		{
			title: '交易群組代碼',
			dataIndex: 'txgCode',
			key: 'txgCode',
			width: '13%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">{record.txgCode}</div>
			),
		},
		{
			title: '所屬交易商ID',
			dataIndex: 'qseId',
			key: 'qseId',
			width: '13%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">{record.qseId}</div>
			),
		},
		{
			title: '註冊容量',
			dataIndex: 'registerCapacity',
			key: 'registerCapacity',
			width: '13%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">
					{new Intl.NumberFormat().format(record.registerCapacity)}
				</div>
			),
		},
		{
			title: '效能價格',
			dataIndex: 'efficiencyPrice',
			key: 'efficiencyPrice',
			width: '13%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">
					{new Intl.NumberFormat().format(record.efficiencyPrice)}
				</div>
			),
		},
		{
			title: '操作',
			key: 'action',
			align: 'center',
			render: (_, record) => (
				<>
					<Icon.Edit
						onClick={() => {
							setIsEdit(true);
							setIsOpenModal(true);
							form.setFieldsValue({ ...record });
						}}
					/>
				</>
			),
		},
	];

	const displayTypeChange = (arg: string) => {
		setIsCardType(arg === 'CARD');
	};

	const getTxgData = async () => {
		setIsLoading(true);
		const response: any = await api.get('/webapi/organization/txg/list');

		if (response.data?.status !== 'ok') {
			return Modal.error({
				title: '找不到資料',
				content: '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		setDisplayData([]);
		setAllData(response.data?.list);
		setIsLoading(false);
	};

	const getCompanyData = async () => {
		const response: any = await api.get('/webapi/system/company/list');

		if (response.data?.status !== 'ok') {
			return;
		}

		setCompanyDropdownList(response.data?.list);
	};

	const getQseData = async () => {
		const response: any = await api.get('/webapi/organization/qse/list');

		if (response.data?.status !== 'ok') {
			return;
		}

		const qseData = response.data?.list;
		const result = qseData.map((qse: QseListType) => ({
			value: qse.qseId,
			label: `[${qse.qseId}] ${qse.name}`,
		}));
		setQseDropdownList(result);
	};

	const validateMessages = {
		required: '請輸入 ${label} ',
	};

	const submitHandler = async (formData: TxgListType) => {
		console.log('submitHandler', formData);

		const requestData = {
			...formData,
			companyName: getCompanyName(companyDropdownList, formData.companyId),
			efficiencyPrice: Number(formData.efficiencyPrice),
		};
		console.log('requestData:', requestData);

		let response;
		if (isEdit) {
			response = await api.put(
				`/webapi/organization/txg/${requestData.txgId}`,
				requestData
			);
		} else {
			response = await api.post(`/webapi/organization/txg`, requestData);
		}

		if (response.data?.status !== 'ok') {
			return Modal.error({
				title: `${isEdit ? '更新' : '新增'}失敗`,
				content:
					response.data?.err?.msg || '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		Modal.success({
			title: `${isEdit ? '更新' : '新增'}成功`,
			okText: '確定',
			centered: true,
			closable: true,
			closeIcon: <Icon.ModalClose />,
		});

		getTxgData();
		setIsOpenModal(false);
	};

	useEffect(() => {
		getTxgData();
		getCompanyData();
		getQseData();
	}, []);

	useEffect(() => {
		setPageSize(isCardType ? 8 : 10);
		setPageIndex(1);
	}, [isCardType]);

	useEffect(() => {
		if (pageIndex && pageSize && allData.length > 0) {
			const startIndex = (pageIndex - 1) * pageSize;
			const endIndex = Math.min(startIndex + pageSize - 1, allData.length - 1);

			setDisplayData(allData.slice(startIndex, endIndex + 1));
		}
	}, [pageIndex, pageSize, allData]);

	return (
		<Card
			title="交易群組 (TXG) 管理"
			className="organization-txg"
			bordered={false}
			extra={
				<Button
					type="primary"
					onClick={() => {
						setIsEdit(false);
						setIsOpenModal(true);
						form.setFieldsValue({ ...noneValue });
					}}
				>
					新增
				</Button>
			}
		>
			<CardTableSwitch displayTypeChange={displayTypeChange} />
			{isCardType ? (
				<Row gutter={[20, 28]} className="data-card">
					{displayData.map((txg, idx) => {
						return (
							<Col sm={24} md={12} lg={6} key={txg.txgId}>
								<Card
									key={idx}
									actions={[
										<div
											className="setting"
											key={txg.qseId}
											onClick={() => {
												setIsEdit(true);
												setIsOpenModal(true);
												form.setFieldsValue({ ...txg });
											}}
										>
											編輯
										</div>,
									]}
								>
									<div className="title">{txg.name}</div>
									<Row className="content">
										<Col span={10}>
											<div className="text">交易群組ID</div>
										</Col>
										<Col span={14}>
											<div className="result ellipsis">{txg.txgId}</div>
										</Col>
									</Row>
									<Row className="content">
										<Col span={10}>
											<div className="text">交易群組簡稱</div>
										</Col>
										<Col span={14}>
											<div className="result ellipsis">{txg.name}</div>
										</Col>
									</Row>
									<Row className="content">
										<Col span={10}>
											<div className="text">所屬公司ID</div>
										</Col>
										<Col span={14}>
											<div className="result ellipsis">{txg.companyId}</div>
										</Col>
									</Row>
									<Row className="content">
										<Col span={10}>
											<div className="text">交易群組代碼</div>
										</Col>
										<Col span={14}>
											<div className="result ellipsis">{txg.txgCode}</div>
										</Col>
									</Row>
									<Row className="content">
										<Col span={10}>
											<div className="text">所屬交易商ID</div>
										</Col>
										<Col span={14}>
											<div className="result ellipsis">{txg.qseId}</div>
										</Col>
									</Row>
									<Row className="highlight-content">
										<Col>
											<div className="highlight-content-text">註冊容量</div>
											<div className="highlight-content-result">
												{new Intl.NumberFormat().format(txg.registerCapacity)}
											</div>
										</Col>
										<Divider
											type="vertical"
											className="highlight-content-divider"
										/>
										<Col>
											<div className="highlight-content-text">效能價格</div>
											<div className="highlight-content-result">
												{new Intl.NumberFormat().format(txg.efficiencyPrice)}
											</div>
										</Col>
									</Row>
								</Card>
							</Col>
						);
					})}
				</Row>
			) : (
				<div className="data-table">
					<Table
						columns={tableColumns}
						dataSource={displayData}
						pagination={false}
						loading={isLoading}
						rowKey={(record) => record.txgId}
					/>
				</div>
			)}
			{allData.length > 0 ? (
				<div className="pagination-bar">
					<div className="right">
						<Pagination
							total={allData.length}
							current={pageIndex}
							onChange={(page, size) => {
								setPageIndex(page);
								setPageSize(size);
							}}
							showSizeChanger
							showQuickJumper
							defaultPageSize={isCardType ? 8 : 10}
							pageSize={pageSize}
							pageSizeOptions={
								isCardType ? ['8', '16', '24'] : ['10', '20', '30']
							}
							locale={{
								items_per_page: ' / page',
								jump_to: 'Go to ',
								page: '',
							}}
							showTotal={(total) => `Total ${total} items`}
						/>
					</div>
				</div>
			) : null}
			<Modal
				title={`${isEdit ? '編輯' : '新增'}交易群組 (QSE)`}
				open={isOpenModal}
				centered
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				width={420}
				className="form-modal"
				closeIcon={<Icon.ModalClose />}
				maskClosable={false}
				destroyOnClose={true}
				forceRender
				onCancel={() => {
					setIsOpenModal(false);
					form.setFieldsValue({ ...noneValue });
				}}
			>
				<Space direction="vertical" size="middle" style={{ display: 'flex' }}>
					<Form
						labelCol={{ span: 7 }}
						wrapperCol={{ span: 17 }}
						form={form}
						labelAlign="left"
						colon={false}
						onFinish={submitHandler}
						validateMessages={validateMessages}
					>
						{inputColumns.map((i, idx) => {
							return (
								<div key={idx}>
									{i.key === 'companyId' ? (
										<Form.Item label={i.title} required>
											<Row gutter={[8, 0]}>
												<Col span={12}>
													<Form.Item
														name="companyId"
														noStyle
														rules={[
															{ required: true, message: '請輸入 公司簡稱' },
														]}
													>
														<Select
															style={{ width: '100%' }}
															placeholder="請選擇"
															onChange={(e) =>
																form.setFieldValue('companyId', e)
															}
															onDropdownVisibleChange={() => {
																setCompanyDropdownIsOpen(
																	(prevState: boolean) => !prevState
																);
															}}
															suffixIcon={
																companyDropdownIsOpen ? (
																	<Icon.UpArrow />
																) : (
																	<Icon.DownArrow />
																)
															}
															options={companyDropdownList.map((item) => ({
																value: item.companyId,
																label: item.name,
															}))}
														/>
													</Form.Item>
												</Col>
												<Col span={12}>
													<Form.Item name="companyId" noStyle>
														<Input disabled={true} placeholder="所屬公司ID" />
													</Form.Item>
												</Col>
											</Row>
										</Form.Item>
									) : i.key === 'serviceType' || i.key === 'qseId' ? (
										<Form.Item
											name={i.key}
											label={i.title}
											rules={[{ required: true }]}
										>
											{i.key === 'serviceType' ? (
												<Select
													placeholder="請選擇"
													options={serviceTypeList}
													style={{ width: '100%' }}
													onDropdownVisibleChange={() => {
														setServiceTypeDropdownIsOpen(
															(prevState: boolean) => !prevState
														);
													}}
													suffixIcon={
														serviceTypeDropdownIsOpen ? (
															<Icon.UpArrow />
														) : (
															<Icon.DownArrow />
														)
													}
												/>
											) : (
												<Select
													placeholder="請選擇"
													options={qseDropdownList}
													style={{ width: '100%' }}
													onDropdownVisibleChange={() => {
														setQSEDropdownIsOpen(
															(prevState: boolean) => !prevState
														);
													}}
													suffixIcon={
														qseDropdownIsOpen ? (
															<Icon.UpArrow />
														) : (
															<Icon.DownArrow />
														)
													}
												/>
											)}
										</Form.Item>
									) : (
										<Form.Item
											name={i.key}
											label={i.title}
											rules={
												i.key === 'txgCode' || i.key === 'efficiencyPrice'
													? [
															{ required: true },
															{
																pattern: /^[0-9]*$/g,
																message: `僅輸入數字`,
															},
													  ]
													: [{ required: true }]
											}
										>
											<Input
												disabled={isEdit && i.key === 'txgId' ? true : false}
											/>
										</Form.Item>
									)}
								</div>
							);
						})}
						<Form.Item
							name="registerCapacity"
							label="registerCapacity"
							hidden={true}
						>
							<Input />
						</Form.Item>
						{isEdit ? (
							<div className="time-area">
								<div className="time-info">
									資料建立時間：
									{dayjs(form.getFieldValue('createTime')).format(
										'YYYY-MM-DD HH:mm:ss'
									)}
								</div>
								<div className="time-info">
									資料更新時間：
									{dayjs(form.getFieldValue('updateTime')).format(
										'YYYY-MM-DD HH:mm:ss'
									)}
								</div>
							</div>
						) : null}

						<Button type="primary" htmlType="submit" className="submit-btn">
							{isEdit ? '更新' : '新增'}
						</Button>
					</Form>
				</Space>
			</Modal>
		</Card>
	);
};

export default OrganizationTXG;
