import React, { useEffect, useState } from 'react';
import type { AppDispatch } from 'src';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { logOut } from '@store/reducers/userSlice';
import {
	Navigate,
	BrowserRouter,
	Routes,
	Route,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import { Role } from '@models/user';
import { menuItems, PATHS } from '@pages/AuthMainLayout/Menu';
import { flatten } from 'lodash';
import jwt_decode from 'jwt-decode';

import NoAuth from '@components/NoAuth/NoAuth';
import NotFound from '@components/NotFound/NotFound';
import AuthMainLayout from '@pages/AuthMainLayout/AuthMainLayout';
import Login from '@pages/Login/Login';
import MarketCompetition from '@pages/MarketCompetition/MarketCompetition';
import MarketAbandon from '@pages/MarketAbandon/MarketAbandon';
import MarketFixData from '@pages/MarketFixData/MarketFixData';
import MarketPriceInfo from '@pages/MarketPriceInfo/MarketPriceInfo';
import MarketEnergyDownload from '@pages/MarketEnergyDownload/MarketEnergyDownload';
import OperatingOverview from '@pages/OperatingOverview/OperatingOverview';
import OperatingService from '@pages/OperatingService/OperatingService';
import OperatingDevice from '@pages/OperatingDevice/OperatingDevice';
import PerformanceExpense from '@pages/PerformanceExpense/PerformanceExpense';
import PerformanceAwarded from '@pages/PerformanceAwarded/PerformanceAwarded';
import PerformanceImplement from '@pages/PerformanceImplement/PerformanceImplement';
import OrganizationQSE from '@pages/OrganizationQSE/OrganizationQSE';
import OrganizationTXG from '@pages/OrganizationTXG/OrganizationTXG';
import OrganizationRES from '@pages/OrganizationRES/OrganizationRES';
import OrganizationDEV from '@pages/OrganizationDEV/OrganizationDEV';
import OrganizationStructure from '@pages/OrganizationStructure/OrganizationStructure';
import SystemManageUser from '@pages/SystemManageUser/SystemManageUser';
import SystemManageCompany from '@pages/SystemManageCompany/SystemManageCompany';
import UserEdit from '@pages/UserEdit/UserEdit';
import MaintenanceDispatch from '@pages/MaintenanceDispatch/MaintenanceDispatch';

function PublicRoute(props: { children: any }) {
	const [user, setUser] = useState(
		localStorage.getItem('energy-dispatch-user') as string
	);

	if (user) {
		return <Navigate to="/" />;
	}

	useEffect(() => {
		document.title = '登入 - 智慧能源調度系統';
	}, []);

	return props.children;
}

function PrivateRoute(props: { children: any }) {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const [user, setUser] = useState(
		localStorage.getItem('energy-dispatch-user') as string
	);
	const location = useLocation();

	if (!user) {
		return <Navigate to="/login" />;
	}

	useEffect(() => {
		console.log('location:', location);
		const menus = flatten(menuItems.map((m) => m.children));
		menus.push({
			label: '首頁',
			key: '',
			auth: [
				Role.SYSAdmin,
				Role.QSEAdmin,
				Role.QSEUser,
				Role.TXGAdmin,
				Role.TXGUser,
				Role.RESAdmin,
				Role.RESUser,
			],
		});
		menus.push({
			label: '使用者資訊',
			key: PATHS.SYSTEM.USEREDIT,
			auth: [
				Role.SYSAdmin,
				Role.QSEAdmin,
				Role.QSEUser,
				Role.TXGAdmin,
				Role.TXGUser,
				Role.RESAdmin,
				Role.RESUser,
			],
		});
		console.log('menus:', menus);

		const nowItem = menus.filter((m) => '/' + m.key === location.pathname)[0];
		console.log('nowItem:', nowItem);
		if (user) {
			const userJson = JSON.parse(user);
			const role = userJson.authority[0] ?? 1;
			if (!nowItem.auth.includes(role)) {
				return navigate('/no-auth');
			}
		}

		document.title =
			location.pathname === '/'
				? '智慧能源調度系統'
				: menus.filter((m) => '/' + m.key === location.pathname)[0].label +
				  ' - 智慧能源調度系統';

		if (user) {
			const userJson = JSON.parse(user);
			const decoded: { exp: number } = jwt_decode(userJson.token);
			console.log('decoded:', decoded);

			if (decoded.exp * 1000 < Date.now()) {
				dispatch(logOut())
					.then(unwrapResult)
					.then(async () => {
						navigate('/login');
					});
			}
		}
	}, [location]);

	return props.children;
}

function AppRouter() {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route
						path="/login"
						element={
							<PublicRoute>
								<Login />
							</PublicRoute>
						}
					/>

					<Route
						path="/"
						element={
							<PrivateRoute>
								<AuthMainLayout />
							</PrivateRoute>
						}
					>
						<Route path="/" element={<OperatingService />} />

						{/* Market */}
						<Route
							path={PATHS.MARKET.COMPETITION}
							element={<MarketCompetition />}
						/>
						<Route path={PATHS.MARKET.ABANDON} element={<MarketAbandon />} />
						<Route path={PATHS.MARKET.FIXDATA} element={<MarketFixData />} />
						<Route
							path={PATHS.MARKET.PRICEINFO}
							element={<MarketPriceInfo />}
						/>
						<Route
							path={PATHS.MARKET.ENERGYDOWNLOAD}
							element={<MarketEnergyDownload />}
						/>
						{/* Operating */}
						<Route
							path={PATHS.OPERATING.OVERVIEW}
							element={<OperatingOverview />}
						/>
						<Route
							path={PATHS.OPERATING.SERVICE}
							element={<OperatingService />}
						/>
						<Route
							path={PATHS.OPERATING.DEVICE}
							element={<OperatingDevice />}
						/>
						{/* Performance */}
						<Route
							path={PATHS.PERFORMANCE.EXPENSE}
							element={<PerformanceExpense />}
						/>
						<Route
							path={PATHS.PERFORMANCE.AWARDED}
							element={<PerformanceAwarded />}
						/>
						<Route
							path={PATHS.PERFORMANCE.IMPLEMENT}
							element={<PerformanceImplement />}
						/>
						{/* Organization */}
						<Route
							path={PATHS.ORGANIZATION.QSE}
							element={<OrganizationQSE />}
						/>
						<Route
							path={PATHS.ORGANIZATION.TXG}
							element={<OrganizationTXG />}
						/>
						<Route
							path={PATHS.ORGANIZATION.RES}
							element={<OrganizationRES />}
						/>
						<Route
							path={PATHS.ORGANIZATION.DEV}
							element={<OrganizationDEV />}
						/>
						<Route
							path={PATHS.ORGANIZATION.STRUCTURE}
							element={<OrganizationStructure />}
						/>
						{/* System */}
						<Route path={PATHS.SYSTEM.USER} element={<SystemManageUser />} />
						<Route
							path={PATHS.SYSTEM.COMPANY}
							element={<SystemManageCompany />}
						/>
						<Route path={PATHS.SYSTEM.USEREDIT} element={<UserEdit />} />
						{/* Maintain */}
						{/* <Route
							path={PATHS.MAINTENANCE.RECORD}
							element={<SystemManageUser />}
						/>
						<Route
							path={PATHS.MAINTENANCE.DISPATCH}
							element={<MaintenanceDispatch />}
						/>
						<Route path={PATHS.SYSTEM.USEREDIT} element={<UserEdit />} />
						<Route path={PATHS.SYSTEM.USEREDIT} element={<UserEdit />} />
						<Route path={PATHS.SYSTEM.USEREDIT} element={<UserEdit />} /> */}
					</Route>

					<Route path="/no-auth" element={<NoAuth />} />

					<Route path="*" element={<NotFound />} />
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default AppRouter;
