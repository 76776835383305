import React, { useEffect, useState } from 'react';
import CardTableSwitch from '@components/CardTableSwitch/CardTableSwitch';
import NumberInput from '@components/CustomNumberInput/CustomNumberInput';
import {
	Button,
	Card,
	Col,
	Divider,
	Form,
	Input,
	InputNumber,
	Modal,
	Pagination,
	Row,
	Select,
	Space,
	Switch,
	Tag,
} from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { ResourceType } from '@models/common';
import {
	CompanyListType,
	ConnectionStatus,
	getCompanyName,
} from '@models/organization';
import { Icon } from '@models/icon';
import dayjs from 'dayjs';
import api from '@api/api';
import './OrganizationRES.less';

interface ResListType {
	resId: string;
	txgId: string;
	name: string;
	resCode: number;
	resType: number;
	accFactor: number;
	registerCapacity: number;
	tcEnable: string;
	tcUrl: string;
	companyId: string;
	createTimestamp: number;
	updateTimestamp: number;
	tcLastUploadTime: null | number;
	devStatus?: string;
}

const noneValue = {
	accFactor: 0,
	companyId: '',
	companyName: '',
	name: '',
	registerCapacity: null,
	resCode: null,
	resId: '',
	resType: null,
	tcEnable: '',
	tcUrl: '',
	txgId: '',
	createTimestamp: null,
	updateTimestamp: null,
	tcLastUploadTime: null,
};

const resourceTypeList = Object.keys(ResourceType)
	.filter((v) => isNaN(Number(v)))
	.map((key, index) => ({ value: index + 1, label: key }));

const OrganizationRES = () => {
	const [isCardType, setIsCardType] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [allData, setAllData] = useState([] as any[]);
	const [displayData, setDisplayData] = useState([] as any[]);
	const [pageIndex, setPageIndex] = useState(1);
	const [pageSize, setPageSize] = useState(8);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);

	const [companyDropdownIsOpen, setCompanyDropdownIsOpen] = useState(false);
	const [companyDropdownList, setCompanyDropdownList] = useState(
		[] as CompanyListType[]
	);
	const [txgDropdownIsOpen, setTxgDropdownIsOpen] = useState(false);
	const [txgDropdownList, setTxgDropdownList] = useState(
		[] as { value: string; label: string }[]
	);
	const [resTypeDropdownIsOpen, setResTypeDropdownIsOpen] = useState(false);
	const [form] = Form.useForm();

	const tableColumns: ColumnsType<ResListType> = [
		{
			title: '場域ID',
			dataIndex: 'resId',
			key: 'txgId',
			width: '15%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">{record.resId}</div>
			),
		},
		{
			title: '場域簡稱',
			dataIndex: 'name',
			key: 'name',
			width: '15%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">{record.name}</div>
			),
		},
		{
			title: '所屬交易群組ID',
			dataIndex: 'txgId',
			key: 'txgId',
			width: '15%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">{record.txgId}</div>
			),
		},
		{
			title: '所屬公司ID',
			dataIndex: 'companyId',
			key: 'companyId',
			width: '15%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">{record.companyId}</div>
			),
		},
		{
			title: '場域誤差因子',
			dataIndex: 'accFactor',
			key: 'accFactor',
			width: '15%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">{record.accFactor}</div>
			),
		},
		{
			title: '註冊容量',
			dataIndex: 'registerCapacity',
			key: 'registerCapacity',
			width: '15%',
			align: 'center',
			render: (_, record) => (
				<div className="table-ellipsis">
					{new Intl.NumberFormat().format(record.registerCapacity)}
				</div>
			),
		},
		{
			title: '操作',
			key: 'action',
			align: 'center',
			render: (_, record) => (
				<>
					<Icon.Edit
						onClick={() => {
							setIsEdit(true);
							setIsOpenModal(true);
							form.setFieldsValue({
								...record,
								tcEnable: record.tcEnable === 'enable',
							});
						}}
					/>
				</>
			),
		},
	];

	const validateMessages = {
		required: '請輸入 ${label} ',
	};

	const displayTypeChange = (arg: string) => {
		setIsCardType(arg === 'CARD');
	};

	const getResData = async () => {
		setIsLoading(true);
		const response: any = await api.get('/webapi/organization/res/list?txgId');
		if (response.data?.status !== 'ok') {
			return Modal.error({
				title: '找不到資料',
				content: '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		setDisplayData([]);
		setAllData(response.data?.list);
		setIsLoading(false);
	};

	const getTxgData = async () => {
		const response: any = await api.get('/webapi/organization/txg/list');
		if (response.data?.status !== 'ok') {
			return;
		}

		const txgListData = response.data?.list.map((txg: any) => {
			return {
				value: txg.txgId,
				label: `[${txg.txgId}] ${txg.name}`,
			};
		});
		setTxgDropdownList(txgListData);
	};

	const getCompanyData = async () => {
		const response: any = await api.get('/webapi/system/company/list');
		if (response.data?.status !== 'ok') {
			return;
		}

		setCompanyDropdownList(response.data?.list);
	};

	const submitHandler = async (formData: ResListType) => {
		console.log('submitHandler', formData);
		const accFactor = `${formData.accFactor}`;
		const requestData = {
			...formData,
			companyName: getCompanyName(companyDropdownList, formData.companyId),
			tcEnable: formData.tcEnable ? 'enable' : 'disable',
			accFactor:
				accFactor.indexOf('.') == -1
					? formData.accFactor
					: Number(accFactor.substring(0, accFactor.indexOf('.') + 3)),
		};
		delete requestData.devStatus;
		console.log('requestData:', requestData);

		let response;
		if (isEdit) {
			response = await api.put(
				`/webapi/organization/res/${requestData.resId}`,
				requestData
			);
		} else {
			response = await api.post(`/webapi/organization/res`, requestData);
		}

		if (response.data?.status !== 'ok') {
			return Modal.error({
				title: `${isEdit ? '更新' : '新增'}失敗`,
				content:
					response.data?.err?.msg || '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		Modal.success({
			title: `${isEdit ? '更新' : '新增'}成功`,
			okText: '確定',
			centered: true,
			closable: true,
			closeIcon: <Icon.ModalClose />,
		});

		getResData();
		setIsOpenModal(false);
	};

	useEffect(() => {
		getResData();
		getCompanyData();
		getTxgData();
	}, []);

	useEffect(() => {
		setPageSize(isCardType ? 8 : 10);
		setPageIndex(1);
	}, [isCardType]);

	useEffect(() => {
		if (pageIndex && pageSize && allData.length > 0) {
			const startIndex = (pageIndex - 1) * pageSize;
			const endIndex = Math.min(startIndex + pageSize - 1, allData.length - 1);
			setDisplayData(allData.slice(startIndex, endIndex + 1));
		}
	}, [pageIndex, pageSize, allData]);

	return (
		<Card
			title="場域 (RES) 管理"
			className="organization-res"
			bordered={false}
			extra={
				<Button
					type="primary"
					onClick={() => {
						setIsEdit(false);
						setIsOpenModal(true);
						form.setFieldsValue({ ...noneValue });
					}}
				>
					新增
				</Button>
			}
		>
			<CardTableSwitch displayTypeChange={displayTypeChange} />
			{isCardType ? (
				<Row gutter={[20, 28]} className="data-card">
					{displayData.map((res, idx) => {
						return (
							<Col sm={24} md={12} lg={6} key={idx}>
								<Card
									className="card"
									key={idx}
									actions={[
										<div
											className="setting"
											key={res.resId}
											onClick={() => {
												setIsEdit(true);
												setIsOpenModal(true);
												form.setFieldsValue({
													...res,
													tcEnable: res.tcEnable === 'enable',
												});
											}}
										>
											編輯
										</div>,
									]}
								>
									<div className="title">{res.name}</div>
									<Row className="content">
										<Col span={11}>
											<div className="text">場域ID</div>
										</Col>
										<Col span={13}>
											<div className="result ellipsis">{res.resId}</div>
										</Col>
									</Row>
									<Row className="content">
										<Col span={11}>
											<div className="text">場域代碼</div>
										</Col>
										<Col span={13}>
											<div className="result ellipsis">{res.resCode}</div>
										</Col>
									</Row>
									<Row className="content">
										<Col span={11}>
											<div className="text">所屬交易群組ID</div>
										</Col>
										<Col span={13}>
											<div className="result ellipsis">{res.txgId}</div>
										</Col>
									</Row>
									<Row className="content">
										<Col span={11}>
											<div className="text">所屬公司ID</div>
										</Col>
										<Col span={13}>
											<div className="result ellipsis">{res.companyId}</div>
										</Col>
									</Row>
									<Row className="highlight-content">
										<Col>
											<div className="highlight-content-text">場域誤差因子</div>
											<div className="highlight-content-result">
												{new Intl.NumberFormat().format(res.accFactor)}
											</div>
										</Col>
										<Divider
											type="vertical"
											className="highlight-content-divider"
										/>
										<Col>
											<div className="highlight-content-text">註冊容量</div>
											<div className="highlight-content-result">
												{new Intl.NumberFormat().format(res.registerCapacity)}
											</div>
										</Col>
									</Row>
								</Card>
							</Col>
						);
					})}
				</Row>
			) : (
				<div className="data-table">
					<Table
						columns={tableColumns}
						dataSource={displayData}
						pagination={false}
						loading={isLoading}
						rowKey={(record) => record.resId}
					/>
				</div>
			)}
			{allData.length > 0 ? (
				<div className="pagination-bar">
					<div className="right">
						<Pagination
							total={allData.length}
							current={pageIndex}
							onChange={(page, size) => {
								setPageIndex(page);
								setPageSize(size);
							}}
							showSizeChanger
							showQuickJumper
							defaultPageSize={isCardType ? 8 : 10}
							pageSize={pageSize}
							pageSizeOptions={
								isCardType ? ['8', '16', '24'] : ['10', '20', '30']
							}
							locale={{
								items_per_page: ' / page',
								jump_to: 'Go to ',
								page: '',
							}}
							showTotal={(total) => `Total ${total} items`}
						/>
					</div>
				</div>
			) : null}
			<Modal
				title={isEdit ? '場域編輯' : '新增場域 (RES)'}
				open={isOpenModal}
				centered
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				width={420}
				className="form-modal"
				closeIcon={<Icon.ModalClose />}
				destroyOnClose={true}
				forceRender
				onCancel={() => {
					setIsOpenModal(false);
					form.setFieldsValue({ ...noneValue });
				}}
			>
				<Space direction="vertical" size="middle" style={{ display: 'flex' }}>
					<Form
						labelCol={{ span: 8 }}
						wrapperCol={{ span: 16 }}
						form={form}
						labelAlign="left"
						colon={false}
						onFinish={submitHandler}
						validateMessages={validateMessages}
					>
						<Form.Item name="resId" label="場域ID" rules={[{ required: true }]}>
							<Input disabled={isEdit} />
						</Form.Item>
						<Form.Item
							name="name"
							label="場域簡稱"
							rules={[{ required: true }]}
						>
							<Input />
						</Form.Item>
						<Form.Item label="公司簡稱" required>
							<Row gutter={[8, 0]}>
								<Col span={12}>
									<Form.Item
										name="companyId"
										noStyle
										rules={[{ required: true, message: '請輸入 公司簡稱' }]}
									>
										<Select
											style={{ width: '100%' }}
											placeholder="請選擇"
											onChange={(e) => form.setFieldValue('companyId', e)}
											onDropdownVisibleChange={() => {
												setCompanyDropdownIsOpen(
													(prevState: boolean) => !prevState
												);
											}}
											suffixIcon={
												companyDropdownIsOpen ? (
													<Icon.UpArrow />
												) : (
													<Icon.DownArrow />
												)
											}
											options={companyDropdownList.map((item) => ({
												value: item.companyId,
												label: item.name,
											}))}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item name="companyId" noStyle>
										<Input disabled={true} placeholder="所屬公司ID" />
									</Form.Item>
								</Col>
							</Row>
						</Form.Item>
						<Form.Item
							name="resCode"
							label="場域代碼"
							rules={[
								{ required: true },
								{
									pattern: /^[0-9]*$/g,
									message: `僅輸入數字`,
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name="txgId"
							label="所屬交易群組ID"
							rules={[{ required: true }]}
						>
							<Select
								options={txgDropdownList}
								placeholder="請選擇"
								style={{ width: '100%' }}
								onDropdownVisibleChange={() => {
									setTxgDropdownIsOpen((prevState: boolean) => !prevState);
								}}
								suffixIcon={
									txgDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
								}
							/>
						</Form.Item>
						<Form.Item
							name="resType"
							label="資源類型"
							rules={[{ required: true }]}
						>
							<Select
								options={resourceTypeList}
								style={{ width: '100%' }}
								placeholder="請選擇"
								onDropdownVisibleChange={() => {
									setResTypeDropdownIsOpen((prevState: boolean) => !prevState);
								}}
								suffixIcon={
									resTypeDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
								}
							/>
						</Form.Item>
						<Form.Item
							name="accFactor"
							label="場域誤差因子"
							rules={[{ required: true }]}
						>
							<NumberInput min={0} max={1} step={0.1} precision={1} />
						</Form.Item>
						<Form.Item
							name="registerCapacity"
							label="註冊容量 (kW)"
							rules={[
								{ required: true },
								{
									pattern: /^[0-9]*$/g,
									message: `僅輸入數字`,
								},
							]}
						>
							<InputNumber
								formatter={(value) =>
									`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
								}
								parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
							/>
						</Form.Item>
						<Form.Item
							name="tcUrl"
							label="ThinClient URL"
							rules={[{ required: true }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name="tcEnable"
							label="ThinClient啟用"
							valuePropName="checked"
						>
							<Switch />
						</Form.Item>
						{isEdit ? (
							<>
								<Form.Item name="devStatus" label="場域連線狀態">
									<Tag
										color={
											ConnectionStatus.filter(
												(c) => c.value === form.getFieldValue('devStatus')
											)[0].color
										}
									>
										{
											ConnectionStatus.filter(
												(c) => c.value === form.getFieldValue('devStatus')
											)[0].text
										}
									</Tag>
								</Form.Item>

								<div className="time-area">
									<div className="tc-upload">
										ThinClient 最後上傳資料時間：
										{form.getFieldValue('tcLastUploadTime')
											? dayjs(form.getFieldValue('tcLastUploadTime')).format(
													'YYYY-MM-DD HH:mm:ss'
											  )
											: '無資料'}
									</div>

									<div className="time-info">
										資料建立時間：
										{dayjs(form.getFieldValue('createTimestamp')).format(
											'YYYY-MM-DD HH:mm:ss'
										)}
									</div>
									<div className="time-info">
										資料更新時間：
										{dayjs(form.getFieldValue('updateTimestamp')).format(
											'YYYY-MM-DD HH:mm:ss'
										)}
									</div>
								</div>
							</>
						) : null}
						<Button type="primary" htmlType="submit" className="submit-btn">
							{isEdit ? '更新' : '新增'}
						</Button>
					</Form>
				</Space>
			</Modal>
		</Card>
	);
};

export default OrganizationRES;
