import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input, Modal, Pagination, Space } from 'antd';
import Table from 'antd/es/table';
import Column from 'antd/es/table/Column';
import { Icon } from '@models/icon';
import api from '@api/api';
import dayjs from 'dayjs';
import './SystemManageCompany.less';

interface TableDataType {
	key: React.Key;
	companyId: string;
	fullName: string;
	name: string;
	address: string;
	phone: string;
	contractPerson: string;
	createTime: number;
	updateTime: number;
}

const SystemManageCompany = () => {
	const [allTableData, setAllTableData] = useState([] as TableDataType[]);
	const [displayTableData, setDisplayTableData] = useState(
		[] as TableDataType[]
	);
	const [pageIndex, setPageIndex] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [isLoading, setIsLoading] = useState(false);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);

	const [form] = Form.useForm();

	const columns = [
		{
			title: '公司ID',
			key: 'companyId',
		},
		{
			title: '公司全名',
			key: 'fullName',
		},
		{
			title: '公司簡稱',
			key: 'name',
		},
		{
			title: '公司地址',
			key: 'address',
		},
		{
			title: '公司聯絡電話',
			key: 'phone',
		},
		{
			title: '公司聯絡人',
			key: 'contractPerson',
		},
	];

	const noneValue = {
		companyId: '',
		fullName: '',
		name: '',
		address: '',
		phone: '',
		contractPerson: '',
		createTime: 0,
		updateTime: 0,
	};

	const validateMessages = {
		required: '請輸入 ${label} ',
	};

	const getTableData = async () => {
		setIsLoading(true);
		const response: any = await api.get('/webapi/system/company/list');
		if (response.data?.status !== 'ok') {
			return Modal.error({
				title: '找不到資料',
				content: '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		setDisplayTableData([]);
		setAllTableData(response.data?.list);
		setIsLoading(false);
	};

	const submitHandler = async (requestData: TableDataType) => {
		let response;
		if (isEdit) {
			response = await api.put(`/webapi/system/company`, requestData);
		} else {
			response = await api.post(`/webapi/system/company`, requestData);
		}

		if (response.data?.status !== 'ok') {
			return Modal.error({
				title: `${isEdit ? '更新' : '新增'}失敗`,
				content:
					response.data?.err?.msg || '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		Modal.success({
			title: `${isEdit ? '更新' : '新增'}成功`,
			okText: '確定',
			centered: true,
			closable: true,
			closeIcon: <Icon.ModalClose />,
		});

		getTableData();
		setIsOpenModal(false);
	};

	useEffect(() => {
		getTableData();
	}, []);

	useEffect(() => {
		if (pageIndex && pageSize && allTableData.length > 0) {
			const startIndex = (pageIndex - 1) * pageSize;
			const endIndex = Math.min(
				startIndex + pageSize - 1,
				allTableData.length - 1
			);

			setDisplayTableData(allTableData.slice(startIndex, endIndex + 1));
		}
	}, [pageIndex, pageSize, allTableData]);

	return (
		<>
			<Card
				title="公司管理"
				extra={
					<Button
						type="primary"
						onClick={() => {
							setIsEdit(false);
							setIsOpenModal(true);
							form.setFieldsValue({ ...noneValue });
						}}
					>
						新增
					</Button>
				}
				className="system-manage-company"
			>
				<div className="data-table">
					<Table
						pagination={false}
						loading={isLoading}
						dataSource={displayTableData}
						rowKey="id"
					>
						{columns.map((col) => {
							const { title, key } = col;
							return (
								<Column
									title={title}
									width={'14.285%'}
									dataIndex={key}
									key={key}
									align="center"
									render={(_: any, record: any) => (
										<div className="table-ellipsis">{record[key]}</div>
									)}
								/>
							);
						})}
						<Column
							title="操作"
							width={'14.285%'}
							key="action"
							align="center"
							render={(_: any, record: TableDataType) => (
								<Icon.Edit
									className="edit-company"
									onClick={() => {
										setIsEdit(true);
										setIsOpenModal(true);
										form.setFieldsValue({ ...record });
									}}
								/>
							)}
						/>
					</Table>
				</div>
				{allTableData.length > 0 ? (
					<div className="pagination-bar">
						<div className="right">
							<Pagination
								total={allTableData.length}
								current={pageIndex}
								onChange={(page, size) => {
									setPageIndex(page);
									setPageSize(size);
								}}
								showSizeChanger
								showQuickJumper
								defaultPageSize={10}
								pageSize={pageSize}
								pageSizeOptions={['10', '20', '30']}
								locale={{
									items_per_page: ' / page',
									jump_to: 'Go to ',
									page: '',
								}}
								showTotal={(total) => `Total ${total} items`}
							/>
						</div>
					</div>
				) : (
					''
				)}
			</Card>
			<Modal
				title={`${isEdit ? '編輯' : '新增'}公司`}
				open={isOpenModal}
				centered
				forceRender
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				width={380}
				className="form-modal"
				maskClosable={false}
				destroyOnClose={true}
				onCancel={() => {
					setIsOpenModal(false);
					form.setFieldsValue({ ...noneValue });
				}}
				closeIcon={<Icon.ModalClose />}
			>
				<Space direction="vertical" size="middle" style={{ display: 'flex' }}>
					<Form
						labelCol={{ span: 9 }}
						wrapperCol={{ span: 15 }}
						form={form}
						labelAlign="left"
						colon={false}
						onFinish={submitHandler}
						validateMessages={validateMessages}
					>
						{columns.map((i, idx) => {
							return (
								<Form.Item
									key={idx}
									name={i.key}
									label={i.title}
									rules={[{ required: true }]}
								>
									<Input
										disabled={isEdit && i.key === 'companyId' ? true : false}
									/>
								</Form.Item>
							);
						})}
						{isEdit ? (
							<div className="time-area">
								<div className="time-info">
									資料建立時間：
									{dayjs(form.getFieldValue('createTime')).format(
										'YYYY-MM-DD HH:mm:ss'
									)}
								</div>
								<div className="time-info">
									資料更新時間：
									{dayjs(form.getFieldValue('updateTime')).format(
										'YYYY-MM-DD HH:mm:ss'
									)}
								</div>
							</div>
						) : null}

						<Button type="primary" htmlType="submit" className="submit-btn">
							{isEdit ? '更新' : '新增'}
						</Button>
					</Form>
				</Space>
			</Modal>
		</>
	);
};

export default SystemManageCompany;
