import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LoginAccountInfo, OrgTree, Role } from '@models/user';
import type { IRootState } from '@store/reducers';
import jwt_decode from 'jwt-decode';
import api from '@api/api';
import axios from 'axios';

export const loginUser = createAsyncThunk(
	'userSlice/loginUser',
	async (accountInfo: LoginAccountInfo) => {
		const { account, password } = accountInfo;
		const res = await api.post(
			`/login_api/signin?username=${account}&password=${password}`
		);
		return res.data;
	}
);

export const loginUserONM = createAsyncThunk(
	'userSlice/lginUserONM',
	async () => {
		const res = await api.post('');
		return;
	}
);

export const logOut = createAsyncThunk('userSlice/logOut', async () => {
	const res = await api.get(`/user/login?redirect=${window.location.href}`);
	return res.data;
});

export const getAllOrgTree = createAsyncThunk(
	'userSlice/getAllOrgTree',
	async () => {
		const res = await api.get(`/webapi/operating/org-tree-list`);
		return res.data;
	}
);

export const getOrgTree = createAsyncThunk('userSlice/getOrgTree', async () => {
	const res = await api.get(`/webapi/authorization/org/tree`);
	return res.data;
});

export const userSlice = createSlice({
	name: 'userSlice',
	initialState: {
		isAuthorization: false,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(loginUser.fulfilled, (state, action) => {
				console.log('loginUser, response:', action.payload);

				const token = action.payload.token;
				const decoded: {
					sub: string;
					roleIds: number[];
					qseId: string;
					tgId: string;
					resId: string;
					jti: string;
				} = jwt_decode(token);
				console.log('decoded', decoded);

				const params = {
					token,
					authority: decoded.roleIds,
					userName: decoded.sub,
					unitId:
						decoded.roleIds[0] === Role.SYSAdmin
							? ''
							: decoded?.resId ?? decoded?.tgId ?? decoded?.qseId,
					userId: decoded.jti,
				};
				localStorage.setItem('energy-dispatch-user', JSON.stringify(params));
				state.isAuthorization = true;
			})
			.addCase(loginUserONM.fulfilled, (state, action) => {
				state.isAuthorization = true;
			})

			.addCase(logOut.fulfilled, (state, action) => {
				console.log('logOut, response:', action.payload);

				localStorage.removeItem('energy-dispatch-user');
				localStorage.removeItem('energy-dispatch-allOrgTree');
				delete axios.defaults.headers.common['Authorization'];
				state.isAuthorization = false;
			})

			.addCase(getAllOrgTree.fulfilled, (state, action) => {
				console.log('getAllOrgTree, response:', action.payload);

				localStorage.setItem(
					'energy-dispatch-allOrgTree',
					JSON.stringify(
						action.payload.list.map((l: { myUnit: OrgTree }) => l.myUnit)
					)
				);
			})

			.addCase(getOrgTree.fulfilled, (state, action) => {
				console.log('getOrgTree, response:', action.payload);
			});
	},
});

export const getIsAuthorizationsState = (state: IRootState) =>
	state.userSlice.isAuthorization;

export default userSlice.reducer;
