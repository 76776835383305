import React, { useEffect, useRef, useState } from 'react';
import NoData from '@components/NoData/NoData';
import { useSelector } from 'react-redux';
import { useMeasure, useFirstMountState } from 'react-use';
import {
	getIsFullscreenState,
	getCustomThemeToken,
} from '@store/reducers/utilSlice';
import { Modal, Spin, Switch } from 'antd';
import { Icon } from '@models/icon';
import { shareChartOption, UnitType } from '@models/common';
import {
	SelectedService,
	TimeType,
} from '@pages/OperatingService/OperatingService';
import dayjs, { Dayjs } from 'dayjs';
import { isEmpty } from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import api from '@api/api';

highchartsMore(Highcharts);
interface DREGProps {
	selectedTXG: string;
	timeType: number;
	serviceObj: SelectedService;
	dateTime: Dayjs | null;
	handleUpdateTime: (arg: string) => void;
	handleApiIsLoading: (arg: boolean) => void;
}

function DREGChart(props: DREGProps) {
	const token = useSelector(getCustomThemeToken);

	const chartOneLabelsObj = [
		{ labelName: '功率', parameter: 'kw', yAxis: 0, valueSuffix: ' kW' },
		{ labelName: '頻率', parameter: 'frequency', yAxis: 1, valueSuffix: ' Hz' },
		{
			labelName: '頻率基準',
			parameter: 'baseFreq',
			yAxis: 1,
			valueSuffix: ' Hz',
		},
		{ labelName: 'SBSPM', parameter: 'sbspm', yAxis: 2, valueSuffix: ' %' },
	];

	const chartOneYAxis = [
		{
			labels: {
				style: {
					color: '#4391FF',
				},
			},
			title: {
				text: '功率 / kW',
				style: {
					color: '#4391FF',
				},
			},
			gridLineColor: token.colorChartNormal,
		},
		{
			labels: {
				style: {
					color: '#72D126',
				},
			},
			title: {
				text: '頻率 / 頻率基準 / Hz',
				style: {
					color: '#72D126',
				},
			},
			gridLineColor: token.colorChartNormal,
			opposite: true,
		},
		{
			labels: {
				style: {
					color: '#EE4D7E',
				},
			},
			title: {
				text: 'SBSPM / %',
				style: {
					color: '#EE4D7E',
				},
			},
			gridLineColor: token.colorChartNormal,
			opposite: true,
		},
	];

	const txgChartTwoLabelsObj = [
		{
			labelName: '得標容量',
			parameter: 'awardedCapacity',
			yAxis: 0,
			valueSuffix: ' kW',
			color: '#4391FF',
			type: 'area',
		},
		{
			labelName: '電能排程',
			parameter: 'scheduleKW',
			yAxis: 1,
			valueSuffix: ' kW',
			color: '#72D126',
		},
		{
			labelName: 'SPM',
			parameter: 'spm',
			yAxis: 2,
			valueSuffix: ' %',
			color: '#FF7A00',
		},
		{
			labelName: '棄標狀態',
			parameter: 'abandon',
			yAxis: 0,
			valueSuffix: ' kW',
			color: '#98A2B3',
		},
	];

	const resChartTwoLabelsObj = [
		{
			labelName: '得標容量',
			parameter: 'awardedCapacity',
			yAxis: 0,
			valueSuffix: ' kW',
			color: '#4391FF',
			type: 'area',
		},
		{
			labelName: '電能排程',
			parameter: 'scheduleKW',
			yAxis: 1,
			valueSuffix: ' kW',
			color: '#72D126',
		},
	];

	const chartTwoYAxis = [
		{
			labels: {
				style: {
					color: '#4391FF',
				},
			},
			title: {
				text: '功率 / kW',
				style: {
					color: '#4391FF',
				},
			},
			gridLineColor: token.colorChartNormal,
			opposite: false,
		},
		{
			labels: {
				style: {
					color: '#FF7A00',
				},
			},
			title: {
				text: 'SPM / %',
				style: {
					color: '#FF7A00',
				},
			},
			gridLineColor: token.colorChartNormal,
			opposite: true,
		},
		{
			labels: {
				style: {
					color: '#72D126',
				},
			},
			title: {
				text: '電能排程 / kW',
				style: {
					color: '#72D126',
				},
			},
			gridLineColor: token.colorChartNormal,
			opposite: true,
		},
	];

	const txgChartThreeLabelsObj = [
		{
			labelName: '頻率基準',
			parameter: 'baseFreq',
			yAxis: 1,
			valueSuffix: ' Hz',
			color: '#FF7A00',
		},
		{
			labelName: '頻率',
			parameter: 'frequency',
			yAxis: 1,
			valueSuffix: ' Hz',
			color: '#EE4D7E',
		},
	];

	const resChartThreeLabelsObj = [
		{
			labelName: 'R相電壓值',
			parameter: 'voltageA',
			yAxis: 0,
			valueSuffix: ' pu',
			color: '#4391FF',
		},
		{
			labelName: 'S相電壓值',
			parameter: 'voltageB',
			yAxis: 0,
			valueSuffix: ' pu',
			color: '#4391FF',
		},
		{
			labelName: 'T相電壓值',
			parameter: 'voltageC',
			yAxis: 0,
			valueSuffix: ' pu',
			color: '#4391FF',
		},
		{
			labelName: '虛功率',
			parameter: 'kvar',
			yAxis: 1,
			valueSuffix: ' kvar',
			color: '#72D126',
		},
		{
			labelName: 'SOC',
			parameter: 'soc',
			yAxis: 2,
			valueSuffix: ' %',
			color: '#EE4D7E',
		},
	];

	const txgChartThreeYAxis = [
		{
			labels: {
				style: {
					color: '#4391FF',
				},
			},
			title: {
				text: '功率 / kW',
				style: {
					color: '#4391FF',
				},
			},
			gridLineColor: token.colorChartNormal,
			opposite: false,
		},
		{
			labels: {
				style: {
					color: '#EE4D7E',
				},
			},
			title: {
				text: '頻率 / 頻率基準 / Hz',
				style: {
					color: '#EE4D7E',
				},
			},
			gridLineColor: token.colorChartNormal,
			opposite: true,
		},
	];

	const resChartThreeYAxis = [
		{
			labels: {
				style: {
					color: '#4391FF',
				},
			},
			title: {
				text: '電壓 / pu',
				style: {
					color: '#4391FF',
				},
			},
			gridLineColor: token.colorChartNormal,
			opposite: false,
		},
		{
			labels: {
				style: {
					color: '#72D126',
				},
			},
			title: {
				text: '虛功率 / kvar',
				style: {
					color: '#72D126',
				},
			},
			gridLineColor: token.colorChartNormal,
			opposite: true,
		},
		{
			labels: {
				style: {
					color: '#EE4D7E',
				},
			},
			title: {
				text: 'SOC / %',
				style: {
					color: '#EE4D7E',
				},
			},
			gridLineColor: token.colorChartNormal,
			opposite: true,
		},
	];

	const isFullscreen = useSelector(getIsFullscreenState);
	const [refresher, setRefresher] = useState(0);
	const [expandField, setExpandField] = useState(false);
	const [isNowUnitTypeTXG, setIsNowUnitTypeTXG] = useState(true);
	const isFirstMount = useFirstMountState();

	const [isChartOneNoData, setIsChartOneNoData] = useState(false);
	const [expandChartOne, setExpandChartOne] = useState(false);
	const [isChartOneLoading, setIsChartOneLoading] = useState(false);
	const chartOneRef = useRef<null | HTMLDivElement>(null);
	const [chartOneOptions, setChartOneOptions] = useState({
		...shareChartOption(token),
		chart: {
			height: 300,
			type: 'line',
			zoomType: 'xy',
			animation: false,
			backgroundColor: token.colorBgContainer,
		},
		colors: ['#4391FF', '#72D126', '#FF7A00', '#EE4D7E'],
		series: chartOneLabelsObj.map((item) => ({
			name: item.labelName,
			parameter: item.parameter,
			data: [],
			step: 'left',
			yAxis: item.yAxis,
			marker: {
				enabled: false,
			},
			dataLabels: {
				enabled: false,
			},
			tooltip: {
				valueSuffix: item.valueSuffix,
			},
		})),
		yAxis: chartOneYAxis,
	});
	const [
		chartOnePartRef,
		{
			x: cOneX,
			y: cOneY,
			width: cOneWidth,
			height: cOneHeight,
			top: cOneTop,
			right: cOneRight,
			bottom: cOneBottom,
			left: cOneLeft,
		},
	] = useMeasure<HTMLDivElement>();

	const [currTenMinSwitch, setCurrTenMinSwitch] = useState(false);
	const [isChartTwoNoData, setIsChartTwoNoData] = useState(false);
	const [expandChartTwo, setExpandChartTwo] = useState(false);
	const [isChartTwoLoading, setIsChartTwoLoading] = useState(false);
	const chartTwoRef = useRef<null | HTMLDivElement>(null);
	const [chartTwoOptions, setChartTwoOptions] = useState({
		...shareChartOption(token),
		chart: {
			height: 300,
			type: 'line',
			zoomType: 'xy',
			animation: false,
			backgroundColor: token.colorBgContainer,
		},
		series: [] as any[],
		yAxis: chartTwoYAxis,
	});
	const [
		chartTwoPartRef,
		{
			x: cTwoX,
			y: cTwoY,
			width: cTwoWidth,
			height: cTwoHeight,
			top: cTwoTop,
			right: cTwoRight,
			bottom: cTwoBottom,
			left: cTwoLeft,
		},
	] = useMeasure<HTMLDivElement>();

	const [isChartThreeNoData, setIsChartThreeNoData] = useState(false);
	const [expandChartThree, setExpandChartThree] = useState(false);
	const [isChartThreeLoading, setIsChartThreeLoading] = useState(false);
	const chartThreeRef = useRef<null | HTMLDivElement>(null);
	const [chartThreeOptions, setChartThreeOptions] = useState({
		...shareChartOption(token),
		chart: {
			height: 300,
			type: 'line',
			zoomType: 'xy',
			animation: false,
			backgroundColor: token.colorBgContainer,
		},
		series: [] as any[],
		yAxis: txgChartThreeYAxis,
	});
	const [
		chartThreePartRef,
		{
			x: cThreeX,
			y: cThreeY,
			width: cThreeWidth,
			height: cThreeHeight,
			top: cThreeTop,
			right: cThreeRight,
			bottom: cThreeBottom,
			left: cThreeLeft,
		},
	] = useMeasure<HTMLDivElement>();

	const getChartData = async (
		id: string,
		isTXG: boolean,
		needLoading = false
	) => {
		console.log('serviceObj:', props.serviceObj);

		if (needLoading) {
			setIsChartOneLoading(true);
			setIsChartTwoLoading(true);
			setIsChartThreeLoading(true);
			props.handleApiIsLoading(true);
		}

		let day = dayjs().format('YYYY-MM-DD');
		let start = '';
		let end = '';

		if (props.timeType === TimeType.Specify) {
			day = dayjs(props.dateTime).format('YYYY-MM-DD');
			start = dayjs(props.dateTime).format('YYYY-MM-DD HH:mm:ss');
			end = dayjs(props.dateTime)
				.add(10, 'minute')
				.format('YYYY-MM-DD HH:mm:ss');
		}
		console.log('day start end:', day, start, end);

		const url = isTXG
			? [
					`/webapi/history/ed/dreg/list?id=${id}&start=${start}&end=${end}`,
					`/webapi/history/ed/dreg/bid?id=${id}&date=${day}`,
					`/webapi/history/ed/dreg/detail?id=${id}&start=${start}&end=${end}`,
					`/webapi/clippedkw/sr/list?id=${id}&date=${dayjs(
						day
					).valueOf()}&statisticsType=day`,
			  ]
			: [
					`/webapi/history/ed/dreg/field/list?id=${id}&start=${start}&end=${end}`,
					`/webapi/history/ed/dreg/field/bid?id=${id}&date=${dayjs(day).format(
						'YYYY-MM-DD'
					)}`,
			  ];
		const allResp = await Promise.all(
			url.map(async (u) => {
				const res = await api.get(u);
				return res.data;
			})
		);
		console.log('getChartData allResp', allResp);
		props.handleUpdateTime(dayjs().format('HH:mm:ss'));

		if (allResp.some((res) => res.status === 'fail')) {
			props.handleApiIsLoading(false);
			return Modal.error({
				title: '找不到資料',
				content: '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		if (isTXG) {
			setChartOneOptions((prevState) => ({
				...prevState,
				series: prevState.series.map((s) => ({
					...s,
					data: allResp[0].list.map((r: any) => [
						r.timeticks,
						r[s.parameter] ?? null,
					]),
				})),
				yAxis: chartOneYAxis,
			}));
			setChartTwoOptions((prevState) => ({
				...prevState,
				series: txgChartTwoLabelsObj.map((s) => ({
					name: s.labelName,
					parameter: s.parameter,
					type: s.type,
					fillOpacity: s.type ? 0.3 : 1,
					data: allResp[1].list.map((r: any) => [
						r.timeticks,
						s.parameter === 'spm'
							? allResp[3].list.filter(
									(item: any) =>
										dayjs(item.servicePeriod).hour() ===
										dayjs(r.timeticks).hour()
							  )[0]?.spm ?? null
							: r[s.parameter] ?? null,
					]),
					step: 'left',
					yAxis: s.yAxis,
					marker: {
						enabled: false,
					},
					dataLabels: {
						enabled:
							prevState.series.filter((p) => p.parameter === s.parameter)[0]
								?.dataLabels?.enabled ?? false,
					},
					tooltip: {
						valueSuffix: s.valueSuffix,
					},
					color: s.color,
				})),
				yAxis: chartTwoYAxis,
				xAxis: {
					...prevState.xAxis,
				},
			}));

			const threeNewLabels = allResp[2]?.list[0]?.res.map((item: any) => ({
				labelName: item.resId,
				parameter: 'kw',
				yAxis: 0,
				valueSuffix: ' kW',
				color: '#4391FF',
			}));
			threeNewLabels.push(...txgChartThreeLabelsObj);
			setChartThreeOptions((prevState) => ({
				...prevState,
				series: threeNewLabels.map((s: any) => ({
					name: s.labelName,
					parameter: s.parameter,
					data:
						s.parameter === 'baseFreq' || s.parameter === 'frequency'
							? allResp[0].list.map((r: any) => [
									r.timeticks,
									r[s.parameter] ?? null,
							  ])
							: allResp[2].list.map((r: any) => [
									r.timeticks,
									r.res.filter((item: any) => item.resId === s.labelName)[0]
										?.kw ?? null,
							  ]),
					step: 'left',
					yAxis: s.yAxis,
					marker: {
						enabled: false,
					},
					dataLabels: {
						enabled:
							prevState.series.filter((p) => p.parameter === s.parameter)[0]
								?.dataLabels?.enabled ?? false,
					},
					tooltip: {
						valueSuffix: s.valueSuffix,
					},
					color: s.color,
				})),
				yAxis: txgChartThreeYAxis,
			}));
		} else {
			const chartOneArr = chartOneLabelsObj.slice(0, 3);
			setChartOneOptions((prevState) => ({
				...prevState,
				series: chartOneArr.map((s) => ({
					name: s.labelName,
					parameter: s.parameter,
					data: allResp[0].list.map((r: any) => [
						r.timeticks,
						r[s.parameter] ?? null,
					]),
					step: 'left',
					yAxis: s.yAxis,
					marker: {
						enabled: false,
					},
					dataLabels: {
						enabled:
							prevState.series.filter((p) => p.parameter === s.parameter)[0]
								?.dataLabels?.enabled ?? false,
					},
					tooltip: {
						valueSuffix: s.valueSuffix,
					},
				})),
				yAxis: chartOneYAxis.slice(0, 2),
			}));
			setChartTwoOptions((prevState) => ({
				...prevState,
				series: resChartTwoLabelsObj.map((s) => ({
					name: s.labelName,
					parameter: s.parameter,
					type: s.type,
					fillOpacity: s.type ? 0.3 : 1,
					data: allResp[1].list.map((r: any) => [
						r.timeticks,
						r[s.parameter] ?? null,
					]),
					step: 'left',
					yAxis: s.yAxis,
					marker: {
						enabled: false,
					},
					dataLabels: {
						enabled:
							prevState.series.filter((p) => p.parameter === s.parameter)[0]
								?.dataLabels?.enabled ?? false,
					},
					tooltip: {
						valueSuffix: s.valueSuffix,
					},
					color: s.color,
				})),
				yAxis: chartTwoYAxis.slice(0, 2),
				xAxis: {
					...prevState.xAxis,
				},
			}));
			setChartThreeOptions((prevState) => ({
				...prevState,
				series: resChartThreeLabelsObj.map((s) => ({
					name: s.labelName,
					parameter: s.parameter,
					data: allResp[0].list.map((r: any) => [
						r.timeticks,
						r[s.parameter] ?? null,
					]),
					step: 'left',
					yAxis: s.yAxis,
					marker: {
						enabled: false,
					},
					dataLabels: {
						enabled:
							prevState.series.filter((p) => p.parameter === s.parameter)[0]
								?.dataLabels?.enabled ?? false,
					},
					tooltip: {
						valueSuffix: s.valueSuffix,
					},
					color: s.color,
				})),
				yAxis: resChartThreeYAxis,
			}));
		}

		setIsChartOneNoData(allResp[0].list.length === 0);
		setIsChartTwoNoData(allResp[1].list.length === 0);
		setIsChartThreeNoData(allResp[0].list.length === 0);

		if (needLoading) {
			setTimeout(() => {
				setIsChartOneLoading(false);
				setIsChartTwoLoading(false);
				setIsChartThreeLoading(false);
				props.handleApiIsLoading(false);
			}, 1000);
		}
	};

	useEffect(() => {
		const cleanup = setInterval(() => setRefresher(Date.now()), 10 * 1000);
		return () => clearInterval(cleanup);
	}, []);

	useEffect(() => {
		setChartOneOptions((prevState) => ({
			...prevState,
			series: prevState.series.map((s) => ({
				...s,
				data: [],
			})),
		}));
		setChartTwoOptions((prevState) => ({
			...prevState,
			series: prevState.series.map((s) => ({
				...s,
				data: [],
			})),
		}));
		setChartThreeOptions((prevState) => ({
			...prevState,
			series: prevState.series.map((s) => ({
				...s,
				data: [],
			})),
		}));
	}, [props.timeType, props.serviceObj, props.dateTime]);

	useEffect(() => {
		setIsChartOneLoading(true);
		setIsChartTwoLoading(true);
		setIsChartThreeLoading(true);

		setTimeout(() => {
			setIsChartOneLoading(false);
			setIsChartTwoLoading(false);
			setIsChartThreeLoading(false);
		}, 1000);
	}, [isFullscreen]);

	useEffect(() => {
		console.log('props:', props);

		if (!isEmpty(props.serviceObj) && props.selectedTXG && !isChartOneLoading) {
			const isTXG = props.serviceObj.unitType === UnitType.TXG;
			getChartData(props.selectedTXG, isTXG, true);
			setIsNowUnitTypeTXG(isTXG);
		}
	}, [props.timeType, props.serviceObj, props.dateTime]);

	useEffect(() => {
		console.log('refresher:', refresher);
		if (isFirstMount) {
			return;
		}

		if (
			!isEmpty(props.serviceObj) &&
			props.selectedTXG &&
			props.timeType === TimeType.WeekOrImmediate
		) {
			const isTXG = props.serviceObj.unitType === UnitType.TXG;
			getChartData(props.selectedTXG, isTXG);
			setIsNowUnitTypeTXG(isTXG);
		}
	}, [refresher]);

	useEffect(() => {
		console.log('currTenMinSwitch:', currTenMinSwitch);
		const dayAndNowTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
		setChartTwoOptions((prevState) => ({
			...prevState,
			xAxis: {
				...prevState.xAxis,
				plotBands: currTenMinSwitch
					? [
							{
								from: dayjs(`${dayAndNowTime}`).valueOf(),
								to: dayjs(`${dayAndNowTime}`).add(10, 'minute').valueOf(),
								color: '#EE4D7E',
								zIndex: 5,
								id: 'plot-band-10-min',
							},
					  ]
					: [],
			},
		}));
	}, [currTenMinSwitch]);

	useEffect(() => {
		setChartOneOptions((prevState) => ({
			...prevState,
			chart: {
				...prevState.chart,
				height: expandChartOne ? 600 : 300,
			},
		}));

		console.log('cOneHeight', cOneHeight);
		if (cOneHeight > 0 && cOneHeight < 600) {
			chartOneRef.current?.scrollIntoView({
				block: 'center',
				behavior: 'smooth',
			});
		}

		if (cOneHeight !== 0) {
			setTimeout(() => {
				setIsChartOneLoading(false);
			}, 300);
		}
	}, [expandChartOne]);

	useEffect(() => {
		setChartTwoOptions((prevState) => ({
			...prevState,
			chart: {
				...prevState.chart,
				height: expandChartTwo ? 600 : 300,
			},
		}));

		console.log('cTwoHeight', cTwoHeight);
		if (cTwoHeight > 0 && cTwoHeight < 600) {
			chartTwoRef.current?.scrollIntoView({
				block: 'center',
				behavior: 'smooth',
			});
		}

		if (cTwoHeight !== 0) {
			setTimeout(() => {
				setIsChartTwoLoading(false);
			}, 300);
		}
	}, [expandChartTwo]);

	useEffect(() => {
		setChartThreeOptions((prevState) => ({
			...prevState,
			chart: {
				...prevState.chart,
				height: expandChartThree ? 600 : 300,
			},
		}));

		console.log('cThreeHeight', cThreeHeight);
		if (cThreeHeight > 0 && cThreeHeight < 600) {
			chartThreeRef.current?.scrollIntoView({
				block: 'center',
				behavior: 'smooth',
			});
		}

		if (cThreeHeight !== 0) {
			setTimeout(() => {
				setIsChartThreeLoading(false);
			}, 300);
		}
	}, [expandChartThree]);

	return (
		<>
			{/* chart one */}
			<>
				<div className="power-frequency" ref={chartOnePartRef}>
					<div className="chart-bar">
						<div className="title-part">功率與頻率</div>
						<div className="switch-part">
							<div className="switch-label">
								<span className="switch-label-title">顯示標籤</span>
								{chartOneOptions.series.map((item, currIdx) => {
									return (
										<div
											key={currIdx}
											className={item.dataLabels.enabled ? 'switch-on' : ''}
										>
											<Switch
												checked={item.dataLabels.enabled}
												onChange={(checked: boolean) => {
													setChartOneOptions((prevState) => ({
														...prevState,
														series: prevState.series.map((s, prevIdx) => ({
															...s,
															dataLabels: {
																enabled:
																	currIdx === prevIdx
																		? checked
																		: s.dataLabels.enabled,
															},
														})),
													}));
												}}
											/>
											<span>{item.name}</span>
										</div>
									);
								})}
							</div>
							<div
								className="switch-expand"
								onClick={() => {
									setIsChartOneLoading(true);
									setExpandChartOne((prevState) => !prevState);
								}}
							>
								{expandChartOne ? (
									<>
										<Icon.CollapseArrow />
										收回圖表
									</>
								) : (
									<>
										<Icon.ExpandArrow />
										展開圖表
									</>
								)}
							</div>
						</div>
					</div>
					<div
						className={`chart ${expandChartOne ? 'expand-chart' : ''}`}
						ref={chartOneRef}
					>
						{isChartOneLoading ? (
							<Spin className="spin-center" />
						) : (
							<>
								<HighchartsReact
									highcharts={Highcharts}
									options={chartOneOptions}
								/>
								<div className="hint-text">滑鼠拖拉可以放大圖表</div>
								<div className="fixed-no-data">
									{isChartOneNoData ? <NoData /> : ''}
								</div>
							</>
						)}
					</div>
				</div>
			</>

			{/* chart two */}
			<>
				<div className="bid-scheduling-abandon" ref={chartTwoPartRef}>
					<div className="chart-bar">
						<div className="title-part">得標、調度、棄標狀態</div>
						<div className="switch-part">
							<div className="switch-label">
								<span className="switch-label-title">顯示標籤</span>
								{chartTwoOptions.series.slice(0, -1).map((item, currIdx) => {
									return (
										<div
											key={currIdx}
											className={item.dataLabels.enabled ? 'switch-on' : ''}
										>
											<Switch
												checked={item.dataLabels.enabled}
												onChange={(checked: boolean) => {
													setChartTwoOptions((prevState) => ({
														...prevState,
														series: prevState.series.map((s, prevIdx) => ({
															...s,
															dataLabels: {
																enabled:
																	currIdx === prevIdx
																		? checked
																		: s.dataLabels.enabled,
															},
														})),
													}));
												}}
											/>
											<span>{item.name}</span>
										</div>
									);
								})}
								<div className={currTenMinSwitch ? 'switch-on' : ''}>
									<Switch
										checked={currTenMinSwitch}
										onChange={(checked: boolean) => {
											setCurrTenMinSwitch(checked);
										}}
									/>
									<span>當前10分鐘位置</span>
								</div>
							</div>
							<div
								className="switch-expand"
								onClick={() => {
									setIsChartTwoLoading(true);
									setExpandChartTwo((prevState) => !prevState);
								}}
							>
								{expandChartTwo ? (
									<>
										<Icon.CollapseArrow />
										收回圖表
									</>
								) : (
									<>
										<Icon.ExpandArrow />
										展開圖表
									</>
								)}
							</div>
						</div>
					</div>
					<div
						className={`chart ${expandChartTwo ? 'expand-chart' : ''}`}
						ref={chartTwoRef}
					>
						{isChartTwoLoading ? (
							<Spin className="spin-center" />
						) : (
							<>
								<HighchartsReact
									highcharts={Highcharts}
									options={chartTwoOptions}
								/>
								<div className="hint-text">滑鼠拖拉可以放大圖表</div>
								<div className="fixed-no-data">
									{isChartTwoNoData ? <NoData /> : ''}
								</div>
							</>
						)}
					</div>
				</div>
			</>

			{/* chart three */}
			<>
				{expandField ? (
					<div className="frequency-power-res" ref={chartThreePartRef}>
						<div className="chart-bar">
							<div className="title-part">
								{isNowUnitTypeTXG ? '頻率與各 RES 功率' : '電壓與虛功率'}
							</div>
							<div className="switch-part">
								<div className="switch-label">
									<span className="switch-label-title">顯示標籤</span>
									{chartThreeOptions.series.map((item, currIdx) => {
										return (
											<div
												key={currIdx}
												className={item.dataLabels.enabled ? 'switch-on' : ''}
											>
												<Switch
													checked={item.dataLabels.enabled}
													onChange={(checked: boolean) => {
														setChartThreeOptions((prevState) => ({
															...prevState,
															series: prevState.series.map((s, prevIdx) => ({
																...s,
																dataLabels: {
																	enabled:
																		currIdx === prevIdx
																			? checked
																			: s.dataLabels.enabled,
																},
															})),
														}));
													}}
												/>
												<span>{item.name}</span>
											</div>
										);
									})}
								</div>
								<div
									className="switch-expand"
									onClick={() => {
										setIsChartThreeLoading(true);
										setExpandChartThree((prevState) => !prevState);
									}}
								>
									{expandChartThree ? (
										<>
											<Icon.CollapseArrow />
											收回圖表
										</>
									) : (
										<>
											<Icon.ExpandArrow />
											展開圖表
										</>
									)}
								</div>
							</div>
						</div>
						<div
							className={`chart ${expandChartThree ? 'expand-chart' : ''}`}
							ref={chartThreeRef}
						>
							{isChartThreeLoading ? (
								<Spin className="spin-center" />
							) : (
								<>
									<HighchartsReact
										highcharts={Highcharts}
										options={chartThreeOptions}
									/>
									<div className="hint-text">滑鼠拖拉可以放大圖表</div>
									<div className="fixed-no-data">
										{isChartThreeNoData ? <NoData /> : ''}
									</div>
								</>
							)}
						</div>
					</div>
				) : (
					''
				)}
				<div
					className="expand-field"
					onClick={() => setExpandField((prevState) => !prevState)}
				>
					{expandField ? (
						<>
							<Icon.Minus />
							{isNowUnitTypeTXG ? '收回所屬場域' : '收回電壓與虛功率'}
						</>
					) : (
						<>
							<Icon.Plus />
							{isNowUnitTypeTXG ? '展開所屬場域' : '展開電壓與虛功率'}
						</>
					)}
				</div>
			</>
		</>
	);
}
export default DREGChart;
