import React, { useEffect, useState } from 'react';
import {
	Button,
	Card,
	Checkbox,
	Col,
	Form,
	Input,
	Modal,
	Pagination,
	Row,
	Select,
	Space,
	Switch,
	TreeSelect,
} from 'antd';
import Table from 'antd/es/table';
import Column from 'antd/es/table/Column';
import { Icon } from '@models/icon';
import { OrgTree, RoleChineseVer } from '@models/user';
import api from '@api/api';
import dayjs from 'dayjs';
import './SystemManageUser.less';

interface UnitIdOptions {
	value: string;
	title: string;
	children?: UnitIdOptions[];
}

interface UserDataType {
	name: string;
	password?: string;
	confirmedPwd?: string;
	roleId: number | null;
	unitId: string;
	email: string;
	phones: string;
	noticeTypes: number[];
	enableStatus: string;
	retry: number;
	lastLoginTime: number;

	unit: OrgTree;
}

enum NoticeTypes {
	Phone = 1,
	Email,
}

const tableColumns = [
	{
		title: '使用者ID',
		key: 'id',
		width: '13%',
	},
	{
		title: '使用者名稱',
		key: 'name',
		width: '13%',
	},
	{
		title: '管理者身份',
		key: 'roleId',
		width: '11%',
	},
	{
		title: '所屬組織ID',
		key: 'unit',
		width: '15%',
	},
	{
		title: 'Email',
		key: 'email',
		width: '15%',
	},
	{
		title: '聯絡電話',
		key: 'phones',
		width: '13%',
	},
	{
		title: '帳戶啟用狀態',
		key: 'enableStatus',
		width: '11%',
	},
];

const getUnitOptions = (orgTreeData: OrgTree[]) => {
	const options: UnitIdOptions[] = orgTreeData.map((company: OrgTree) => {
		if (company.subUnits.length !== 0) {
			return {
				value: `${company.unitType}-${company.unitId}`,
				title: `[${company.unitType}] ${company.unitName}`,
				children: getUnitOptions(company.subUnits),
			};
		} else {
			return {
				value: `${company.unitType}-${company.unitId}`,
				title: `[${company.unitType}] ${company.unitName}`,
			};
		}
	});
	return options;
};

let resultOfUnitType: string;
const getUnitType = (unitId: string | null, orgTreeData: OrgTree[]) => {
	if (!unitId) return '';
	orgTreeData.forEach((company: OrgTree) => {
		if (company.unitId === unitId) {
			resultOfUnitType = company.unitType;
		} else {
			if (company.subUnits.length !== 0) {
				getUnitType(unitId, company.subUnits);
			}
		}
	});
	return resultOfUnitType;
};

const getCellData = (
	record: UserDataType,
	key: string
): string | JSX.Element => {
	if (key === 'roleId') {
		return RoleChineseVer[record.roleId as keyof typeof RoleChineseVer];
	}
	if (key === 'unit') {
		return `[${record[key].unitType}] ${record[key].unitId}`;
	}
	if (key === 'enableStatus') {
		return record[key] === 'enable' ? (
			<div className="status-enable">開啟</div>
		) : (
			<div className="status-disable">關閉</div>
		);
	}
	return (record as any)[key];
};

const SystemManageUser = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [allTableData, setAllTableData] = useState([] as UserDataType[]);
	const [displayTableData, setDisplayTableData] = useState(
		[] as UserDataType[]
	);
	const [pageIndex, setPageIndex] = useState(1);
	const [pageSize, setPageSize] = useState(10);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [form] = Form.useForm();
	const passwordValue = Form.useWatch('password', form);
	const confirmedPwdValue = Form.useWatch('confirmedPwd', form);
	const phonesValue = Form.useWatch('phones', form);

	const [isChangePwd, setIsChangePwd] = useState(false);
	// const [isPhoneRequired, setIsPhoneRequired] = useState(false);
	const [roleDropdownIsOpen, setRoleDropdownIsOpen] = useState(false);
	const [unitDropdownIsOpen, setUnitDropdownIsOpen] = useState(false);
	const [unitDropdownList, setUnitDropdownList] = useState(
		[] as UnitIdOptions[]
	);

	const allOrgTreeList: OrgTree[] = JSON.parse(
		localStorage.getItem('energy-dispatch-allOrgTree') as string
	);

	const noneValue: UserDataType = {
		name: '',
		password: '',
		confirmedPwd: '',
		roleId: null,
		unitId: '',
		email: '',
		phones: '',
		noticeTypes: [],
		enableStatus: '',
		retry: 0,
		lastLoginTime: 0,
		unit: {} as OrgTree,
	};

	const validateMessages = {
		required: '請輸入 ${label} ',
	};

	const passwordValidate =
		passwordValue &&
		passwordValue.length >= 8 &&
		passwordValue.length <= 20 &&
		passwordValue.match(/([a-zA-Z])/g) &&
		passwordValue.match(/([0-9])/g) &&
		passwordValue.match(/((\$)|(@)|(!)|(%)|(\*)|(\?)|(&)|(-))/g);

	const getTableData = async () => {
		setIsLoading(true);
		const response: any = await api.get('/webapi/system/user/list');
		if (response.data?.status !== 'ok') {
			return Modal.error({
				title: '找不到資料',
				content: '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		setDisplayTableData([]);
		setAllTableData(response.data?.list);
		setIsLoading(false);
	};

	const submitHandler = async (formData: UserDataType) => {
		console.log('submitHandler', formData);

		if (
			((isEdit && isChangePwd) || !isEdit) &&
			(!passwordValidate || passwordValue !== confirmedPwdValue)
		) {
			return;
		}

		const id = formData.unitId.slice(4, formData.unitId.length);
		const requestData = {
			...formData,
			phones: [formData.phones],
			enableStatus: formData.enableStatus ? 'enable' : 'disable',
			unit: {
				unitId: id,
				unitType: getUnitType(id, allOrgTreeList),
			},
		};
		delete requestData.confirmedPwd;
		!isChangePwd && delete requestData.password;

		let response;
		if (isEdit) {
			response = await api.put(`/webapi/system/user`, requestData);
		} else {
			response = await api.post(`/webapi/system/user`, requestData);
		}

		if (response.data?.status !== 'ok') {
			return Modal.error({
				title: `${isEdit ? '更新' : '新增'}失敗`,
				content:
					response.data?.err?.msg || '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		Modal.success({
			title: `${isEdit ? '更新' : '新增'}成功`,
			okText: '確定',
			centered: true,
			closable: true,
			closeIcon: <Icon.ModalClose />,
		});

		getTableData();
		setIsModalOpen(false);
	};

	const inputColumns = [
		{
			title: '使用者名稱',
			key: 'name',
			rule: [{ required: true }],
		},
		{
			title: '使用者密碼',
			key: 'password',
			rule: [{ required: true }],
			field: (
				<Input.Password
					status={
						(passwordValue && !passwordValidate) ||
						(passwordValue &&
							confirmedPwdValue &&
							passwordValue !== confirmedPwdValue)
							? 'error'
							: ''
					}
					disabled={isEdit && !isChangePwd}
					placeholder={isChangePwd ? '請輸入新密碼' : ''}
					iconRender={(visible) =>
						visible ? <Icon.EyeOpen /> : <Icon.EyeClose />
					}
				/>
			),
		},
		{
			title: '確認新密碼',
			key: 'confirmedPwd',
			rule: [{ required: true }],
			field: (
				<Input.Password
					status={
						passwordValue &&
						confirmedPwdValue &&
						passwordValue !== confirmedPwdValue
							? 'error'
							: ''
					}
					disabled={isEdit && !isChangePwd}
					placeholder={isChangePwd ? '請再次輸入密碼' : ''}
					iconRender={(visible) =>
						visible ? <Icon.EyeOpen /> : <Icon.EyeClose />
					}
				/>
			),
		},
		{
			title: '管理者身份',
			key: 'roleId',
			rule: [{ required: true }],
			field: (
				<Select
					style={{ width: '100%' }}
					options={Object.values(RoleChineseVer).map((val, idx) => ({
						value: idx + 1,
						label: val,
					}))}
					placeholder="請選擇"
					onDropdownVisibleChange={() => {
						setRoleDropdownIsOpen((prevState: boolean) => !prevState);
					}}
					suffixIcon={
						roleDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
					}
				/>
			),
		},
		{
			title: '所屬組織ID',
			key: 'unitId',
			rule: [{ required: true }],
			field: (
				<TreeSelect
					treeData={unitDropdownList}
					treeDefaultExpandAll
					placeholder="請選擇"
					style={{ width: '100%' }}
					onDropdownVisibleChange={() => {
						setUnitDropdownIsOpen((prevState: boolean) => !prevState);
					}}
					suffixIcon={
						unitDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
					}
				/>
			),
		},
		{
			title: 'Email',
			key: 'email',
			rule: [
				{ required: true },
				{
					pattern:
						/^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/,
					message: 'Email 格式錯誤',
				},
			],
			disabled: true,
		},
		{
			title: '聯絡電話',
			key: 'phones',
			placeholder: '需含國碼，e.g. +886912345678',
			rule: [
				// { required: isPhoneRequired },
				{
					pattern: /^[+][0-9]+$/,
					message: '聯絡電話 格式錯誤',
				},
			],
		},
		{
			title: '通知方式',
			key: 'noticeTypes',
			field: (
				<Checkbox.Group
					onChange={(val) => {
						// const hasPhone = val.includes(NoticeTypes.Phone);
						// form.validateFields(['phones']);
						// setIsPhoneRequired(hasPhone);
					}}
				>
					<Checkbox value={NoticeTypes.Phone} disabled={!phonesValue}>
						Phone
					</Checkbox>
					<Checkbox value={NoticeTypes.Email}>Email</Checkbox>
				</Checkbox.Group>
			),
		},
		{
			title: '帳戶啟用狀態',
			key: 'enableStatus',
			field: <Switch />,
		},
	];

	useEffect(() => {
		getTableData();

		if (allOrgTreeList.length > 0) {
			const result = getUnitOptions(allOrgTreeList);
			setUnitDropdownList(result);
		}
	}, []);

	useEffect(() => {
		if (pageIndex && pageSize && allTableData.length > 0) {
			const startIndex = (pageIndex - 1) * pageSize;
			const endIndex = Math.min(
				startIndex + pageSize - 1,
				allTableData.length - 1
			);

			setDisplayTableData(allTableData.slice(startIndex, endIndex + 1));
		}
	}, [pageIndex, pageSize, allTableData]);

	return (
		<Card
			title="人員管理"
			extra={
				<Button
					type="primary"
					onClick={() => {
						setIsEdit(false);
						setIsChangePwd(false);
						setIsModalOpen(true);
						form.setFieldsValue({ ...noneValue });
					}}
				>
					新增
				</Button>
			}
			className="system-manage-user"
		>
			<div className="data-table">
				<Table
					pagination={false}
					loading={isLoading}
					dataSource={displayTableData}
					rowKey="id"
				>
					{tableColumns.map((col) => {
						const { title, key, width } = col;
						return (
							<Column
								title={title}
								width={width}
								dataIndex={key}
								key={key}
								align="center"
								render={(_: any, record: UserDataType) => (
									<div className="table-ellipsis">
										{getCellData(record, key)}
									</div>
								)}
							/>
						);
					})}
					<Column
						title="操作"
						width="7%"
						key="action"
						align="center"
						render={(_: any, record: UserDataType) => (
							<Icon.Edit
								className="edit-company"
								onClick={() => {
									setIsEdit(true);
									setIsChangePwd(false);
									setIsModalOpen(true);
									form.setFieldsValue({
										...record,
										password: '1234567812345678',
										confirmedPwd: '1234567812345678',
										unitId: record?.unit?.unitId
											? `${record?.unit?.unitType}-${record?.unit?.unitId}`
											: '',
										phones: record?.phones ? record?.phones[0] : '',
										enableStatus: record.enableStatus === 'enable',
									});
									// setIsPhoneRequired(
									// 	record.noticeTypes
									// 		? record.noticeTypes.includes(NoticeTypes.Phone)
									// 		: false
									// );
								}}
							/>
						)}
					/>
				</Table>
			</div>
			{allTableData.length > 0 ? (
				<div className="pagination-bar">
					<div className="right">
						<Pagination
							total={allTableData.length}
							current={pageIndex}
							onChange={(page, size) => {
								setPageIndex(page);
								setPageSize(size);
							}}
							showSizeChanger
							showQuickJumper
							defaultPageSize={10}
							pageSize={pageSize}
							pageSizeOptions={['10', '20', '30']}
							locale={{
								items_per_page: ' / page',
								jump_to: 'Go to ',
								page: '',
							}}
							showTotal={(total) => `Total ${total} items`}
						/>
					</div>
				</div>
			) : null}
			<Modal
				title={`${isEdit ? '編輯' : '新增'}人員`}
				open={isModalOpen}
				centered
				width={380}
				destroyOnClose={true}
				maskClosable={false}
				onCancel={() => {
					setIsModalOpen(false);
					form.setFieldsValue({ ...noneValue });
				}}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				closeIcon={<Icon.ModalClose />}
				className="form-modal system-manage-user-modal"
			>
				<Space direction="vertical" size="middle" style={{ display: 'flex' }}>
					<Form
						labelCol={{ span: 7 }}
						wrapperCol={{ span: 17 }}
						form={form}
						labelAlign="left"
						colon={false}
						preserve={false}
						onFinish={submitHandler}
						validateMessages={validateMessages}
					>
						{inputColumns.map((i, idx) => {
							return (
								<div key={idx}>
									<Form.Item
										name={i.key}
										label={i.title}
										rules={i.rule ? i.rule : undefined}
										valuePropName={
											i.key === 'enableStatus' ? 'checked' : 'value'
										}
										className={
											i.key === 'confirmedPwd' ? 'confirmed-pwd-item' : ''
										}
									>
										{i?.field ? (
											i.field
										) : (
											<Input
												disabled={i?.disabled && isEdit ? i.disabled : false}
												placeholder={i?.placeholder ? i.placeholder : ''}
											/>
										)}
									</Form.Item>
									{i.key === 'confirmedPwd' ? (
										<Row className="pwd-validate">
											<Col span={10} offset={7}>
												{(isEdit && isChangePwd) || !isEdit ? (
													<>
														{passwordValue ? (
															<>
																{!passwordValidate ? (
																	<>
																		<div
																			className={
																				passwordValue.length >= 8 &&
																				passwordValue.length <= 20
																					? 'pwd-checked'
																					: 'pwd-unchecked'
																			}
																		>
																			{passwordValue.length >= 8 &&
																			passwordValue.length <= 20 ? (
																				<Icon.Check />
																			) : (
																				<></>
																			)}
																			長度為 8 - 20 碼
																		</div>
																		<div
																			className={
																				passwordValue.match(/([a-zA-Z])/g) &&
																				passwordValue.match(/([0-9])/g)
																					? 'pwd-checked'
																					: 'pwd-unchecked'
																			}
																		>
																			{passwordValue.match(/([a-zA-Z])/g) &&
																			passwordValue.match(/([0-9])/g) ? (
																				<Icon.Check />
																			) : (
																				<></>
																			)}
																			需包含英文 + 數字
																		</div>
																		<div
																			className={
																				passwordValue.match(
																					/((\$)|(@)|(!)|(%)|(\*)|(\?)|(&)|(-))/g
																				)
																					? 'pwd-checked'
																					: 'pwd-unchecked'
																			}
																		>
																			{passwordValue.match(
																				/((\$)|(@)|(!)|(%)|(\*)|(\?)|(&)|(-))/g
																			) ? (
																				<Icon.Check />
																			) : (
																				<></>
																			)}
																			含特殊符號 $@$!%*?&-
																		</div>
																	</>
																) : (
																	<></>
																)}
																{confirmedPwdValue &&
																passwordValidate &&
																passwordValue !== confirmedPwdValue ? (
																	<div className="pwd-error">
																		密碼不正確，請重新確認
																	</div>
																) : null}
															</>
														) : (
															<></>
														)}
													</>
												) : (
													<></>
												)}
											</Col>

											{isEdit ? (
												<Col span={7}>
													<Switch
														checkedChildren="修改密碼"
														unCheckedChildren="修改密碼"
														checked={isChangePwd}
														style={{ marginLeft: 'auto', display: 'flex' }}
														onChange={(val: boolean) => {
															setIsChangePwd(val);
															if (val) {
																form.setFieldValue('password', '');
																form.setFieldValue('confirmedPwd', '');
															} else {
																form.setFieldValue(
																	'password',
																	'1234567812345678'
																);
																form.setFieldValue(
																	'confirmedPwd',
																	'1234567812345678'
																);
															}
														}}
													/>
												</Col>
											) : (
												<></>
											)}
										</Row>
									) : (
										<></>
									)}
								</div>
							);
						})}

						{isEdit ? (
							<div className="time-area">
								<div className="time-info">
									登入錯誤次數：
									{form.getFieldValue('retry')} 次
								</div>
								<div className="time-info">
									最後登入時間：
									{form.getFieldValue('lastLoginTime')
										? dayjs(form.getFieldValue('lastLoginTime')).format(
												'YYYY-MM-DD HH:mm:ss'
										  )
										: '無資料'}
								</div>
							</div>
						) : null}
						<Button type="primary" htmlType="submit" className="submit-btn">
							{isEdit ? '更新' : '新增'}
						</Button>
					</Form>
				</Space>
			</Modal>
		</Card>
	);
};

export default SystemManageUser;
