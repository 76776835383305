import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Form, Input, Modal, Row } from 'antd';
import { Icon } from '@models/icon';
import api from '@api/api';
import './UserEdit.less';

type formData = {
	email: string;
	oldPassword: string;
	newPassword: string;
	checkPassword: string;
};

const validateMessages = {
	required: '請輸入 ${label} ',
};

const UserEdit = () => {
	const userJson = JSON.parse(
		localStorage.getItem('energy-dispatch-user') as string
	);
	const userName = userJson?.userName || 'User';
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const passwordValue = Form.useWatch('newPassword', form);
	const confirmedPwdValue = Form.useWatch('checkPassword', form);
	const passwordValidate =
		passwordValue &&
		passwordValue.length >= 8 &&
		passwordValue.length <= 20 &&
		passwordValue.match(/([a-zA-Z])/g) &&
		passwordValue.match(/([0-9])/g) &&
		passwordValue.match(/((\$)|(@)|(!)|(%)|(\*)|(\?)|(&)|(-))/g);

	const submitHandler = async (formData: formData) => {
		const requestData = formData;
		console.log('requestData', requestData);

		if (!passwordValidate || passwordValue !== confirmedPwdValue) {
			return;
		}

		const params = {
			email: userName,
			password: requestData.oldPassword,
			newPassword: requestData.checkPassword,
		};

		const response = await api.post(
			`/webapi/system/user/password/${requestData.email}/${requestData.oldPassword}/${requestData.checkPassword}`,
			params
		);

		if (response.data?.status !== 'ok') {
			return Modal.error({
				title: '密碼更改失敗',
				content:
					response.data?.err?.msg || '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		Modal.success({
			title: '密碼更改成功',
			okText: '確定',
			centered: true,
			closable: true,
			closeIcon: <Icon.ModalClose />,
		});

		form.setFieldsValue({
			email: userName,
			oldPassword: '',
			newPassword: '',
			checkPassword: '',
		});
	};

	useEffect(() => {
		form.setFieldsValue({
			email: userName,
			oldPassword: '',
			newPassword: '',
			checkPassword: '',
		});
	}, []);

	return (
		<Card title="使用者資訊" bordered={false} className="user-edit">
			<Form
				labelCol={{ span: 7 }}
				wrapperCol={{ span: 17 }}
				labelAlign="left"
				colon={false}
				form={form}
				onFinish={submitHandler}
				validateMessages={validateMessages}
				className="user-edit-form"
			>
				<Form.Item name="email" label="帳號">
					<Input disabled={true} />
				</Form.Item>
				<Form.Item
					name="oldPassword"
					label="原始密碼"
					rules={[{ required: true }]}
				>
					<Input.Password
						placeholder={'請輸入原始密碼'}
						iconRender={(visible) =>
							visible ? <Icon.EyeOpen /> : <Icon.EyeClose />
						}
					/>
				</Form.Item>
				<Form.Item
					name="newPassword"
					label="新密碼"
					rules={[{ required: true }]}
				>
					<Input.Password
						status={
							(passwordValue && !passwordValidate) ||
							(passwordValue &&
								confirmedPwdValue &&
								passwordValue !== confirmedPwdValue)
								? 'error'
								: ''
						}
						placeholder={'請輸入新密碼'}
						iconRender={(visible) =>
							visible ? <Icon.EyeOpen /> : <Icon.EyeClose />
						}
					/>
				</Form.Item>
				<Form.Item
					name="checkPassword"
					label="確認新密碼"
					rules={[{ required: true }]}
					style={{ marginBottom: 10 }}
				>
					<Input.Password
						status={
							passwordValue &&
							confirmedPwdValue &&
							passwordValue !== confirmedPwdValue
								? 'error'
								: ''
						}
						placeholder={'請再次確認密碼'}
						iconRender={(visible) =>
							visible ? <Icon.EyeOpen /> : <Icon.EyeClose />
						}
					/>
				</Form.Item>
				<Row className="pwd-validate">
					<Col span={10} offset={7}>
						<>
							{passwordValue ? (
								<>
									{!passwordValidate ? (
										<>
											<div
												className={
													passwordValue.length >= 8 &&
													passwordValue.length <= 20
														? 'pwd-checked'
														: 'pwd-unchecked'
												}
											>
												{passwordValue.length >= 8 &&
												passwordValue.length <= 20 ? (
													<Icon.Check />
												) : (
													<></>
												)}
												長度為 8 - 20 碼
											</div>
											<div
												className={
													passwordValue.match(/([a-zA-Z])/g) &&
													passwordValue.match(/([0-9])/g)
														? 'pwd-checked'
														: 'pwd-unchecked'
												}
											>
												{passwordValue.match(/([a-zA-Z])/g) &&
												passwordValue.match(/([0-9])/g) ? (
													<Icon.Check />
												) : (
													<></>
												)}
												需包含英文 + 數字
											</div>
											<div
												className={
													passwordValue.match(
														/((\$)|(@)|(!)|(%)|(\*)|(\?)|(&)|(-))/g
													)
														? 'pwd-checked'
														: 'pwd-unchecked'
												}
											>
												{passwordValue.match(
													/((\$)|(@)|(!)|(%)|(\*)|(\?)|(&)|(-))/g
												) ? (
													<Icon.Check />
												) : (
													<></>
												)}
												含特殊符號 $@$!%*?&-
											</div>
										</>
									) : (
										<></>
									)}
									{confirmedPwdValue &&
									passwordValidate &&
									passwordValue !== confirmedPwdValue ? (
										<div className="pwd-error">密碼不正確，請重新確認</div>
									) : null}
								</>
							) : (
								<></>
							)}
						</>
					</Col>
				</Row>

				<Row>
					<Form.Item>
						<Button
							type="primary"
							className="user-edit-btn-cancel"
							ghost
							onClick={() => {
								navigate(-1);
							}}
						>
							取消
						</Button>
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit">
							儲存
						</Button>
					</Form.Item>
				</Row>
			</Form>
		</Card>
	);
};

export default UserEdit;
