import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import AppRouter from './AppRouter';
import ThemeProvider from '@themes/ThemeProvider';
import { Provider as ReduxProvider } from 'react-redux';
import store from './store';
import './index.less';

import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import isToday from 'dayjs/plugin/isToday';
import isBetween from 'dayjs/plugin/isBetween';
import en from 'dayjs/locale/en';

dayjs.extend(isoWeek);
dayjs.extend(isToday);
dayjs.extend(isBetween);
dayjs.locale({ ...en, weekStart: 1 });

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<Suspense fallback={<div>Loading...</div>}>
		<ReduxProvider store={store}>
			<ThemeProvider>
				<AppRouter></AppRouter>
			</ThemeProvider>
		</ReduxProvider>
	</Suspense>
);

export type AppDispatch = typeof store.dispatch;
