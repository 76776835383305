import React, { useState } from 'react';
import type { AppDispatch } from 'src';
import { useNavigate } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { loginUser, getAllOrgTree } from '@store/reducers/userSlice';
import { Input, Button, Form } from 'antd';
import { Icon } from '@models/icon';
import './Login.less';

function Login() {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const [isLoading, setIsLoading] = useState(false);
	const [accountInput, setAccountInput] = useState('');
	const [passwordInput, setPasswordInput] = useState('');
	const [accountError, setAccountError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [accountErrorText, setAccountErrorText] = useState('');
	const [passwordErrorText, setPasswordErrorText] = useState('');

	const submitHandler = async () => {
		setAccountError(false);
		setPasswordError(false);

		const account = accountInput.trim();
		const password = passwordInput.trim();
		console.log('acc, pwd:', account, password);

		// @hdrenewables.com
		if (!account || !account.includes('@')) {
			setAccountErrorText('請填寫有效Email');
			setAccountError(true);
		}

		if (!password) {
			setPasswordErrorText('請填寫有效密碼');
			setPasswordError(true);
		}

		if (password && account.includes('@')) {
			setIsLoading(true);
			dispatch(loginUser({ account, password }))
				.then(unwrapResult)
				.then(async () => {
					await dispatch(getAllOrgTree());
					setIsLoading(false);
					navigate('/');
				})
				.catch(() => {
					setIsLoading(false);
					setAccountError(true);
					setAccountErrorText('');

					setPasswordError(true);
					setPasswordErrorText('該密碼與帳號不符，請重新輸入');
				});
		}

		return;
	};

	return (
		<>
			<div id="login-page">
				<div className="center-part">
					<div className="center-title">
						<Icon.LoginLogo />
						<div className="title-text">智慧能源調度系統</div>
						<div className="version-text">版本 V2.0</div>
					</div>
					<div className="input-fields">
						<Form>
							<div>
								<Form.Item>
									<Input
										value={accountInput}
										size="large"
										placeholder="帳號"
										status={accountError ? 'error' : undefined}
										prefix={<Icon.LoginUser />}
										type="email"
										onChange={(e) => {
											setAccountError(false);
											setAccountInput(e.target.value);
										}}
										disabled={isLoading}
									/>
								</Form.Item>
								{accountError && (
									<div className="error-text">{accountErrorText}</div>
								)}
							</div>
							<div>
								<Form.Item name="password">
									<Input
										value={passwordInput}
										size="large"
										placeholder="密碼"
										status={passwordError ? 'error' : undefined}
										prefix={<Icon.LoginLock />}
										type="password"
										onChange={(e) => {
											setPasswordError(false);
											setPasswordInput(e.target.value);
										}}
										disabled={isLoading}
										onPressEnter={() => {
											submitHandler();
										}}
									/>
								</Form.Item>
								{passwordError && (
									<div className="error-text">{passwordErrorText}</div>
								)}
							</div>
							<Button
								type="primary"
								size="large"
								onClick={() => {
									submitHandler();
								}}
								block
							>
								{isLoading ? '登入中' : '登入'}
							</Button>
						</Form>
					</div>
				</div>
				<div className="footer-part">
					{/* <div className="footer-links">
						<a href="#">幫助</a>
						<a href="#">隱私</a>
						<a href="#">條款</a>
					</div> */}
					<div className="copyright-info">
						Copyright ©️ 2022 泓德能源科技股份有限公司
					</div>
				</div>
			</div>
		</>
	);
}
export default Login;
