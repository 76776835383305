import React, { useEffect, useState } from 'react';
import { Button, Card, DatePicker, Modal, Pagination, Select } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { Icon } from '@models/icon';
import { OrgTree, Role } from '@models/user';
import { jsonToCSV } from 'react-papaparse';
import fileDownload from 'js-file-download';
import dayjs, { Dayjs } from 'dayjs';
import api from '@api/api';
import './PerformanceAwarded.less';

interface AwardedData {
	id: string;
	srName: string;
	awardedTimestamp: number;
	awardedCapacity: number;
	capacityPrice: number;
	energyPrice: number;
}

const tableColumns: ColumnsType<AwardedData> = [
	{
		title: '得標時段',
		dataIndex: 'awardedTimestamp',
		key: 'awardedTimestamp',
		width: '25%',
		align: 'center',
		render: (_, record) => (
			<div className="table-ellipsis">
				{dayjs(record.awardedTimestamp).format('YYYY/MM/DD HH:mm:ss')}
			</div>
		),
	},
	{
		title: '得標容量(MW)',
		dataIndex: 'awardedCapacity',
		key: 'awardedCapacity',
		width: '25%',
		align: 'center',
		render: (_, record) => (
			<div className="table-ellipsis">{record.awardedCapacity}</div>
		),
	},
	{
		title: '容量單價(NT$/MW)',
		dataIndex: 'capacityPrice',
		key: 'capacityPrice',
		width: '25%',
		align: 'center',
		render: (_, record) => (
			<div className="table-ellipsis">{record.capacityPrice}</div>
		),
	},
	{
		title: '電能單價(NT$/MWh)',
		dataIndex: 'energyPrice',
		key: 'energyPrice',
		width: '25%',
		align: 'center',
		render: (_, record) => (
			<div className="table-ellipsis">{record.energyPrice}</div>
		),
	},
];

const PerformanceAwarded = () => {
	const [qseDropdownIsOpen, setQSEDropdownIsOpen] = useState(false);
	const [txgDropdownIsOpen, setTXGDropdownIsOpen] = useState(false);
	const [qseDropdownList, setQSEDropdownList] = useState([] as OrgTree[]);
	const [txgDropdownList, setTXGDropdownList] = useState([] as OrgTree[]);
	const [selectedQSE, setSelectedQSE] = useState('');
	const [selectedTXG, setSelectedTXG] = useState('');
	const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
	const [allData, setAllData] = useState([] as any);
	const [displayData, setDisplayData] = useState([] as AwardedData[]);
	const [isLoading, setIsLoading] = useState(false);
	const [pageIndex, setPageIndex] = useState(1);
	const [pageSize, setPageSize] = useState(6);

	const allOrgTreeList: OrgTree[] = JSON.parse(
		localStorage.getItem('energy-dispatch-allOrgTree') as string
	);
	const userJson = JSON.parse(
		localStorage.getItem('energy-dispatch-user') as string
	);

	const getAwardedData = async () => {
		setIsLoading(true);
		const date = dayjs(
			dayjs(selectedDate).format('YYYY-MM-DD 00:00:00')
		).valueOf();

		const response: any = await api.get(
			`/webapi/awarded/sr/list?id=${selectedTXG}&date=${date}&statisticsT
ype=day`
		);

		if (response.data?.status !== 'ok') {
			return Modal.error({
				title: '找不到資料',
				content: '請確認網路連線狀態，並重新整理網頁。',
				okText: '確定',
				centered: true,
				closable: true,
				closeIcon: <Icon.ModalClose />,
			});
		}

		setPageIndex(1);
		setDisplayData([]);
		setAllData(response.data?.list);
		setIsLoading(false);
	};

	const awardedDataExport = () => {
		const newArr: AwardedData[] = [];
		allData.forEach((a: AwardedData) => {
			newArr.push(a);
		});
		console.log('newArr:', newArr);

		const jsonData = newArr.map((a) => ({
			得標時段: dayjs(a.awardedTimestamp).format('YYYY/MM/DD HH:mm:ss'),
			'得標容量 (MW)': a.awardedCapacity,
			'容量單價 (NT/MW)': a.capacityPrice,
			'電能單價 (NT/MWh)': a.energyPrice,
		}));

		const results = jsonToCSV(jsonData);
		console.log(
			'hmmm',
			txgDropdownList.find((txg: OrgTree) => txg.unitId === selectedTXG)
		);
		fileDownload(
			results,
			`得標資訊_${txgDropdownList
				.find((txg: OrgTree) => txg.unitId === selectedTXG)
				?.unitName.trim()}_${dayjs(selectedDate).format('YYYY-MM-DD')}.csv`
		);
		Modal.success({
			title: '檔案已經匯出成功',
			content: '請點擊確定按鈕返回頁面',
			okText: '確定',
			centered: true,
			closable: true,
			closeIcon: <Icon.ModalClose />,
		});
	};

	useEffect(() => {
		if (allOrgTreeList.length > 0) {
			setQSEDropdownList(allOrgTreeList);
			setSelectedQSE(allOrgTreeList[0].unitId);
		}
	}, []);

	useEffect(() => {
		if (selectedQSE) {
			const newArr = allOrgTreeList.filter((a) => a.unitId === selectedQSE)[0]
				.subUnits;
			setTXGDropdownList(newArr);
			setSelectedTXG(newArr[0]?.unitId ?? '');
		}
	}, [selectedQSE]);

	useEffect(() => {
		if (selectedQSE && selectedTXG && selectedDate) {
			getAwardedData();
		}
	}, [selectedQSE, selectedTXG, selectedDate]);

	useEffect(() => {
		if (pageIndex && pageSize && allData.length > 0) {
			const startIndex = (pageIndex - 1) * pageSize;
			const endIndex = Math.min(startIndex + pageSize - 1, allData.length - 1);

			setDisplayData(allData.slice(startIndex, endIndex + 1));
		}
	}, [pageIndex, pageSize, allData]);

	return (
		<Card title="得標資訊" bordered={false} className="performance-awarded">
			<div className="tool-bar">
				<div className="left">
					<Select
						style={{ width: 180 }}
						onDropdownVisibleChange={() => {
							setQSEDropdownIsOpen((prevState: boolean) => !prevState);
						}}
						value={selectedQSE}
						onChange={(arg) => setSelectedQSE(arg)}
						suffixIcon={
							qseDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
						}
						options={qseDropdownList.map((qse: OrgTree) => ({
							value: qse.unitId,
							label: qse.unitName,
							disabled: qse.subUnits.length === 0,
						}))}
					/>
					<Select
						style={{ width: 180 }}
						onDropdownVisibleChange={() => {
							setTXGDropdownIsOpen((prevState: boolean) => !prevState);
						}}
						value={selectedTXG}
						onChange={(arg) => setSelectedTXG(arg)}
						suffixIcon={
							txgDropdownIsOpen ? <Icon.UpArrow /> : <Icon.DownArrow />
						}
						options={txgDropdownList.map((txg: OrgTree) => ({
							value: txg.unitId,
							label: txg.unitName,
							disabled: [Role.SYSAdmin, Role.QSEAdmin, Role.QSEUser].includes(
								userJson.authority[0] ?? 1
							)
								? false
								: txg.unitId !== userJson.unitId,
						}))}
					/>
				</div>
				<div className="right">
					<DatePicker
						style={{ width: 145 }}
						onChange={(arg) => setSelectedDate(arg)}
						suffixIcon={<Icon.Day />}
						value={selectedDate}
						placeholder={'開始時間'}
						format={'YYYY-MM-DD'}
					/>

					<Button ghost type="primary" onClick={() => awardedDataExport()}>
						匯出CSV
					</Button>
				</div>
			</div>
			<div className="data-table">
				<Table
					columns={tableColumns}
					dataSource={displayData}
					pagination={false}
					loading={isLoading}
					rowKey={(record) => record.awardedTimestamp}
				/>
			</div>
			{allData.length > 0 ? (
				<div className="pagination-bar">
					<div className="right">
						<Pagination
							total={allData.length}
							current={pageIndex}
							onChange={(page, size) => {
								setPageIndex(page);
								setPageSize(size);
							}}
							showSizeChanger
							showQuickJumper
							defaultPageSize={6}
							pageSize={pageSize}
							pageSizeOptions={['6', '12', '24']}
							locale={{
								items_per_page: ' / page',
								jump_to: 'Go to ',
								page: '',
							}}
							showTotal={(total) => `Total ${total} items`}
						/>
					</div>
				</div>
			) : null}
		</Card>
	);
};

export default PerformanceAwarded;
