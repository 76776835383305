import React, { useEffect, useState } from 'react';
import NoData from '@components/NoData/NoData';
import { useSelector } from 'react-redux';
import { getCustomThemeToken } from '@store/reducers/utilSlice';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Pagination, Spin } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { ImplementData } from '@pages/PerformanceImplement/PerformanceImplement';
import { shareChartOption } from '@models/common';
import dayjs, { Dayjs } from 'dayjs';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';

interface Props {
	allData: ImplementData[];
	isLoading: boolean;
	selectedDate: Dayjs | null;
	selectedTXGName: string;
}

const srTableColumns: ColumnsType<ImplementData> = [
	{
		title: '用戶/場域名稱',
		dataIndex: 'name',
		key: 'name',
		width: '20%',
		align: 'center',
	},
	{
		title: '通知類型',
		dataIndex: 'noticeType',
		key: 'noticeType',
		width: '20%',
		align: 'center',
	},
	{
		title: '調度通知時間',
		dataIndex: 'noticeTime',
		key: 'noticeTime',
		width: '20%',
		align: 'center',
		render: (_, record) => (
			<div>
				{record.noticeTime
					? dayjs(record.noticeTime).format('YYYY/MM/DD HH:mm:ss')
					: ''}
			</div>
		),
	},
	{
		title: '通知降載量(MW)',
		dataIndex: 'clipMW',
		key: 'clipMW',
		width: '20%',
		align: 'center',
	},
	{
		title: '執行率(%)',
		dataIndex: 'revenueFactor',
		key: 'revenueFactor',
		width: '20%',
		align: 'center',
	},
];

const chartLabels = [
	{
		labelName: '通知降載量(kW)',
		parameter: 'clipMW',
		valueSuffix: ' kW',
		yAxis: 0,
		color: '#4391FF',
	},
	{
		labelName: '執行率(%)',
		parameter: 'revenueFactor',
		yAxis: 1,
		color: '#EE4D7E',
	},
];

highchartsMore(Highcharts);

const SRImplement = ({
	allData,
	isLoading,
	selectedDate,
	selectedTXGName,
}: Props) => {
	const token = useSelector(getCustomThemeToken);
	const [displayData, setDisplayData] = useState([] as ImplementData[]);
	const [pageIndex, setPageIndex] = useState(1);
	const [pageSize, setPageSize] = useState(4);
	const [chartData, setChartData] = useState([]);
	const [isChartLoading, setIsChartLoading] = useState(false);

	const hourScale = {
		name: '',
		pointInterval: 3600 * 1000,
		pointStart: dayjs(
			dayjs(selectedDate).format('YYYY-MM-DD 00:00:00')
		).valueOf(),
		data: [
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
		],
		opacity: 0,
	};

	const options = {
		...shareChartOption(token),
		chart: {
			height: 300,
			type: 'line',
			zoomType: 'xy',
			animation: false,
			backgroundColor: token.colorBgContainer,
		},
		yAxis: [
			{
				title: {
					text: '通知降載量｜kW',
					style: {
						color: token.colorChartNormal,
					},
				},
				labels: {
					style: {
						color: token.colorChartNormal,
					},
				},
				gridLineColor: token.colorChartNormal,
			},
			{
				title: {
					text: '執行率｜%',
					rotation: 270,
					style: {
						color: token.colorChartNormal,
					},
				},
				labels: {
					style: {
						color: token.colorChartNormal,
					},
				},
				gridLineColor: token.colorChartNormal,
				opposite: true,
			},
		],
		series: chartData,
		tooltip: {
			shared: true,
			backgroundColor: token.colorBgContainer,
			borderColor: token.colorBorder,
			borderRadius: token.borderRadius,
			borderWidth: 1,
			shadow: true,
			animation: true,
			style: {
				fontSize: '12px',
				fontWeight: 400,
			},
			useHTML: true,
			outside: true,
			headerFormat: '',
			pointFormatter: function (this: any) {
				const series = this.series;
				const x = this.x;
				const y = this.y;

				const timeHeader = `<div style="color: ${
					token.colorTextQuaternary
				};">${dayjs(x).format('YYYY-MM-DD HH:mm:ss')}</div>`;
				const clipHeader = `<div style="margin-top: 8px; color: ${chartLabels[0].color};">${chartLabels[0].labelName}</div>`;
				const rfHeader = `<div style="margin-top: 8px; color: ${chartLabels[1].color};">${chartLabels[1].labelName}</div>`;

				if (series.name === `${selectedTXGName} 通知降載量(kW)`) {
					const resInfo = series.options?.originData
						? (series.options.originData as ImplementData[])
								.filter((o) => o.noticeTime === x)[0]
								.list?.map(
									(
										l
									) => `<div style="margin-top: 8px; display: flex; justify-content: space-between;">
										<div style="color:${token.colorTextTertiary};margin-right: 15px;">${
										l.name
									}</div>
										<div style="color:${token.colorTextTertiary};text-align: right;">${
										Number(l.clipMW) * 1000
									} kW</div>
									</div>`
								)
								.join('')
						: ``;

					return `${timeHeader}
									${clipHeader}
									<div style="margin-top: 8px; display: flex; justify-content: space-between;">
										<div style="color:${token.colorTextTertiary};margin-right: 15px;">${selectedTXGName}</div>
										<div style="color:${token.colorTextTertiary};text-align: right;">${y} kW</div>
									</div>
									${resInfo}
									`;
				}
				if (series.name === `${selectedTXGName} 執行率(%)`) {
					const resInfo = series.options?.originData
						? (series.options.originData as ImplementData[])
								.filter((o) => o.noticeTime === x)[0]
								.list?.map(
									(
										l
									) => `<div style="margin-top: 8px; display: flex; justify-content: space-between;">
										<div style="color:${token.colorTextTertiary};margin-right: 15px;">${l.name}</div>
										<div style="color:${token.colorTextTertiary};text-align: right;">${l.revenueFactor}</div>
									</div>`
								)
								.join('')
						: ``;

					return `${rfHeader}
									<div style="margin-top: 8px; display: flex; justify-content: space-between;">
										<div style="color:${token.colorTextTertiary};margin-right: 15px;">${selectedTXGName}</div>
										<div style="color:${token.colorTextTertiary};text-align: right;">${y}</div>
									</div>
									${resInfo}
									`;
				}
			},
		},
		legend: {
			useHTML: true,
			labelFormatter: function (this: any) {
				if (
					this.name === `${selectedTXGName} 執行率(%)` ||
					this.name === `${selectedTXGName} 通知降載量(kW)`
				) {
					return `<svg width="20" height="11" viewBox="0 0 20 11" fill="none">
						<path d="M18 5C17.7596 5.00172 17.5214 5.04677 17.297 5.133L14.899 3.17C14.958 2.956 15 2.734 15 2.5C15 1.114 13.886 0 12.5 0C11.114 0 10 1.114 10 2.5C10 2.896 10.1 3.265 10.262 3.597L7.353 7.035C7.23656 7.01321 7.11845 7.00149 7 7C6.821 7 6.652 7.03 6.488 7.074L3.925 4.511C3.97 4.348 4 4.179 4 4C4 2.892 3.108 2 2 2C0.892 2 0 2.892 0 4C0 5.108 0.892 6 2 6C2.179 6 2.348 5.97 2.512 5.926L5.075 8.489C5.02693 8.65514 5.0017 8.82705 5 9C5 10.108 5.892 11 7 11C8.108 11 9 10.108 9 9C9 8.763 8.952 8.54 8.877 8.329L11.79 4.887C12.017 4.953 12.252 5 12.5 5C12.8946 4.99782 13.2831 4.90148 13.633 4.719L16.032 6.682C16.0132 6.78702 16.0025 6.89334 16 7C16 8.108 16.892 9 18 9C19.108 9 20 8.108 20 7C20 5.892 19.108 5 18 5Z" fill="${this.color}"/>
					</svg>
					<span style="color: ${this.color}; font-weight: 400;">${this.name}</span>
					`;
				} else {
					return ``;
				}
			},
		},
	};

	const getChartData = (data: ImplementData[]) => {
		console.log('getChartData data:', data);

		const newArr: any = [];
		chartLabels.forEach((item) => {
			const isParameterClipMW = item.parameter === 'clipMW';
			const parentParam = {
				...item,
				marker: {
					symbol: 'circle',
					radius: 3.5,
				},
				dataLabels: {
					enabled: false,
				},
				tooltip: {
					valueSuffix: item.valueSuffix,
				},
				name: `${data[0].name} ${item.labelName}`,
				data: data.map((d) => [
					d.noticeTime,
					isParameterClipMW
						? Number(d.clipMW) * 1000
						: (d as any)[item.parameter],
				]),
				originData: data,
			};
			newArr.push(parentParam);
		});
		console.log('newArr', newArr);

		return newArr;
	};

	useEffect(() => {
		setIsChartLoading(true);
	}, [selectedDate]);

	useEffect(() => {
		setIsChartLoading(true);
		let arr = [] as any;
		if (allData.length > 0) {
			arr = getChartData(allData);
		} else {
			arr = chartLabels.map((c) => ({
				name: `${selectedTXGName} ${c.labelName}`,
				color: c.color,
			}));
		}
		arr.push(hourScale);

		setChartData([]);
		setTimeout(() => {
			setChartData(arr);
			setIsChartLoading(false);
		}, 300);
	}, [allData]);

	useEffect(() => {
		if (allData.length === 0) {
			setDisplayData([]);
		}

		if (pageIndex && pageSize && allData.length > 0) {
			const startIndex = (pageIndex - 1) * pageSize;
			const endIndex = Math.min(startIndex + pageSize - 1, allData.length - 1);
			setDisplayData(allData.slice(startIndex, endIndex + 1));
		}
	}, [pageIndex, pageSize, allData]);

	return (
		<>
			<div className="chart">
				{isLoading || isChartLoading ? (
					<Spin className="spin-center" />
				) : (
					<>
						<HighchartsReact highcharts={Highcharts} options={options} />
						<div className="hint-text">滑鼠拖拉可以放大圖表</div>
						<div className="fixed-no-data">
							{allData.length === 0 ? <NoData /> : ''}
						</div>
					</>
				)}
			</div>
			<div className="data-table">
				<Table
					columns={srTableColumns}
					dataSource={displayData}
					pagination={false}
					loading={isLoading}
					expandable={{
						indentSize: 40,
						childrenColumnName: 'list',
						expandIcon: ({ expanded, onExpand, record }) =>
							record?.list ? (
								expanded ? (
									<DownOutlined
										onClick={(e) => onExpand(record, e)}
										className="expand-icon"
									/>
								) : (
									<RightOutlined
										onClick={(e) => onExpand(record, e)}
										className="expand-icon"
									/>
								)
							) : (
								''
							),
					}}
					rowKey={(record) => record.idx.toString()}
				/>
			</div>
			{allData.length > 0 ? (
				<div className="pagination-bar">
					<div className="right">
						<Pagination
							total={allData.length}
							current={pageIndex}
							onChange={(page, size) => {
								setPageIndex(page);
								setPageSize(size);
							}}
							defaultPageSize={4}
						/>
					</div>
				</div>
			) : null}
		</>
	);
};

export default SRImplement;
