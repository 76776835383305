/**
 * 數值加上千分位逗號 (e.g., 12345789.0 --> 123,456,789.0)
 * @param {*} input             來源資料
 */
export const commafy = (input: number | string) => {
	const re = /^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/;
	let result = '0';
	if (input) {
		result = input.toString().replace(re, (all, pre, groupOf3Digital) => {
			return pre + groupOf3Digital.replace(/\d{3}/g, ',$&');
		});
	}
	return result;
};
